import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const CloseButton = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing(4)};
  right: ${({ theme }) => theme.spacing(4)};
`;

export const Header = styled.div`
  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};

  color: ${({ theme }) => theme.palette.text.primary};
`;

export const Description = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};

  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Anchor = styled.a`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const Body = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(6)}`};

  color: ${({ theme }) => theme.palette.text.primary};
`;

export const TextArea = styled.textarea`
  ${({ theme }) => typographyToCss(theme.typography.body2)};

  width: 100%;
  height: 23.2rem;

  resize: none;
  border: none;
  outline: none;
`;

export const Footer = styled.div`
  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};
  margin-left: auto;
`;
