import React from 'react';
import { BarChartEntry } from 'shared/lib/recharts';
import { formatNumberShort } from 'shared/utils/format-number-short';

import { BarChart } from './bar-chart';
import * as Styled from './evenly-distributed-bar-chart.style';
import { useYAxisData } from './use-y-axis-data';

export type EvenlyDistributedBarChartProps = {
  data: BarChartEntry[];
  startValue?: number | null;
  target?: number | null;
  showYAxis?: boolean;
  hasLowHeight?: boolean;
};

export const EvenlyDistributedBarChart: React.FC<
  EvenlyDistributedBarChartProps
> = ({ data, startValue, target, showYAxis, hasLowHeight }) => {
  const { ticks: yTicks } = useYAxisData(data, {
    startValue: startValue ?? undefined,
    endValue: target ?? undefined,
  });

  return (
    <Styled.Container $hasLowHeight={!!hasLowHeight}>
      <BarChart
        data={data}
        yTicks={yTicks}
        yTickFormatter={showYAxis ? formatNumberShort : undefined}
        target={target ?? undefined}
      />
    </Styled.Container>
  );
};
