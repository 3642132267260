import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { Calendar } from 'shared/assets/icons';
import { Datepicker } from 'shared/components/ui/datepicker';
import { FormInput } from 'shared/components/ui/form-input';
import { DateFormatOptions } from 'shared/types/date-format-options';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

import * as Styled from './form-date-input.style';

export type FormDateInputProps = React.ComponentProps<typeof FormInput> & {
  control: Control<any>;
  name: string;
  dateFormat: DateFormatOptions;
  weekStartsOn: WeekDays;
  today: Timestamp;
};

export const FormDateInput: React.FC<FormDateInputProps> = ({
  id,
  control,
  name,
  placeholder,
  dateFormat,
  weekStartsOn,
  today,
}) => (
  <Styled.Container>
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Datepicker
          today={today}
          wrapperClassName={Styled.datePickerWrapperClassName}
          placeholderText={placeholder}
          onChange={(date) => field.onChange(date)}
          selected={field.value}
          dateFormat={dateFormat}
          weekStartsOn={weekStartsOn}
          customInput={<FormInput iconRight={Calendar} canClear />}
          popperProps={{ strategy: 'fixed' }}
          id={id}
        />
      )}
    />
  </Styled.Container>
);
