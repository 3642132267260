import { endOfMonth, isSameDay, isSameMonth, startOfMonth } from 'date-fns';
import React, { useMemo } from 'react';
import {
  HabitProgressCalendar,
  SurroundingMonths,
} from 'shared/components/ui/habit-progress-calendar';
import { isBetween } from 'shared/lib/date-fns';
import { EntryState, HabitSchedule } from 'shared/types/habit-schedule';
import { Timeframe } from 'shared/types/timeframe';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

export type TimeframeMonthProps = {
  referenceDate: Timestamp;
  dataStartDate: Timestamp;
  dataEndDate: Timestamp;
  occurrences: Timestamp[];
  completions: Timestamp[];
  skips: Timestamp[];
  perfectSlots?: Timeframe[];
  scheduleChanges?: {
    date: Timestamp;
    oldSchedule: HabitSchedule;
    newSchedule: HabitSchedule;
  }[];
  target?: number;
  weekTarget?: number;
  weekStartsOn: WeekDays;
  onDate?: (date: Timestamp, type: EntryState) => void;
  onInfo?: () => void;
  showCompletionPercentage?: boolean;
};

export const TimeframeMonth: React.FC<TimeframeMonthProps> = ({
  referenceDate,
  dataStartDate,
  dataEndDate,
  occurrences,
  completions,
  skips,
  perfectSlots,
  scheduleChanges,
  target,
  weekTarget,
  weekStartsOn,
  onDate,
  onInfo,
  showCompletionPercentage,
}) => {
  const dataOccurrences = useMemo(
    () =>
      occurrences.filter((occurrence) =>
        isBetween(occurrence, dataStartDate, dataEndDate),
      ),
    [dataEndDate, dataStartDate, occurrences],
  );

  const dataCompletions = useMemo(
    () =>
      completions.filter((completion) =>
        isBetween(completion, dataStartDate, dataEndDate),
      ),
    [completions, dataEndDate, dataStartDate],
  );

  const filteredSkips = useMemo(
    () => skips.filter((skip) => isBetween(skip, dataStartDate, dataEndDate)),
    [dataEndDate, dataStartDate, skips],
  );

  const monthOccurrences = useMemo(
    () =>
      occurrences.filter((occurrence) =>
        isBetween(
          occurrence,
          startOfMonth(referenceDate),
          endOfMonth(referenceDate),
        ),
      ),
    [occurrences, referenceDate],
  );

  const monthCompletions = useMemo(
    () =>
      completions.filter((completion) =>
        isBetween(
          completion,
          startOfMonth(referenceDate),
          endOfMonth(referenceDate),
        ),
      ),
    [completions, referenceDate],
  );

  const completionPercentage = useMemo(() => {
    const percentage = showCompletionPercentage
      ? Math.round(
          (monthCompletions.length / (target || monthOccurrences.length || 1)) *
            100,
        )
      : undefined;

    return percentage && percentage >= 10 ? Math.round(percentage) : percentage;
  }, [
    monthCompletions.length,
    monthOccurrences.length,
    showCompletionPercentage,
    target,
  ]);

  const monthScheduleChanges = useMemo(
    () =>
      scheduleChanges?.filter(({ date }) => isSameMonth(date, referenceDate)),
    [referenceDate, scheduleChanges],
  );

  return (
    <HabitProgressCalendar
      openToDate={referenceDate}
      occurrences={dataOccurrences}
      completions={dataCompletions}
      completionCount={dataCompletions.length}
      skipCount={filteredSkips.length}
      completionPercentage={completionPercentage}
      skips={filteredSkips}
      weekStartsOn={weekStartsOn}
      weekTarget={weekTarget}
      perfectSlots={perfectSlots}
      scheduleChanges={monthScheduleChanges}
      showSurroundingMonths={
        [
          !isSameDay(dataStartDate, startOfMonth(referenceDate))
            ? SurroundingMonths.Before
            : undefined,
          !isSameDay(dataEndDate, endOfMonth(referenceDate))
            ? SurroundingMonths.After
            : undefined,
        ].filter(Boolean) as SurroundingMonths[]
      }
      onChange={onDate}
      onInfo={onInfo}
      showDates
      showHeader
      isCard
    />
  );
};
