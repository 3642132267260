import React, { useMemo, useState } from 'react';
import { InsightsCalculationsDialog } from 'shared/dialogs/insight-calculations';

import {
  InsightsCalculationsContext,
  InsightsCalculationsContextProps,
} from './insights-calculations-context';
import { InsightsInfo } from './types';

export type InsightsCalculationsProviderProps = {
  children: React.ReactNode;
};

export const InsightsCalculationsProvider: React.FC<
  InsightsCalculationsProviderProps
> = ({ children }) => {
  const [insightsInfo, setInsightsInfo] = useState<InsightsInfo>();
  const onClose = () => setInsightsInfo(undefined);

  const value = useMemo<InsightsCalculationsContextProps>(
    () => ({
      openInsightsCalculations: setInsightsInfo,
    }),
    [],
  );

  return (
    <InsightsCalculationsContext.Provider value={value}>
      {children}
      <InsightsCalculationsDialog
        onClose={onClose}
        habit={insightsInfo?.habit}
        timeframe={insightsInfo?.timeframe}
        referenceDate={insightsInfo?.referenceDate}
      />
    </InsightsCalculationsContext.Provider>
  );
};
