import {
  eachMonthOfInterval,
  eachWeekOfInterval,
  eachYearOfInterval,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { useMemo } from 'react';
import { weekdaysNumberMap } from 'shared/constants';
import { useActiveSchedule } from 'shared/hooks/use-active-schedule';
import { useTimeframeCompletions } from 'shared/hooks/use-timeframe-completions';
import { useTimeframeOccurrences } from 'shared/hooks/use-timeframe-occurrences';
import { isBetween } from 'shared/lib/date-fns';
import { FrequencyUnit, HabitSchedule } from 'shared/types/habit-schedule';
import { InsightsTimeframes } from 'shared/types/insights';
import { Timeframe } from 'shared/types/timeframe';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getScheduleFrequency } from 'shared/utils/get-schedule-frequency';
import { isNumber } from 'shared/utils/is-number';

const frequencySlotFuncsMap = {
  [FrequencyUnit.Week]: {
    startOfSlot: startOfWeek,
    endOfSlot: endOfWeek,
    eachOfInterval: eachWeekOfInterval,
  },
  [FrequencyUnit.Month]: {
    startOfSlot: startOfMonth,
    endOfSlot: endOfMonth,
    eachOfInterval: eachMonthOfInterval,
  },
  [FrequencyUnit.Year]: {
    startOfSlot: startOfYear,
    endOfSlot: endOfYear,
    eachOfInterval: eachYearOfInterval,
  },
};

const frequencyTimeframeMap = {
  [FrequencyUnit.Week]: [
    InsightsTimeframes.Week,
    InsightsTimeframes.Month,
    InsightsTimeframes.Quarter,
    InsightsTimeframes.Year,
    InsightsTimeframes.FourWeeks,
    InsightsTimeframes.TwelveWeeks,
    InsightsTimeframes.TwelveMonths,
  ],
  [FrequencyUnit.Month]: [
    InsightsTimeframes.Month,
    InsightsTimeframes.Quarter,
    InsightsTimeframes.Year,
    InsightsTimeframes.TwelveMonths,
  ],
  [FrequencyUnit.Year]: [InsightsTimeframes.Year],
};

export type Options = {
  timeframe: InsightsTimeframes;
  startDate: Timestamp;
  endDate: Timestamp;
  weekStartsOn: WeekDays;
};

export const useTimeframePerfectSlots = (
  schedules: HabitSchedule[],
  { timeframe, startDate, endDate, weekStartsOn }: Options,
) => {
  const { completions } = useTimeframeCompletions(schedules, {
    startDate,
    endDate,
  });
  const { occurrences } = useTimeframeOccurrences(schedules, {
    startDate,
    endDate,
  });
  const activeSchedule = useActiveSchedule(schedules);
  const target = activeSchedule?.frequency?.count;

  return useMemo<Timeframe[]>(() => {
    // frequency of month cant have perfect weeks
    if (!activeSchedule) {
      return [];
    }
    const frequency = getScheduleFrequency(activeSchedule);
    if (!frequencyTimeframeMap[frequency].includes(timeframe)) {
      return [];
    }

    const { startOfSlot, endOfSlot, eachOfInterval } =
      frequencySlotFuncsMap[frequency];

    const slotStart = startOfSlot(startDate, {
      weekStartsOn: weekdaysNumberMap[weekStartsOn],
    });
    const slotEnd = endOfSlot(endDate, {
      weekStartsOn: weekdaysNumberMap[weekStartsOn],
    });

    return eachOfInterval(
      { start: slotStart, end: slotEnd },
      { weekStartsOn: weekdaysNumberMap[weekStartsOn] },
    )
      .map((slotStartDate) => {
        const slotEndDate = endOfSlot(slotStartDate, {
          weekStartsOn: weekdaysNumberMap[weekStartsOn],
        });

        const slotTarget = isNumber(target)
          ? target!
          : occurrences.filter((occurrence) =>
              isBetween(occurrence, slotStartDate, slotEndDate),
            ).length || 1;
        const weekCompletions = completions.filter((completion) =>
          isBetween(completion, slotStartDate, slotEndDate),
        ).length;

        if (slotTarget >= 1 && weekCompletions >= slotTarget) {
          return { startDate: slotStartDate, endDate: slotEndDate };
        }
        return undefined;
      })
      .filter(Boolean) as Timeframe[];
  }, [
    activeSchedule,
    completions,
    endDate,
    occurrences,
    target,
    startDate,
    timeframe,
    weekStartsOn,
  ]);
};
