export enum LimitKeys {
  CreateGoal = 'createGoal',
  CreateHabit = 'createHabit',
  HabitIcons = 'habitIcons',
  HabitScheduleTarget = 'habitScheduleTarget',
  GoalUserPhoto = 'goalUserPhoto',
  BiometricAppLock = 'biometricAppLock',
  CustomizeDefaultLifeAreas = 'customizeDefaultLifeAreas',
  CustomLifeAreas = 'customLifeAreas',
  SubGoals = 'subGoals',
  VisionUserPhoto = 'visionUserPhoto',
  Insights = 'insights',
}

export const limitKeys = Object.values(LimitKeys);
