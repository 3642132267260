import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';
import { getScheduleLabel } from 'shared/utils/get-schedule-label';

import * as Styled from './schedule-changes-announcement.style';

export type ScheduleChangesAnnouncementProps = {
  scheduleChanges: {
    date: Timestamp;
    oldSchedule: HabitSchedule;
    newSchedule: HabitSchedule;
  }[];
};

export const ScheduleChangesAnnouncement: React.FC<
  ScheduleChangesAnnouncementProps
> = ({ scheduleChanges }) => {
  const { t } = useTranslation();

  return (
    <Styled.List>
      {scheduleChanges.map(({ date, oldSchedule, newSchedule }, index) => (
        <Styled.ListItem key={`${date.getTime()}-${index}`}>
          <Styled.ChangeContainer>
            <Styled.Date>{format(date, 'MMM d: ')}</Styled.Date>
            <Styled.From>
              {t(
                'general.labels.task-statistics.sections.calendar.reschedule.description',
                {
                  old: getScheduleLabel(oldSchedule, t),
                },
              )}
            </Styled.From>
            <Styled.To>{getScheduleLabel(newSchedule, t)}</Styled.To>
          </Styled.ChangeContainer>
        </Styled.ListItem>
      ))}
    </Styled.List>
  );
};
