import Typography from '@mui/material/Typography';
import { format, isSameDay } from 'date-fns';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Bell, Calendar, Repeat, Subtask } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { TaskTemplateSchedule } from 'shared/types/goal-template';
import { ID } from 'shared/types/id';
import { LocalizedString } from 'shared/types/localized-string';
import { TaskPriorityOptions } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';
import { getLocalizedStringValue } from 'shared/utils/get-localized-string-value';
import { getScheduleLabel } from 'shared/utils/get-schedule-label';
import { parseReminderTime } from 'shared/utils/parse-reminder-time';

import * as Styled from './task-preview-card.style';

export type TaskPreviewCardProps = {
  name: LocalizedString;
  description: LocalizedString | null;
  priority: TaskPriorityOptions | null;
  deadline: Timestamp | null;
  reminderTime: number | null;
  schedule: TaskTemplateSchedule | null;
  childIds: ID[] | null;

  today: Timestamp;
};

export const TaskPreviewCard: React.FC<TaskPreviewCardProps> = ({
  name,
  description,
  priority,
  deadline,
  reminderTime,
  schedule,
  childIds,

  today,
}) => {
  const { t, i18n } = useTranslation();

  const deadlineLabel = useMemo(() => {
    if (!deadline) {
      return undefined;
    }

    if (isSameDay(deadline, today)) {
      return t('general.labels.dates.today');
    }

    return format(deadline, 'd MMM');
  }, [deadline, t, today]);

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.CheckboxPlaceholder $priority={priority} />
        <Styled.HeaderContent>
          <Typography variant="body2">
            {getLocalizedStringValue(name, i18n.language)}
          </Typography>
          {!!description && (
            <Styled.Description>
              {getLocalizedStringValue(description, i18n.language)}
            </Styled.Description>
          )}
        </Styled.HeaderContent>
      </Styled.Header>

      <Styled.Body>
        <Styled.List>
          {!!childIds?.length && (
            <Styled.ListItem>
              <Styled.MetaTag>
                <Icon icon={Subtask} />
                <Typography variant="overline">{childIds.length}</Typography>
              </Styled.MetaTag>
            </Styled.ListItem>
          )}
          {!!deadlineLabel && (
            <Styled.ListItem>
              <Styled.MetaTag>
                <Icon icon={Calendar} />
                <Typography variant="overline">{deadlineLabel}</Typography>
              </Styled.MetaTag>
            </Styled.ListItem>
          )}
          {!!schedule && (
            <Styled.ListItem>
              <Styled.MetaTag>
                <Icon icon={Repeat} />
                <Typography variant="overline">
                  {getScheduleLabel(schedule, t)}
                </Typography>
              </Styled.MetaTag>
            </Styled.ListItem>
          )}
          {!!reminderTime && (
            <Styled.ListItem>
              <Styled.MetaTag>
                <Icon icon={Bell} />
                <Typography variant="overline">
                  {parseReminderTime(reminderTime)}
                </Typography>
              </Styled.MetaTag>
            </Styled.ListItem>
          )}
        </Styled.List>
      </Styled.Body>
    </Styled.Container>
  );
};
