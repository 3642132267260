import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => `0 ${theme.spacing(6)} ${theme.spacing(2)}`};

  background: ${({ theme }) => theme.palette.background.default};
`;

export const Title = styled.h2`
  ${({ theme }) => typographyToCss(theme.typography.h5)};
  flex: 1;
  padding: 0;
  margin: 0;

  text-align: center;
`;

export const Description = styled.p`
  ${({ theme }) => typographyToCss(theme.typography.overline)};

  margin: 0;
  padding: 0;

  text-align: center;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const Body = styled.div``;
