import React, { useEffect } from 'react';
import { useDebounce } from 'shared/hooks/use-debounce';

export const useResizeObserver = (
  ref: React.RefObject<HTMLElement>,
  onResize: (size: DOMRectReadOnly) => void,
  timeout?: number,
) => {
  const debouncedResize = useDebounce(onResize, timeout);

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) =>
      entries.forEach((entry) => debouncedResize(entry.contentRect)),
    );
    resizeObserver.observe(element);

    return () => resizeObserver.unobserve(element);
  }, [debouncedResize, onResize, ref]);
};
