export const debounce = <Callback extends (...args: any[]) => any>(
  callback: Callback,
  timeout = 500,
) => {
  let timer: number;

  return (...args: Parameters<Callback>) => {
    window.clearTimeout(timer);
    timer = window.setTimeout(() => callback.apply(this, args), timeout);
  };
};
