import { useCreateEntriesFromGoalTemplate } from 'features/goal-templates';
import { useLocalizedLifeAreas } from 'features/life-areas';
import React, { useCallback, useEffect, useMemo } from 'react';
import { GoalTemplatePreviewDialog } from 'shared/dialogs/goal-template-preview';
import { useUser } from 'shared/hooks/use-user';
import { GoalTemplate } from 'shared/types/goal-template';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

import {
  GoalTemplatePreviewContext,
  GoalTemplatePreviewContextProps,
} from './goal-template-preview-context';

export type GoalTemplatePreviewProviderProps = {
  children: React.ReactNode;
};

export const GoalTemplatePreviewProvider: React.FC<
  GoalTemplatePreviewProviderProps
> = ({ children }) => {
  const user = useUser();
  const [activeTemplate, setActiveTemplate] = React.useState<GoalTemplate>();
  const { submit, retry, isLoading, error, isSuccess, reset } =
    useCreateEntriesFromGoalTemplate();

  const lifeAreas = useLocalizedLifeAreas();

  const onClose = useCallback(() => {
    setActiveTemplate(undefined);
    reset();
  }, [reset]);

  const onSubmit = (template: GoalTemplate) => {
    submit(template.id);
  };

  const value = useMemo<GoalTemplatePreviewContextProps>(
    () => ({ openGoalTemplatePreview: setActiveTemplate }),
    [],
  );

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  return (
    <GoalTemplatePreviewContext.Provider value={value}>
      {children}

      {activeTemplate && (
        <GoalTemplatePreviewDialog
          template={activeTemplate}
          onSubmit={onSubmit}
          retry={retry}
          isLoading={isLoading}
          isError={!!error}
          onClose={onClose}
          lifeAreas={lifeAreas}
          weekStartsOn={getWeekStartsOn(user)}
        />
      )}
    </GoalTemplatePreviewContext.Provider>
  );
};
