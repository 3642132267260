import { createContext } from 'react';

import { InsightsInfo } from './types';

export type InsightsCalculationsContextProps = {
  openInsightsCalculations: (insightsInfo: InsightsInfo) => void;
};

export const InsightsCalculationsContext =
  createContext<InsightsCalculationsContextProps>({
    openInsightsCalculations: () => {},
  });
