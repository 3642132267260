import { Day, startOfMonth, startOfWeek } from 'date-fns';

export type Options = {
  weekStartsOn: Day;
};

export const startOfFirstWeekOfMonth = (
  date: Date | string | number,
  { weekStartsOn }: Options,
) => startOfWeek(startOfMonth(date), { weekStartsOn });
