import { addWeeks } from 'date-fns';
import { useMemo } from 'react';
import { isBetween } from 'shared/lib/date-fns';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';

export type Options = {
  startDate: Timestamp;
  endDate: Timestamp;
};

export const useTimeframeCompletions = (
  schedules: HabitSchedule[],
  { startDate, endDate }: Options,
) => {
  // get a bigger range for extra completions to display in the month-view
  const rangeStartDate = useMemo(() => addWeeks(startDate, -3), [startDate]);
  const rangeEndDate = useMemo(() => addWeeks(endDate, 3), [endDate]);

  const completions = useMemo(
    () =>
      schedules
        .map(({ completions }) => completions)
        .flat(1)
        .filter((completion) =>
          isBetween(completion, rangeStartDate, rangeEndDate),
        ),
    [rangeEndDate, rangeStartDate, schedules],
  );

  const timeframeCompletions = useMemo(
    () =>
      completions.filter((completion) =>
        isBetween(completion, startDate, endDate),
      ),
    [completions, endDate, startDate],
  );

  return {
    completions,
    timeframeCompletions,
  };
};
