import { startOfDay } from 'date-fns';
import { RRule } from 'shared/lib/rrule';
import { Timestamp } from 'shared/types/timestamp';

export const getFirstUncompletedDateByRrule = ({
  startDate,
  rrule,
  completions,
}: {
  startDate: Timestamp;
  completions: Timestamp[];
  rrule: string;
}) => {
  const sortedCompletions = completions.toSorted(
    (completionA, completionB) => completionA.getTime() - completionB.getTime(),
  );
  const rule = RRule.fromString(rrule);
  rule.options.dtstart = startOfDay(startDate);
  let nextDate = rule.after(startDate);

  sortedCompletions.forEach((completion) => {
    nextDate = rule.after(completion);
  });

  return nextDate;
};
