import { useHabits } from 'features/tasks';
import { useMemo } from 'react';
import { useUser } from 'shared/hooks/use-user';
import { InsightSections } from 'shared/types/insights';
import { getActiveSchedule } from 'shared/utils/get-active-schedule';
import { sortItems } from 'shared/utils/sort-items';

export const useInsightsHabits = () => {
  const user = useUser();
  const habits = useHabits();

  return useMemo(() => {
    const filteredHabits = habits.filter(
      ({ id, completedAt, archivedAt, parentIds, schedules }) => {
        const activeSchedule = getActiveSchedule(schedules);

        return (
          !!activeSchedule &&
          (user?.settings?.insights?.showHiddenItems ||
            !user?.settings?.insights?.sections?.[
              InsightSections.Habits
            ]?.hiddenItems?.includes(id)) &&
          !parentIds?.length &&
          !archivedAt &&
          !completedAt
        );
      },
    );

    const sortedHabits = sortItems(
      filteredHabits,
      user?.settings?.habitSorting ?? [],
      'id',
    );

    return user?.settings?.insights?.sections?.[InsightSections.Habits]?.order
      ?.length
      ? sortItems(
          sortedHabits,
          user.settings.insights.sections[InsightSections.Habits].order,
          'id',
        )
      : sortedHabits;
  }, [
    habits,
    user?.settings?.habitSorting,
    user?.settings?.insights?.sections,
    user?.settings?.insights?.showHiddenItems,
  ]);
};
