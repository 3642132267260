import styled, { css } from 'styled-components';

export const Label = styled.label<{
  $fullWidth: boolean;
  $isDisabled: boolean;
}>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  opacity: 1;
  transition: opacity 200ms;

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      justify-content: space-between;
      width: 100%;
    `};

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `};
`;

export const Handle = styled.span`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;
