import { addWeeks, eachWeekOfInterval, endOfWeek, isSameMonth } from 'date-fns';
import { weekdaysNumberMap } from 'shared/constants';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

export const getLastWeekDaysInTimeframe = (
  startDate: Timestamp,
  endDate: Timestamp,
  weekStartsOn: WeekDays,
) => {
  const dateFnsWeekOptions = { weekStartsOn: weekdaysNumberMap[weekStartsOn] };
  // the startDate could be in the last month. To exclude the correct weeks, we add a week to the startDate if this is the case.
  // With this, the start and endDate dates should be in the same month,
  // but we still check the start to see if it is in the same month to prevent future months from being added as well.
  const start = isSameMonth(startDate, endDate)
    ? startDate
    : addWeeks(startDate, 1);

  return eachWeekOfInterval(
    { start: startDate, end: endDate },
    dateFnsWeekOptions,
  )
    .map((weekDate) => {
      const eow = endOfWeek(weekDate, dateFnsWeekOptions);
      return isSameMonth(eow, start) ? eow : undefined;
    })
    .filter(Boolean) as Date[];
};
