import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};
`;

export const Body = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing(6)} ${theme.spacing(6)}`};
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(5)};

  padding-bottom: ${({ theme }) => theme.spacing(5)};

  &:last-child {
    padding-bottom: 0;
  }
`;
