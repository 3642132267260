import { useMemo } from 'react';
import { weekdaysNumberMap } from 'shared/constants';
import { useToday } from 'shared/contexts/today';
import { InsightsTimeframes } from 'shared/types/insights';
import { WeekDays } from 'shared/types/week-days';
import { timeframeDatesMap } from 'shared/utils/timeframe-dates-map';

export type Options = {
  weekStartsOn: WeekDays;
  allMonthWeeks?: boolean;
  quarterRoundedToWeeks?: boolean;
  yearRoundedToWeeks?: boolean;
  weekEndsWithStartOfDay?: boolean;
};

export const useTimeByInsightsTimeframe = (
  timeframe: InsightsTimeframes,
  {
    weekStartsOn,
    allMonthWeeks,
    quarterRoundedToWeeks,
    yearRoundedToWeeks,
    weekEndsWithStartOfDay,
  }: Options,
) => {
  const today = useToday();

  return useMemo(() => {
    const extraRange =
      (timeframe === InsightsTimeframes.Week && weekEndsWithStartOfDay) ||
      (timeframe === InsightsTimeframes.Month && allMonthWeeks) ||
      (timeframe === InsightsTimeframes.Quarter && quarterRoundedToWeeks) ||
      (timeframe === InsightsTimeframes.Year && yearRoundedToWeeks) ||
      (timeframe === InsightsTimeframes.TwelveMonths && yearRoundedToWeeks);

    const {
      referenceDate,
      startDate,
      endDate,
      extraRangeStartDate,
      extraRangeEndDate,
    } = timeframeDatesMap[timeframe];

    const dateFnsWeekOptions = {
      weekStartsOn: weekdaysNumberMap[weekStartsOn],
    };
    return {
      referenceDate: referenceDate(today, dateFnsWeekOptions),
      startDate: extraRange
        ? extraRangeStartDate(today, dateFnsWeekOptions)
        : startDate(today, dateFnsWeekOptions),
      endDate: extraRange
        ? extraRangeEndDate(today, dateFnsWeekOptions)
        : endDate(today, dateFnsWeekOptions),
    };
  }, [
    allMonthWeeks,
    quarterRoundedToWeeks,
    timeframe,
    today,
    weekEndsWithStartOfDay,
    weekStartsOn,
    yearRoundedToWeeks,
  ]);
};
