import { isAfter, isBefore, isSameDay } from 'date-fns';
import { useMemo } from 'react';
import { unlimitedLimit } from 'shared/constants';
import { useToday } from 'shared/contexts/today';
import { LimitKeys } from 'shared/types/limit-keys';
import { Timestamp } from 'shared/types/timestamp';
import { User } from 'shared/types/user';

import { useUser } from './use-user';

type LimitCheckFunctionParams = {
  [LimitKeys.GoalUserPhoto]: { user: User; today: Timestamp };
  [LimitKeys.CreateGoal]: {
    totalUncompleted: number;
    user: User;
    today: Timestamp;
  };
  [LimitKeys.SubGoals]: {
    totalUncompleted: number;
    user: User;
    today: Timestamp;
  };
  [LimitKeys.CreateHabit]: { total: number; user: User; today: Timestamp };
  [LimitKeys.HabitIcons]: { user: User; today: Timestamp };
  [LimitKeys.HabitScheduleTarget]: { user: User; today: Timestamp };
  [LimitKeys.BiometricAppLock]: { user: User; today: Timestamp };
  [LimitKeys.CustomLifeAreas]: {
    totalCustom: number;
    user: User;
    today: Timestamp;
  };
  [LimitKeys.CustomizeDefaultLifeAreas]: { user: User; today: Timestamp };
  [LimitKeys.VisionUserPhoto]: { user: User; today: Timestamp };
  [LimitKeys.Insights]: { user: User; today: Timestamp };
};

const goalUserPhoto = ({
  user,
  today,
}: LimitCheckFunctionParams[LimitKeys.GoalUserPhoto]): boolean =>
  // allowed if limits are set to unlimited
  user.limits[LimitKeys.GoalUserPhoto].limit === unlimitedLimit ||
  // allowed if limits are not enforced
  !user.limits[LimitKeys.GoalUserPhoto].enforcedAt ||
  (isAfter(user.limits[LimitKeys.GoalUserPhoto].enforcedAt, today) &&
    !isSameDay(user.limits[LimitKeys.GoalUserPhoto].enforcedAt, today));

const createGoal = ({
  user,
  today,
  totalUncompleted,
}: LimitCheckFunctionParams[LimitKeys.CreateGoal]): boolean =>
  // allowed if limits are set to unlimited
  user.limits[LimitKeys.CreateGoal].limit === unlimitedLimit ||
  // allowed if limits are not enforced
  !user.limits[LimitKeys.CreateGoal].enforcedAt ||
  (isAfter(user.limits[LimitKeys.CreateGoal].enforcedAt, today) &&
    !isSameDay(user.limits[LimitKeys.CreateGoal].enforcedAt, today)) ||
  // allowed if limits are not met
  totalUncompleted < user.limits[LimitKeys.CreateGoal].limit;

const subGoals = ({
  user,
  today,
  totalUncompleted,
}: LimitCheckFunctionParams[LimitKeys.SubGoals]): boolean =>
  // allowed if limits are set to unlimited
  user.limits[LimitKeys.SubGoals].limit === unlimitedLimit ||
  // allowed if limits are not enforced
  !user.limits[LimitKeys.SubGoals].enforcedAt ||
  (isAfter(user.limits[LimitKeys.SubGoals].enforcedAt, today) &&
    !isSameDay(user.limits[LimitKeys.SubGoals].enforcedAt, today)) ||
  // allowed if limits are not met
  totalUncompleted < user.limits[LimitKeys.SubGoals].limit;

const createHabit = ({
  user,
  today,
  total,
}: LimitCheckFunctionParams[LimitKeys.CreateHabit]): boolean =>
  // allowed if limits are set to unlimited
  user.limits[LimitKeys.CreateHabit].limit === unlimitedLimit ||
  // allowed if limits are not enforced
  !user.limits[LimitKeys.CreateHabit].enforcedAt ||
  (isAfter(user.limits[LimitKeys.CreateHabit].enforcedAt, today) &&
    !isSameDay(user.limits[LimitKeys.CreateHabit].enforcedAt, today)) ||
  // allowed if limits are not met
  total < user.limits[LimitKeys.CreateHabit].limit;

const habitIcons = ({
  user,
  today,
}: LimitCheckFunctionParams[LimitKeys.HabitIcons]): boolean =>
  // allowed if limits are set to unlimited
  user.limits[LimitKeys.HabitIcons].limit === unlimitedLimit ||
  // allowed if limits are not enforced
  !user.limits[LimitKeys.HabitIcons].enforcedAt ||
  (isAfter(user.limits[LimitKeys.HabitIcons].enforcedAt, today) &&
    !isSameDay(user.limits[LimitKeys.HabitIcons].enforcedAt, today));

const habitScheduleTarget = ({
  user,
  today,
}: LimitCheckFunctionParams[LimitKeys.HabitScheduleTarget]): boolean =>
  // allowed if limits are set to unlimited
  user.limits[LimitKeys.HabitScheduleTarget].limit === unlimitedLimit ||
  // allowed if limits are not enforced
  !user.limits[LimitKeys.HabitScheduleTarget].enforcedAt ||
  (isAfter(user.limits[LimitKeys.HabitScheduleTarget].enforcedAt, today) &&
    !isSameDay(user.limits[LimitKeys.HabitScheduleTarget].enforcedAt, today));

const biometricAppLock = ({
  user,
  today,
}: LimitCheckFunctionParams[LimitKeys.BiometricAppLock]): boolean =>
  // allowed if limits are set to unlimited
  user.limits[LimitKeys.BiometricAppLock].limit === unlimitedLimit ||
  // allowed if limits are not enforced
  !user.limits[LimitKeys.BiometricAppLock].enforcedAt ||
  (isAfter(user.limits[LimitKeys.BiometricAppLock].enforcedAt, today) &&
    !isSameDay(user.limits[LimitKeys.BiometricAppLock].enforcedAt, today));

const customLifeAreas = ({
  user,
  today,
  totalCustom,
}: LimitCheckFunctionParams[LimitKeys.CustomLifeAreas]): boolean =>
  // allowed if limits are set to unlimited
  user.limits[LimitKeys.CustomLifeAreas].limit === unlimitedLimit ||
  // allowed if limits are not enforced
  !user.limits[LimitKeys.CustomLifeAreas].enforcedAt ||
  (isAfter(user.limits[LimitKeys.CustomLifeAreas].enforcedAt, today) &&
    !isSameDay(user.limits[LimitKeys.CustomLifeAreas].enforcedAt, today)) ||
  // allowed if limits are not met
  totalCustom < user.limits[LimitKeys.CustomLifeAreas].limit;

const customizeDefaultLifeAreas = ({
  user,
  today,
}: LimitCheckFunctionParams[LimitKeys.CustomizeDefaultLifeAreas]): boolean =>
  // allowed if limits are set to unlimited
  user.limits[LimitKeys.CustomizeDefaultLifeAreas].limit === unlimitedLimit ||
  // allowed if limits are not enforced
  !user.limits[LimitKeys.CustomizeDefaultLifeAreas].enforcedAt ||
  (isAfter(
    user.limits[LimitKeys.CustomizeDefaultLifeAreas].enforcedAt,
    today,
  ) &&
    !isSameDay(
      user.limits[LimitKeys.CustomizeDefaultLifeAreas].enforcedAt,
      today,
    ));

const visionUserPhoto = ({
  user,
  today,
}: LimitCheckFunctionParams[LimitKeys.VisionUserPhoto]): boolean =>
  // allowed if limits are set to unlimited
  user.limits[LimitKeys.VisionUserPhoto].limit === unlimitedLimit ||
  // allowed if limits are not enforced
  !user.limits[LimitKeys.VisionUserPhoto].enforcedAt ||
  (isAfter(user.limits[LimitKeys.VisionUserPhoto].enforcedAt, today) &&
    !isSameDay(user.limits[LimitKeys.VisionUserPhoto].enforcedAt, today));

const insights = ({
  user,
  today,
}: LimitCheckFunctionParams[LimitKeys.Insights]): boolean =>
  // allowed if limits are set to unlimited
  user.limits[LimitKeys.Insights].limit === unlimitedLimit ||
  // allowed if limits are not enforced
  !user.limits[LimitKeys.Insights].enforcedAt ||
  (isAfter(user.limits[LimitKeys.Insights].enforcedAt, today) &&
    !isSameDay(user.limits[LimitKeys.Insights].enforcedAt, today));

const limitKeyFunctionMap = {
  [LimitKeys.GoalUserPhoto]: goalUserPhoto,
  [LimitKeys.CreateGoal]: createGoal,
  [LimitKeys.SubGoals]: subGoals,
  [LimitKeys.CreateHabit]: createHabit,
  [LimitKeys.HabitIcons]: habitIcons,
  [LimitKeys.HabitScheduleTarget]: habitScheduleTarget,
  [LimitKeys.BiometricAppLock]: biometricAppLock,
  [LimitKeys.CustomLifeAreas]: customLifeAreas,
  [LimitKeys.CustomizeDefaultLifeAreas]: customizeDefaultLifeAreas,
  [LimitKeys.VisionUserPhoto]: visionUserPhoto,
  [LimitKeys.Insights]: insights,
};

export const usePremiumFeatureAllowed = <LimitKey extends LimitKeys>(
  limitKey: LimitKey,
  options: Omit<LimitCheckFunctionParams[LimitKey], 'user' | 'today'>,
) => {
  const today = useToday();
  const user = useUser();

  return useMemo(() => {
    if (!user) {
      return {
        limit: 0,
        enforced: true,
        enforcedAt: today,
        allowed: false,
      };
    }

    const checkFunction = limitKeyFunctionMap[limitKey] as (
      options: LimitCheckFunctionParams[LimitKey],
    ) => boolean;

    const enforceDate = user.limits[limitKey].enforcedAt;

    return {
      limit: user.limits[limitKey].limit,
      enforced: enforceDate
        ? isBefore(enforceDate, today) || isSameDay(enforceDate, today)
        : false,
      enforcedAt: user.limits[limitKey].enforcedAt,
      allowed: checkFunction({
        ...options,
        user,
        today,
      } as LimitCheckFunctionParams[LimitKey]),
    };
  }, [limitKey, options, today, user]);
};
