import Typography from '@mui/material/Typography';
import { isSameDay } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CornerDownLeft, Trash2 } from 'shared/assets/icons';
import { Button, Sizes } from 'shared/components/ui/button';
import { FormDateInput } from 'shared/components/ui/form-date-input';
import { FormInput } from 'shared/components/ui/form-input';
import { IconButton, Variants } from 'shared/components/ui/icon-button';
import { useToday } from 'shared/contexts/today';
import {
  DateFormatOptions,
  dateFormatOptionToLabelMap,
} from 'shared/types/date-format-options';
import { MetricUnit } from 'shared/types/goal-metric';
import { GoalProgressFormFields } from 'shared/types/goal-progress-form';
import { metricUnitFormatBase } from 'shared/types/metric-template';
import { WeekDays } from 'shared/types/week-days';
import { formatNumberShort } from 'shared/utils/format-number-short';
import { getLocalizedStringValue } from 'shared/utils/get-localized-string-value';
import { isNumber } from 'shared/utils/is-number';

import * as Styled from './goal-progress-form.style';
import { useGoalProgressForm } from './use-goal-progress-form';

export type GoalProgressFormProps = {
  entries: GoalProgressFormFields['entries'];
  dateFormat: DateFormatOptions;
  weekStartsOn: WeekDays;
  unit?: MetricUnit;
  target?: number;
  onSubmit: (entries: GoalProgressFormFields['entries']) => void;
  onCancel: () => void;
};

export const GoalProgressForm: React.FC<GoalProgressFormProps> = ({
  entries,
  dateFormat,
  weekStartsOn,
  unit,
  target,
  onSubmit,
  onCancel,
}) => {
  const { t, i18n } = useTranslation();
  const today = useToday();
  const localizedSymbol = unit?.symbol
    ? getLocalizedStringValue(unit.symbol, i18n.language)
    : undefined;

  const initialNewEntry = useMemo(
    () => ({
      date: !entries.find(({ date }) => isSameDay(today, date))
        ? new Date()
        : null,
    }),
    [entries, today],
  );

  const lastEntry = useMemo(
    () =>
      entries.toSorted(
        (entryA, entryB) => entryB.date.getTime() - entryA.date.getTime(),
      )[0],
    [entries],
  );

  const unitFormat = unit
    ? unit.format ??
      `${metricUnitFormatBase} ${getLocalizedStringValue(unit.name, i18n.language)}`
    : metricUnitFormatBase;

  const formattedTarget =
    isNumber(target) &&
    getLocalizedStringValue(unitFormat, i18n.language).replace(
      metricUnitFormatBase,
      formatNumberShort(target!),
    );

  const {
    register,
    onSubmit: submitForm,
    isValid,
    control,
    trigger,
    setValue,
    getValues,
    setFocus,
  } = useGoalProgressForm(onSubmit, {
    newEntry: initialNewEntry,
    entries: entries,
  });

  const { fields, remove, append, update } = useFieldArray({
    control,
    name: 'entries',
  });

  const sortEntries = () => {
    setValue<'entries'>(
      'entries',
      getValues<'entries'>('entries').sort(
        (entryA, entryB) => entryB.date.getTime() - entryA.date.getTime(),
      ),
    );
  };

  const addNewEntry = async () => {
    const isValidEntry = await trigger('newEntry');
    const newEntry = getValues<'newEntry'>('newEntry');

    // valid means empty or fully filled entry. To make sure it actually is a filled entry we need to check at least 1 field
    if (!isValidEntry || !newEntry?.date) {
      return;
    }

    const date = new Date(newEntry.date);
    // set the current time on the date, as it is missing from the calendar output
    date.setHours(
      new Date().getHours(),
      new Date().getMinutes(),
      new Date().getSeconds(),
      new Date().getMilliseconds(),
    );

    append({
      id: window.crypto.randomUUID(),
      value: Math.round(newEntry.value! * 100) / 100,
      date,
    });

    sortEntries();
    setValue<'newEntry'>('newEntry', { value: null, date: null });

    setFocus('newEntry.value');
  };

  const updateEntry = async (index: number) => {
    const entryValue = getValues<'entries'>('entries')[index];
    const isValidEntry = await trigger(`entries.${index}`);

    if (!isValidEntry) {
      return;
    }

    update(index, {
      ...entryValue,
      value: Math.round(entryValue.value * 100) / 100,
    });
    sortEntries();
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key !== 'Enter') {
      return;
    }

    const activeElement = document.activeElement as HTMLElement;
    if (!activeElement) {
      // without focus on something the normal flow should continue
      return;
    }

    const elementName = activeElement.getAttribute('name') ?? '';

    if (elementName.startsWith('entries.')) {
      event.preventDefault();
      event.stopPropagation();
      // when it's one of the entries, blurring it will trigger the functionality needed.
      activeElement.blur();
      return;
    }

    // if it starts with newEntry, we need to add the entry (if valid)
    if (elementName.startsWith('newEntry.')) {
      event.preventDefault();
      event.stopPropagation();
      addNewEntry();
    }
  };

  useEffect(() => {
    const onEnter = (e: KeyboardEvent) => {
      if (e.key !== 'Enter') {
        return;
      }
      const elementName = document.activeElement?.getAttribute('name') ?? '';

      if (
        elementName.startsWith('entries.') ||
        elementName.startsWith('newEntry.')
      ) {
        return;
      }

      e.preventDefault();
      submitForm();
    };

    window.addEventListener('keydown', onEnter);

    return () => window.removeEventListener('keydown', onEnter);
  }, [submitForm]);

  return (
    <Styled.Form noValidate onSubmit={submitForm}>
      <Styled.Header>
        <Typography variant="h5" component="h1">
          {t('forms.goal-progress.title')}
        </Typography>
        {!!formattedTarget && (
          <Styled.Description>
            {t('forms.goal-progress.description', { target: formattedTarget })}
          </Styled.Description>
        )}
      </Styled.Header>

      <Styled.Body>
        <Styled.Section>
          <FormDateInput
            today={today}
            name={`newEntry.date`}
            control={control}
            dateFormat={dateFormat}
            weekStartsOn={weekStartsOn}
            placeholder={dateFormatOptionToLabelMap[dateFormat]}
            onKeyDown={onKeyDown}
          />
          <FormInput
            {...register(`newEntry.value`)}
            inputMode="decimal"
            type="number"
            postFix={localizedSymbol}
            onKeyDown={onKeyDown}
            placeholder={lastEntry?.value?.toString() ?? '0'}
          />
          <IconButton
            icon={CornerDownLeft}
            variant={Variants.Outlined}
            onClick={addNewEntry}
            type="button"
          />
        </Styled.Section>

        {!!fields.length && (
          <>
            <Styled.Divider />

            <Styled.List>
              {fields.map((field, index) => {
                const onBlur = () => updateEntry(index);

                return (
                  <Styled.ListItem key={field.id}>
                    <Styled.Section>
                      <FormDateInput
                        today={today}
                        name={`entries.${index}.date`}
                        control={control}
                        dateFormat={dateFormat}
                        weekStartsOn={weekStartsOn}
                        placeholder={dateFormatOptionToLabelMap[dateFormat]}
                        onKeyDown={onKeyDown}
                        onBlur={onBlur}
                      />
                      <FormInput
                        {...register(`entries.${index}.value`)}
                        inputMode="decimal"
                        type="number"
                        postFix={localizedSymbol}
                        onKeyDown={onKeyDown}
                        onBlur={onBlur}
                      />
                      <IconButton
                        icon={Trash2}
                        variant={Variants.Outlined}
                        onClick={() => remove(index)}
                      />
                    </Styled.Section>
                  </Styled.ListItem>
                );
              })}
            </Styled.List>
          </>
        )}
      </Styled.Body>

      <Styled.Interactions>
        <Button
          variant={Variants.Outlined}
          size={Sizes.Medium}
          onClick={onCancel}
          type="button"
        >
          {t('forms.goal-progress.buttons.cancel.label')}
        </Button>
        <Button size={Sizes.Medium} type="submit" disabled={!isValid}>
          {t('forms.goal-progress.buttons.save.label')}
        </Button>
      </Styled.Interactions>
    </Styled.Form>
  );
};
