import { ID } from 'shared/types/id';
import { InsightSections, SectionInsights } from 'shared/types/insights';

import { getUser, getUserRef } from './get-user';
import { updateDocWithTimestamp } from './helpers';

export const updateInsightsSection = async (
  sectionId: InsightSections,
  section: SectionInsights,
) => {
  const user = await getUser();

  if (!user) {
    return;
  }

  const userRef = await getUserRef();
  if (!userRef) {
    return;
  }

  const data = Object.entries(section).reduce<Record<string, ID[] | null>>(
    (acc, [key, value]) => {
      acc[`settings.insights.sections.${sectionId}.${key}`] = value;
      return acc;
    },
    {},
  );

  updateDocWithTimestamp(userRef, data);
};
