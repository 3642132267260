import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const HorizontalList = styled.ul`
  display: flex;
  gap: ${({ theme }) => theme.spacing(0.5)};

  margin: 0;
  padding: 0;

  list-style: none;
`;

export const HorizontalListItem = styled.li``;

export const DayButton = styled.button<{ $selected: boolean }>`
  ${({ theme }) => typographyToCss(theme.typography.subtitle2)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 3.2rem;
  height: 3.2rem;

  border-radius: ${({ theme }) => theme.spacing(2)};
  background: transparent;
  border: none;
  cursor: pointer;

  transition:
    color 200ms,
    background 200ms;

  color: ${({ theme }) => theme.palette.text.primary};

  ${({ theme, $selected }) =>
    $selected &&
    css`
      color: ${theme.palette.primary.contrastText};
      background: ${theme.palette.primary.main};
    `};
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const RadioBody = styled.div<{ $disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};

  padding-left: ${({ theme }) => theme.spacing(6)};

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
    `};
`;

export const MonthDayList = styled.ul`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: ${({ theme }) => theme.spacing(0.5)};

  margin: 0;
  padding: 0;

  list-style: none;
`;

export const MonthDayWarning = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: block;
  max-width: 23.6rem;

  font-style: italic;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const MonthDayListItem = styled.li``;

export const TimesPerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;
