import { addMinutes } from 'date-fns';
import { RRule as RRuleBase } from 'rrule';

export class RRule extends RRuleBase {
  static localToNaiveUTC(date: Date) {
    if (!date) {
      return date;
    } // Handle cases where no dtstart is provided
    const timezoneOffset = date.getTimezoneOffset();
    return addMinutes(date, -timezoneOffset);
  }

  static fromString(rruleString: string) {
    // Use the original `fromString` method to parse the rule
    const baseRrule = super.fromString(rruleString);

    // If `dtstart` exists, convert it back to naive UTC
    if (baseRrule.options.dtstart) {
      baseRrule.options.dtstart = RRule.localToNaiveUTC(
        baseRrule.options.dtstart,
      );
    }

    // Return an instance of RRule with adjusted options
    const rrule = new RRule();
    rrule.origOptions = baseRrule.origOptions;
    rrule.options = baseRrule.options;

    return rrule;
  }

  private preProcess() {
    this.options.dtstart = RRule.localToNaiveUTC(this.options.dtstart);
    this.options.until = this.options.until
      ? RRule.localToNaiveUTC(this.options.until)
      : null;
  }
  private postProcess() {
    this.options.dtstart = addMinutes(
      this.options.dtstart,
      this.options.dtstart.getTimezoneOffset(),
    );
    this.options.until = this.options.until
      ? addMinutes(this.options.until, this.options.until.getTimezoneOffset())
      : null;
  }

  after(dt: Date, inc?: boolean): Date | null {
    this.preProcess();
    const naiveUtcDate = RRule.localToNaiveUTC(dt);
    const result = super.after(naiveUtcDate, inc);
    this.postProcess();
    return result
      ? // Convert back to local time
        addMinutes(result, dt.getTimezoneOffset())
      : result;
  }

  between(
    after: Date,
    before: Date,
    inc?: boolean,
    iterator?: (d: Date, len: number) => boolean,
  ): Date[] {
    this.preProcess();
    const naiveUtcStart = RRule.localToNaiveUTC(after);
    const naiveUtcEnd = RRule.localToNaiveUTC(before);
    const results = super.between(naiveUtcStart, naiveUtcEnd, inc, iterator);
    this.postProcess();
    return results.map((result) =>
      addMinutes(result, after.getTimezoneOffset()),
    );
  }

  before(dt: Date, inc?: boolean): Date | null {
    this.preProcess();
    const naiveUtcDate = RRule.localToNaiveUTC(dt);
    const result = super.after(naiveUtcDate, inc);
    this.postProcess();
    return result
      ? // Convert back to local time
        addMinutes(result, dt.getTimezoneOffset())
      : result;
  }
}
