import { isAfter, isBefore, isSameDay, isSameMonth } from 'date-fns';
import React, { useMemo, useRef } from 'react';
import { ArrowRight, X } from 'shared/assets/icons';
import { DayContextMenu } from 'shared/components/ui/day-context-menu';
import { Icon } from 'shared/components/ui/icon';
import { useToday } from 'shared/contexts/today';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useContextMenuState } from 'shared/hooks/use-context-menu-state';
import { isBetween } from 'shared/lib/date-fns';
import { EntryState } from 'shared/types/habit-schedule';
import { Timeframe } from 'shared/types/timeframe';
import { Timestamp } from 'shared/types/timestamp';
import { useTheme } from 'styled-components';

import * as Styled from './habit-progress-calendar.style';

export type DayProps = {
  date: Timestamp;
  monthCompareDate: Timestamp;
  occurrences: Timestamp[];
  completions: Timestamp[];
  skips: Timestamp[];
  failures: Timestamp[];
  perfectSlots?: Timeframe[];
  scheduleChanges?: { date: Timestamp }[];
  onDate?: (date: Timestamp, type: EntryState) => void;
  showDate?: boolean;
  showPrevMonthDate?: boolean;
  showNextMonthDate?: boolean;
};

export const Day: React.FC<DayProps> = ({
  date,
  monthCompareDate,
  occurrences,
  completions,
  skips,
  failures,
  perfectSlots = [],
  scheduleChanges = [],
  onDate,
  showDate,
  showPrevMonthDate,
  showNextMonthDate,
}) => {
  const today = useToday();
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const { position, open, close } = useContextMenuState({
    disabled: theme.isMobile,
  });

  const isPrevMonth = useMemo(
    () =>
      !isSameMonth(date, monthCompareDate) && isBefore(date, monthCompareDate),
    [date, monthCompareDate],
  );

  const isNextMonth = useMemo(
    () =>
      !isSameMonth(date, monthCompareDate) && isAfter(date, monthCompareDate),
    [date, monthCompareDate],
  );

  const isCompleted = useMemo(
    () => !!completions.find((completion) => isSameDay(completion, date)),
    [completions, date],
  );

  const isPerfectDay = useMemo(
    () =>
      !!perfectSlots.find(({ startDate, endDate }) =>
        isBetween(date, startDate, endDate),
      ),
    [date, perfectSlots],
  );

  const isSkipped = useMemo(
    () => !!skips.find((skip) => isSameDay(skip, date)),
    [date, skips],
  );

  const isFailure = useMemo(
    () => !!failures.find((failure) => isSameDay(failure, date)),
    [date, failures],
  );
  const isScheduleChange = useMemo(
    () =>
      !!scheduleChanges.find((scheduleChange) =>
        isSameDay(scheduleChange.date, date),
      ),
    [date, scheduleChanges],
  );

  const onComplete = onDate
    ? () => {
        onDate(date, EntryState.Complete);
        close();
      }
    : undefined;

  const isOccurrence = useMemo(
    () => !!occurrences.find((occurrence) => isSameDay(occurrence, date)),
    [date, occurrences],
  );

  useClickOutside(containerRef, close);

  if (
    (!showPrevMonthDate && isPrevMonth) ||
    (!showNextMonthDate && isNextMonth)
  ) {
    return null;
  }

  return (
    <Styled.DayContainer ref={containerRef} onContextMenu={open}>
      <Styled.Day
        $isToday={isSameDay(date, today)}
        $isOccurrence={isOccurrence}
        $isCompleted={isCompleted}
        $isPerfectDay={isPerfectDay}
        $isScheduleChange={isScheduleChange}
        $isSkipped={isSkipped}
        $isFailure={isFailure}
        $isAfter={isAfter(date, today)}
        $showDate={!!showDate}
        onClick={onComplete}
      >
        {!isCompleted && (isSkipped || isFailure) ? (
          <Styled.DayIcon>
            <Icon icon={isSkipped ? ArrowRight : X} />
          </Styled.DayIcon>
        ) : (
          showDate && date.getDate()
        )}
      </Styled.Day>

      {!!position && !!onDate && (
        <DayContextMenu
          date={date}
          onDate={onDate}
          location={position}
          onClose={close}
        />
      )}
    </Styled.DayContainer>
  );
};
