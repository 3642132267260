import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import React, { useRef, useState } from 'react';
import { HabitInsightsCard as HabitInsightsCardUi } from 'shared/components/ui/habit-insights-card';
import { useOpenTaskStatistics } from 'shared/contexts/task-detail';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useContextMenuState } from 'shared/hooks/use-context-menu-state';

import { ContextMenu } from './context-menu';

export type HabitInsightsCardProps = Omit<
  React.ComponentProps<typeof HabitInsightsCardUi>,
  'onMenuButton' | 'menuButtonRef' | 'goals' | 'lifeAreas' | 'onClick'
> & {};

export const HabitInsightsCard: React.FC<HabitInsightsCardProps> = ({
  habit,
  isPreview,
  ...rest
}) => {
  const contextRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = React.useRef<HTMLButtonElement>(null);
  const lifeAreas = useLocalizedLifeAreas();
  const goals = useGoals();
  const openTaskStatistics = useOpenTaskStatistics();

  const [menuButtonUsed, setMenuButtonUsed] = useState(false);
  const {
    open: openMenu,
    close: closeMenu,
    position: menuPosition,
  } = useContextMenuState({
    onClose: () => setMenuButtonUsed(false),
  });

  const openMenuByButton = (e: React.MouseEvent) => {
    setMenuButtonUsed(true);
    openMenu(e);
  };

  useClickOutside(contextRef, closeMenu);

  return (
    <>
      <HabitInsightsCardUi
        habit={habit}
        goals={goals}
        lifeAreas={lifeAreas}
        onMenuButton={openMenuByButton}
        menuButtonRef={menuButtonRef}
        onContextMenu={openMenu}
        onClick={isPreview ? undefined : openTaskStatistics}
        isPreview={isPreview}
        {...rest}
      />
      {!!menuPosition && (
        <ContextMenu
          ref={contextRef}
          task={habit}
          onClose={closeMenu}
          referenceElement={menuButtonUsed ? menuButtonRef : undefined}
          location={menuButtonUsed ? undefined : menuPosition}
        />
      )}
    </>
  );
};
