import {
  addMilliseconds,
  addMonths,
  addWeeks,
  Day,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import {
  endOfLastWeekOfMonth,
  startOfFirstWeekOfMonth,
} from 'shared/lib/date-fns';
import { InsightsTimeframes } from 'shared/types/insights';
import { Timestamp } from 'shared/types/timestamp';

type DateFnc = (
  date: Timestamp,
  { weekStartsOn }: { weekStartsOn: Day },
) => Timestamp;

type DateFncs = {
  referenceDate: DateFnc;
  startDate: DateFnc;
  endDate: DateFnc;
  extraRangeStartDate: DateFnc;
  extraRangeEndDate: DateFnc;
};

export const timeframeDatesMap: Record<InsightsTimeframes, DateFncs> = {
  [InsightsTimeframes.Week]: {
    referenceDate: startOfWeek,
    startDate: startOfWeek,
    endDate: endOfWeek,
    extraRangeStartDate: startOfWeek,
    extraRangeEndDate: (date, options) =>
      addMilliseconds(startOfDay(endOfWeek(date, options)), 1),
  },
  [InsightsTimeframes.Month]: {
    referenceDate: startOfMonth,
    startDate: startOfMonth,
    endDate: endOfMonth,
    extraRangeStartDate: startOfFirstWeekOfMonth,
    extraRangeEndDate: endOfLastWeekOfMonth,
  },
  [InsightsTimeframes.Quarter]: {
    referenceDate: startOfQuarter,
    startDate: startOfQuarter,
    endDate: endOfQuarter,
    extraRangeStartDate: (date, options) =>
      startOfWeek(startOfQuarter(date), options),
    extraRangeEndDate: (date, options) =>
      endOfWeek(endOfQuarter(date), options),
  },
  [InsightsTimeframes.Year]: {
    referenceDate: startOfYear,
    startDate: startOfYear,
    endDate: endOfYear,
    extraRangeStartDate: (date, options) =>
      startOfWeek(startOfYear(date), options),
    extraRangeEndDate: (date, options) => endOfWeek(endOfYear(date), options),
  },
  [InsightsTimeframes.FourWeeks]: {
    referenceDate: (date, options) => addWeeks(startOfWeek(date, options), -3),
    startDate: (date, options) => addWeeks(startOfWeek(date, options), -3),
    endDate: endOfWeek,
    extraRangeStartDate: (date, options) =>
      addWeeks(startOfWeek(date, options), -3),
    extraRangeEndDate: endOfWeek,
  },
  [InsightsTimeframes.TwelveWeeks]: {
    referenceDate: (date, options) => addWeeks(startOfWeek(date, options), -11),
    startDate: (date, options) => addWeeks(startOfWeek(date, options), -11),
    endDate: endOfWeek,
    extraRangeStartDate: (date, options) =>
      addWeeks(startOfWeek(date, options), -11),
    extraRangeEndDate: endOfWeek,
  },
  [InsightsTimeframes.TwelveMonths]: {
    referenceDate: (date) => addMonths(startOfMonth(date), -11),
    startDate: (date) => addMonths(startOfMonth(date), -11),
    endDate: endOfMonth,
    extraRangeStartDate: (date, options) =>
      addWeeks(startOfWeek(date, options), -51),
    extraRangeEndDate: endOfWeek,
  },
};
