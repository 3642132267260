import Dialog from '@mui/material/Dialog';
import React, { useMemo } from 'react';
import { DataType } from 'shared/types/data-type';
import { Habit } from 'shared/types/habit';
import { InsightsTimeframes } from 'shared/types/insights';
import { Timestamp } from 'shared/types/timestamp';
import { getActiveSchedule } from 'shared/utils/get-active-schedule';

import { InsightsCalculationsMessage } from './insights-calculations-message';

export type InsightsCalculationsDialogProps = {
  onClose: () => void;
  habit?: Habit;
  timeframe?: InsightsTimeframes;
  referenceDate?: Timestamp;
};

export const InsightsCalculationsDialog: React.FC<
  InsightsCalculationsDialogProps
> = ({ onClose, habit, timeframe, referenceDate }) => {
  const activeSchedule = useMemo(
    () => getActiveSchedule(habit?.schedules),
    [habit],
  );

  return (
    <Dialog
      open={!!habit && !!activeSchedule && !!timeframe && !!referenceDate}
      fullWidth
      onClose={onClose}
      // prevent task-detail to close
      data-component-type={DataType.Task}
    >
      {!!habit && !!activeSchedule && !!timeframe && !!referenceDate && (
        <InsightsCalculationsMessage
          name={habit.name}
          schedule={activeSchedule}
          timeframe={timeframe}
          referenceDate={referenceDate}
          onClose={onClose}
        />
      )}
    </Dialog>
  );
};
