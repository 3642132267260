import { index, Options } from 'shared/assets/styles';
import { typographyToCss } from 'shared/lib/mui';
import { ReactDatePicker } from 'shared/lib/react-datepicker';
import styled, { css } from 'styled-components';

export const Container = styled.div<{
  $inline: boolean;
  $bordered: boolean;
}>`
  .react-datepicker {
    border-radius: ${({ theme }) => theme.spacing(2)};
    border-color: ${({ theme }) => theme.palette.divider};
    background: ${({ theme }) => theme.palette.background.default};
    box-shadow: ${({ theme }) => theme.shadowBox.menu};

    ${({ $inline }) =>
      $inline &&
      css`
        background: transparent;
        border-color: transparent;
        box-shadow: unset;
      `};

    ${({ $bordered, theme }) =>
      $bordered &&
      css`
        border: 1px solid ${theme.palette.divider};
      `};
  }

  .react-datepicker__input-container {
    line-height: 62.5%;
  }

  .react-datepicker__header {
    margin: 0;
    padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(2)} 0`};

    background: transparent;
    border-bottom: none;

    ${({ $inline }) =>
      $inline &&
      css`
        padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(1)} 0`};
      `};
  }

  .react-datepicker__aria-live {
    display: none;
  }

  .react-datepicker__day-names {
    margin: 0;
  }

  .react-datepicker__month {
    margin: 0;
    padding: ${({ theme }) => `0 ${theme.spacing(2)} ${theme.spacing(2)}`};

    ${({ $inline }) =>
      $inline &&
      css`
        padding: ${({ theme }) => `0 ${theme.spacing(1)} ${theme.spacing(1)}`};
      `};
  }

  .react-datepicker__day-name,
  .react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    ${({ theme }) => typographyToCss(theme.typography.body2)};
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 3.2rem;
    height: 3.2rem;
    margin: 0;
  }

  .react-datepicker__day {
    border-radius: ${({ theme }) => theme.spacing(2)};

    &:hover {
      background: ${({ theme }) => theme.palette.brown['100']};
    }
  }

  .react-datepicker__day-name {
    ${({ theme }) => typographyToCss(theme.typography.overline)};
  }

  .react-datepicker__day--today {
    ${({ theme }) => typographyToCss(theme.typography.subtitle2)};
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }

  .react-datepicker__day--highlighted {
    border: 1px solid ${({ theme }) => theme.palette.brown['200']};
  }

  .react-datepicker__day--selected {
    background: ${({ theme }) => theme.palette.primary.light};
    color: ${({ theme }) => theme.palette.primary.main};
    border: none;

    &:hover {
      background: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.contrastText};
    }
  }

  .react-datepicker__day--keyboard-selected {
    background: transparent;
  }
`;

export const Datepicker = styled(ReactDatePicker)`
  z-index: ${index(Options.TOOLTIP)};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const HeaderDate = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.subtitle2)};
  display: inline-block;
  padding-left: ${({ theme }) => theme.spacing(1.5)};
`;

export const HeaderInteractions = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
`;
