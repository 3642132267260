import Typography from '@mui/material/Typography';
import { eachWeekOfInterval, format } from 'date-fns';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { X } from 'shared/assets/icons';
import { IconButton, Sizes, Variants } from 'shared/components/ui/icon-button';
import { weekdaysNumberMap } from 'shared/constants';
import { useUser } from 'shared/hooks/use-user';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { InsightsTimeframes } from 'shared/types/insights';
import { Timestamp } from 'shared/types/timestamp';
import { getScheduleLabel } from 'shared/utils/get-schedule-label';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';
import { timeframeDatesMap } from 'shared/utils/timeframe-dates-map';

import * as Styled from './insights-calculations-message.style';

export type InsightsCalculationsMessageProps = {
  name: string;
  schedule: HabitSchedule;
  timeframe: InsightsTimeframes;
  referenceDate: Timestamp;
  onClose: (e: React.MouseEvent) => void;
};

export const InsightsCalculationsMessage: React.FC<
  InsightsCalculationsMessageProps
> = ({ name, schedule, timeframe, referenceDate, onClose }) => {
  const { t } = useTranslation();
  const user = useUser();
  const weekStartsOn = getWeekStartsOn(user);
  const frequencyCount = schedule.frequency?.count ?? 1;

  const { startDate, endDate, extraRangeStartDate, extraRangeEndDate, weeks } =
    useMemo(() => {
      const { startDate, endDate, extraRangeStartDate, extraRangeEndDate } =
        timeframeDatesMap[timeframe];

      const dateFnsWeekOptions = {
        weekStartsOn: weekdaysNumberMap[weekStartsOn],
      };

      const rangeStart = extraRangeStartDate(referenceDate, dateFnsWeekOptions);
      const rangeEnd = extraRangeEndDate(referenceDate, dateFnsWeekOptions);

      return {
        startDate: startDate(referenceDate, dateFnsWeekOptions),
        endDate: endDate(referenceDate, dateFnsWeekOptions),
        extraRangeStartDate: rangeStart,
        extraRangeEndDate: rangeEnd,
        weeks: eachWeekOfInterval(
          { start: rangeStart, end: rangeEnd },
          dateFnsWeekOptions,
        ).length,
      };
    }, [referenceDate, timeframe, weekStartsOn]);

  return (
    <>
      <Styled.Header>
        <Typography variant="h5" component="h1">
          {t('dialogs.insights.calculations.weekly-target.title')}
        </Typography>

        <IconButton
          icon={X}
          onClick={onClose}
          variant={Variants.Contained}
          size={Sizes.Large}
        />
      </Styled.Header>

      <Styled.Body>
        <Typography variant="h6">
          {t(
            'dialogs.insights.calculations.weekly-target.description.statistics.label',
          )}
        </Typography>
        <Styled.Section>
          <Typography variant="body2">
            {t(
              'dialogs.insights.calculations.weekly-target.description.statistics.habit-stats.label',
              {
                name,
                scheduleName: getScheduleLabel(schedule, t),
              },
            )}
          </Typography>
          <Typography variant="body2">
            {t(
              'dialogs.insights.calculations.weekly-target.description.statistics.timeframe.label',
              {
                timeframe: t(`general.labels.timeframes.${timeframe}`),
                startDate: format(startDate, 'EEEE, MMM d'),
                endDate: format(endDate, 'EEEE, MMM d'),
              },
            )}
          </Typography>
          <Typography variant="body2">
            {t(
              'dialogs.insights.calculations.weekly-target.description.statistics.timeframe-stats.label',
              {
                timeframe: t(`general.labels.timeframes.${timeframe}`),
                startDate: format(extraRangeStartDate, 'EEEE, MMM d'),
                endDate: format(extraRangeEndDate, 'EEEE, MMM d'),
              },
            )}
          </Typography>
          <Typography variant="body2">
            {t(
              'dialogs.insights.calculations.weekly-target.description.statistics.schedule-specific-calculations.part-1.label',
              {
                count: weeks,
              },
            )}
            {t(
              'dialogs.insights.calculations.weekly-target.description.statistics.schedule-specific-calculations.part-2.label',
              {
                count: weeks * frequencyCount,
                weeks: weeks,
                frequency: frequencyCount,
              },
            )}
          </Typography>
        </Styled.Section>

        <Typography variant="h6">
          {t(
            'dialogs.insights.calculations.weekly-target.description.perfect-weeks.label',
          )}
        </Typography>
        <Styled.Section>
          <Typography variant="body2">
            {t(
              'dialogs.insights.calculations.weekly-target.description.perfect-weeks.description',
            )}
          </Typography>
        </Styled.Section>
      </Styled.Body>
    </>
  );
};
