import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import React, { useRef, useState } from 'react';
import { GoalInsightsCard as GoalInsightsCardUi } from 'shared/components/ui/goal-insights-card';
import { useClickOutside } from 'shared/hooks/use-click-outside';
import { useContextMenuState } from 'shared/hooks/use-context-menu-state';
import { useUser } from 'shared/hooks/use-user';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

import { ContextMenu } from './context-menu';

export type GoalInsightsCardProps = Omit<
  React.ComponentProps<typeof GoalInsightsCardUi>,
  'onMenuButton' | 'menuButtonRef' | 'goals' | 'lifeAreas' | 'weekStartsOn'
> & {};

export const GoalInsightsCard: React.FC<GoalInsightsCardProps> = ({
  goal,
  ...rest
}) => {
  const contextRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = React.useRef<HTMLButtonElement>(null);
  const user = useUser();
  const lifeAreas = useLocalizedLifeAreas();
  const goals = useGoals();

  const [menuButtonUsed, setMenuButtonUsed] = useState(false);
  const {
    open: openMenu,
    close: closeMenu,
    position: menuPosition,
  } = useContextMenuState({
    onClose: () => setMenuButtonUsed(false),
  });

  const openMenuByButton = (e: React.MouseEvent) => {
    setMenuButtonUsed(true);
    openMenu(e);
  };

  const weekStartsOn = getWeekStartsOn(user);

  useClickOutside(contextRef, closeMenu);

  return (
    <>
      <GoalInsightsCardUi
        goal={goal}
        goals={goals}
        lifeAreas={lifeAreas}
        onMenuButton={openMenuByButton}
        menuButtonRef={menuButtonRef}
        weekStartsOn={weekStartsOn}
        onContextMenu={openMenu}
        {...rest}
      />
      {!!menuPosition && (
        <ContextMenu
          ref={contextRef}
          goal={goal}
          onClose={closeMenu}
          referenceElement={menuButtonUsed ? menuButtonRef : undefined}
          location={menuButtonUsed ? undefined : menuPosition}
        />
      )}
    </>
  );
};
