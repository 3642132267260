import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  margin: 0;
  padding: 0;

  list-style: none;
`;

export const ListItem = styled.li`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};

  &:before {
    content: '';
    display: block;

    width: 0.6rem;
    min-width: 0.6rem;
    height: 0.6rem;
    margin-top: ${({ theme }) => theme.spacing(1.5)};

    border-radius: 50%;
    background: ${({ theme }) => theme.palette.warning.main};
  }
`;

export const ChangeContainer = styled.span`
  color: ${({ theme }) => theme.palette.text.secondary};
  line-height: 62.5%;
`;

export const Date = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.caption)};
`;

export const From = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
`;

export const To = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.caption)};
`;
