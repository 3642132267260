import { useMemo } from 'react';
import { MetricEntry } from 'shared/types/goal-metric';
import { Timestamp } from 'shared/types/timestamp';
import { interpolateDataEntries } from 'shared/utils/interpolate-data-entries';

export type Options = {
  entries: MetricEntry[];
  startDate: Timestamp;
  endDate: Timestamp;
  target?: number;
};

export const useTimeframeDifference = ({
  entries,
  startDate,
  endDate,
  target,
}: Options) =>
  useMemo(() => {
    if (!target) {
      return 0;
    }
    const interpolatedEntries = interpolateDataEntries(
      entries,
      startDate,
      endDate,
    );
    const firstEntry = interpolatedEntries[0];
    const lastEntry = interpolatedEntries.pop();

    if (!firstEntry || !lastEntry || firstEntry.value === lastEntry.value) {
      return 0;
    }

    const currentPercentage = (lastEntry.value / target) * 100;

    const startPercentage = (firstEntry.value / target) * 100;

    return currentPercentage - startPercentage || 0;
  }, [entries, target, startDate, endDate]);
