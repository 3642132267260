import { Bar as VisxBar } from '@visx/shape';
import { rgba } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const SVG = styled.svg``;

export const Bar = styled(VisxBar)<{
  $isTarget?: boolean;
  $isCompleted?: boolean;
}>`
  fill: ${({ $isTarget, $isCompleted, theme }) =>
    $isCompleted
      ? theme.palette.success.dark
      : $isTarget
        ? rgba(theme.palette.divider, 0.4)
        : theme.palette.primary.main};
`;
