import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import {
  useArchiveTask,
  useCompleteTask,
  useCreateHabitAllowed,
  useCreateTask,
  useUpdateTask,
} from 'features/tasks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useOpenDeleteHabitDialog } from 'shared/contexts/delete-habit';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { HabitFormDialog } from 'shared/dialogs/habit-form';
import { useUpdateGoalTaskCountMutation } from 'shared/hooks/queries/use-update-goal-task-count-mutation';
import { usePremiumFeatureAllowed } from 'shared/hooks/use-premium-feature-allowed';
import { useUser } from 'shared/hooks/use-user';
import { initialDateFormat } from 'shared/types/date-format-options';
import { Habit, NewHabit } from 'shared/types/habit';
import { HabitFormFields } from 'shared/types/habit-form';
import { ID } from 'shared/types/id';
import { LimitKeys } from 'shared/types/limit-keys';
import { RepeatingTask } from 'shared/types/repeating-task';
import { TaskType } from 'shared/types/task-base';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

import { HabitFormContext, HabitFormContextProps } from './habit-form-context';

export type HabitFormProviderProps = {
  children: React.ReactNode;
};

export const HabitFormProvider: React.FC<HabitFormProviderProps> = ({
  children,
}) => {
  const [habitId, setHabitId] = useState<ID>();
  const [habit, setHabit] = useState<Habit | RepeatingTask>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [initialValues, setInitialValues] =
    useState<Partial<HabitFormFields>>();

  const user = useUser();
  const { allowed: customIconAllowed } = usePremiumFeatureAllowed(
    LimitKeys.HabitIcons,
    {},
  );
  const { allowed: createHabitAllowed } = useCreateHabitAllowed();
  const { allowed: scheduleTargetAllowed } = usePremiumFeatureAllowed(
    LimitKeys.HabitScheduleTarget,
    {},
  );
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  // useOpenPremiumDialog is part of a higher level context which should be kept in mind (probably different setup needed)
  const openPremiumDialog = useOpenPremiumDialog();
  const { mutate: updateGoalTaskCount } = useUpdateGoalTaskCountMutation();

  const create = useCreateTask();
  const update = useUpdateTask();
  const completeHabit = useCompleteTask();
  const archiveHabit = useArchiveTask();
  const deleteHabit = useOpenDeleteHabitDialog();
  const { retry, isLoading, error, isSuccess, reset } = useMemo(
    () => (habitId ? update : create),
    [create, habitId, update],
  );

  const openDialog = useCallback(
    (initialValues?: Partial<HabitFormFields>, id?: ID) => {
      setHabitId(id);
      setInitialValues(initialValues);
      setDialogOpen(true);
    },
    [setDialogOpen],
  );
  const openDialogWithHabit = useCallback(
    (habit: Habit | RepeatingTask) => {
      setHabitId(habit.id);
      setHabit(habit);
      setInitialValues(habit);
      setDialogOpen(true);
    },
    [setDialogOpen],
  );

  const closeDialog = useCallback(() => {
    reset();
    setInitialValues(undefined);
    setHabitId(undefined);
    setHabit(undefined);
    setDialogOpen(false);
  }, [reset]);

  const submitForm = useCallback(
    (values: HabitFormFields) => {
      if (!values.name) {
        return;
      }

      // update goal task count when the goalId is updated
      if (values.goalId !== habit?.goalId) {
        values.goalId &&
          updateGoalTaskCount({
            goalId: values.goalId,
            taskCount: 1,
            completedTaskCount: habit?.completedAt ? 1 : 0,
          });
        habit?.goalId &&
          updateGoalTaskCount({
            goalId: habit.goalId,
            taskCount: -1,
            completedTaskCount: habit.completedAt ? -1 : 0,
          });
      }

      if (habitId) {
        update.submit({ ...values, id: habitId } as Habit);
        return;
      }
      create.submit(values as unknown as NewHabit);
    },
    [
      create,
      habit?.completedAt,
      habit?.goalId,
      habitId,
      update,
      updateGoalTaskCount,
    ],
  );

  const onComplete = () => {
    if (!habit) {
      return;
    }
    completeHabit(habit);
    closeDialog();
  };

  const onArchive = () => {
    if (!habit) {
      return;
    }
    archiveHabit(habit);
    habit.goalId &&
      updateGoalTaskCount({
        goalId: habit.goalId,
        taskCount: -1,
        completedTaskCount: habit.completedAt ? -1 : 0,
      });
    closeDialog();
  };

  const onDelete = () => {
    if (!habit) {
      return;
    }

    deleteHabit(habit as Habit);
    closeDialog();
  };

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [closeDialog, isSuccess]);

  const value = useMemo<HabitFormContextProps>(
    () => ({
      onCreateHabit: openDialog,
      onEditHabit: openDialogWithHabit,
    }),
    [openDialog, openDialogWithHabit],
  );

  return (
    <HabitFormContext.Provider value={value}>
      {children}
      <HabitFormDialog
        open={dialogOpen}
        initialValues={initialValues}
        goals={goals}
        lifeAreas={lifeAreas}
        dateFormat={user?.settings?.dateFormat ?? initialDateFormat}
        weekStartsOn={getWeekStartsOn(user)}
        onClose={closeDialog}
        onSubmit={submitForm}
        onRetry={retry}
        isLoading={isLoading}
        isError={!!error}
        isEdit={!!habitId}
        isCustomIconAllowed={customIconAllowed}
        isRepeatTargetAllowed={scheduleTargetAllowed}
        isHabitCreationAllowed={
          createHabitAllowed || habit?.type === TaskType.Habit
        }
        onPremium={openPremiumDialog}
        isCompleted={!!habit?.completedAt}
        isArchived={!!habit?.archivedAt}
        onComplete={habit ? onComplete : undefined}
        onArchive={habit ? onArchive : undefined}
        onDelete={habit ? onDelete : undefined}
      />
    </HabitFormContext.Provider>
  );
};
