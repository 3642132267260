import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { WeekDays } from 'shared/types/week-days';
import { getWeekDays } from 'shared/utils/get-week-days';

import * as Styled from './form-body.style';
import { parseWeekDaysFromRrule, setWeekDaysInRrule } from './parse-rrule';

export type WeeksFormProps = {
  rrule: string;
  weekStartsOn: WeekDays;
  onChange: (rrule: string) => void;
};

export const RruleWeeksBody: React.FC<WeeksFormProps> = ({
  rrule,
  weekStartsOn,
  onChange,
}) => {
  const { t } = useTranslation();
  const selectedWeekDays = useMemo(
    () => parseWeekDaysFromRrule(rrule),
    [rrule],
  );

  const onChangeWeekDay = (e: React.MouseEvent) => {
    e.preventDefault();
    const dayValue = e.currentTarget.getAttribute('data-value');

    const newSelectedWeekDays = selectedWeekDays.filter(
      (weekDay) => weekDay !== dayValue,
    );
    if (dayValue && newSelectedWeekDays.length === selectedWeekDays.length) {
      newSelectedWeekDays.push(dayValue as WeekDays);
    }

    onChange(setWeekDaysInRrule(rrule, newSelectedWeekDays));
  };

  const weekDayOptions = useMemo(
    () =>
      getWeekDays(weekStartsOn).map((weekDay) => ({
        label: t(
          `general.labels.habit.repeat.value.specific-days.days.${weekDay}.label`,
        ),
        value: weekDay,
      })),
    [t, weekStartsOn],
  );

  return (
    <Styled.Container>
      <Typography variant="body2">
        {t('forms.habit.fields.repeat.weeks.specific-days.label')}
      </Typography>

      <Styled.HorizontalList>
        {weekDayOptions.map(({ label, value }) => (
          <Styled.HorizontalListItem key={value}>
            <Styled.DayButton
              onClick={onChangeWeekDay}
              data-value={value}
              $selected={selectedWeekDays.includes(value)}
              type="button"
            >
              {label}
            </Styled.DayButton>
          </Styled.HorizontalListItem>
        ))}
      </Styled.HorizontalList>
    </Styled.Container>
  );
};
