import { useMemo } from 'react';
import { BarChartEntry } from 'shared/lib/recharts';

export type Options = {
  startValue?: number;
  endValue?: number;
};

export const useYAxisData = (
  data: BarChartEntry[],
  { startValue = 0, endValue }: Options,
) => {
  const domain = useMemo(() => {
    const entryValues = data
      .map(({ value }) => value)
      .filter(Boolean) as number[];
    const entryTargets = data
      .map(({ target }) => target)
      .filter(Boolean) as number[];
    const endVal = endValue ?? startValue + 1;
    const minValue = Math.min(
      ...entryValues,
      ...entryTargets,
      startValue,
      endVal,
    );
    const maxValue = Math.max(
      ...entryValues,
      ...entryTargets,
      startValue,
      endVal,
    );
    return [minValue, maxValue];
  }, [data, endValue, startValue]);

  const ticks = useMemo(() => {
    const difference = domain[1] - domain[0];

    // check if difference contains decimal
    if ((difference / 2) % 1 > 0) {
      return [domain[0], domain[1]];
    }

    return [domain[0], domain[0] + difference / 2, domain[1]];
  }, [domain]);

  return {
    domain,
    ticks,
  };
};
