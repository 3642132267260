import { addWeeks } from 'date-fns';
import { useMemo } from 'react';
import { isBetween } from 'shared/lib/date-fns';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';

export type Options = {
  startDate: Timestamp;
  endDate: Timestamp;
};

export const useTimeframeSkips = (
  schedules: HabitSchedule[],
  { startDate, endDate }: Options,
) => {
  // get a bigger range for extra completions to display in the month-view
  const rangeStartDate = useMemo(() => addWeeks(startDate, -3), [startDate]);
  const rangeEndDate = useMemo(() => addWeeks(endDate, 3), [endDate]);

  const skips = useMemo(
    () =>
      schedules
        .map(({ skips }) => skips ?? [])
        .flat(1)
        .filter((skip) => isBetween(skip, rangeStartDate, rangeEndDate)),
    [rangeEndDate, rangeStartDate, schedules],
  );

  const timeframeSkips = useMemo(
    () => skips.filter((skip) => isBetween(skip, startDate, endDate)),
    [skips, endDate, startDate],
  );

  return {
    skips,
    timeframeSkips,
  };
};
