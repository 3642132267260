import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { lifeAreaOtherID } from 'shared/types/orderable-section';
import { SortingMode } from 'shared/types/sorting-mode';
import { TaskCardTask } from 'shared/types/task-card-task';
import { getSortedTasks } from 'shared/utils/get-sorted-tasks';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';
import { sortItems } from 'shared/utils/sort-items';

import { useTodayTasks } from './use-today-tasks';

export const useLifeAreaTaskSections = () => {
  const { t } = useTranslation();
  const today = useToday();

  const user = useUser();

  const tasks = useTodayTasks();
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();

  const sections = useMemo(() => {
    const filteredGoals = goals.filter(
      ({ id }) => !!tasks.find(({ goalId }) => goalId === id),
    );
    const otherGoalIds = goals
      .filter(({ lifeAreaId }) => !lifeAreaId)
      .map(({ id }) => id);

    return [
      ...sortItems(lifeAreas, user?.settings?.lifeAreaSorting ?? [], 'id')
        .filter(({ id }) => {
          return (
            !!filteredGoals.find(({ lifeAreaId }) => lifeAreaId === id) ||
            !!tasks.find(({ lifeAreaId }) => lifeAreaId === id)
          );
        })
        .map((lifeArea) => {
          const lifeAreaGoalIds = filteredGoals
            .filter(({ lifeAreaId }) => lifeAreaId === lifeArea.id)
            .map(({ id }) => id);

          return {
            id: lifeArea.id,
            lifeArea,
            items: getSortedTasks(
              tasks.filter(
                ({ goalId, lifeAreaId }) =>
                  (goalId && lifeAreaGoalIds.includes(goalId)) ||
                  lifeAreaId === lifeArea.id,
              ),
              {
                today,
                weekStartsOn: getWeekStartsOn(user),
                mode: Array.from(
                  new Set([
                    SortingMode.DueDate,
                    user?.settings?.todoSortingMode,
                  ]),
                ).filter(Boolean) as SortingMode[],
                order:
                  user?.settings?.todayGroupSorting?.lifeArea?.sorting?.[
                    lifeArea.id
                  ] ?? [],
              },
            ).map((task) =>
              mapTaskToTaskCard(task, {
                today,
                goals,
                t,
                showDue: true,
                hideTodayDue: true,
                weekStartsOn: getWeekStartsOn(user),
              }),
            ),
          };
        }),
      {
        id: lifeAreaOtherID,
        items: getSortedTasks(
          tasks.filter(
            ({ goalId, lifeAreaId }) =>
              (!goalId || otherGoalIds.includes(goalId)) && !lifeAreaId,
          ),
          {
            today,
            weekStartsOn: getWeekStartsOn(user),
            mode: Array.from(
              new Set([SortingMode.DueDate, user?.settings?.todoSortingMode]),
            ).filter(Boolean) as SortingMode[],
            order:
              user?.settings?.todayGroupSorting?.lifeArea?.sorting?.['null'] ??
              [],
          },
        ).map((task) =>
          mapTaskToTaskCard(task, {
            today,
            goals,
            t,
            showDue: true,
            hideTodayDue: true,
            weekStartsOn: getWeekStartsOn(user),
          }),
        ),
      },
    ];
  }, [goals, lifeAreas, t, tasks, today, user]);

  const taskCardTasks = useMemo(
    () =>
      sections.reduce<TaskCardTask[]>((acc, { items }) => {
        acc.push(...items);
        return acc;
      }, []),
    [sections],
  );

  return {
    sections,
    taskCardTasks,
  };
};
