import {
  addDays,
  addWeeks,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachWeekOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  isSameDay,
  isSameWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { weekdaysNumberMap } from 'shared/constants';
import { Timeframe } from 'shared/types/timeframe';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

export enum Interval {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

const intervalFuncMap = {
  [Interval.Day]: {
    eachOfInterval: eachDayOfInterval,
    timeframeStartFn: startOfDay,
    timeframeEndFn: endOfDay,
    isSameTimeframeFn: isSameDay,
    addTimeframeFn: addDays,
  },
  [Interval.Week]: {
    eachOfInterval: eachWeekOfInterval,
    timeframeStartFn: startOfWeek,
    timeframeEndFn: endOfWeek,
    isSameTimeframeFn: isSameDay,
    addTimeframeFn: addDays,
  },
  [Interval.Month]: {
    eachOfInterval: eachMonthOfInterval,
    timeframeStartFn: startOfMonth,
    timeframeEndFn: endOfMonth,
    isSameTimeframeFn: isSameWeek,
    addTimeframeFn: addWeeks,
  },
};

export type Options = {
  interval: Interval;
  weekStartsOn: WeekDays;
};

export const getTimeframesForDateRange = (
  startDate: Timestamp,
  endDate: Timestamp,
  { interval, weekStartsOn }: Options,
): Timeframe[] => {
  const {
    eachOfInterval,
    timeframeStartFn,
    timeframeEndFn,
    isSameTimeframeFn,
    addTimeframeFn,
  } = intervalFuncMap[interval];
  const dateFnsWeekOptions = { weekStartsOn: weekdaysNumberMap[weekStartsOn] };

  const start = isSameTimeframeFn(
    timeframeStartFn(startDate, dateFnsWeekOptions),
    startDate,
  )
    ? startDate
    : timeframeStartFn(addTimeframeFn(startDate, 1), dateFnsWeekOptions);
  const end = isSameTimeframeFn(
    timeframeStartFn(endDate, dateFnsWeekOptions),
    endDate,
  )
    ? endDate
    : timeframeEndFn(addTimeframeFn(endDate, -1), dateFnsWeekOptions);

  return eachOfInterval({ start, end }, dateFnsWeekOptions).map(
    (timeframeStartDate, index, arr) => ({
      referenceDate: timeframeStartDate,
      startDate: isSameDay(start, timeframeStartDate)
        ? startDate
        : timeframeStartDate,
      endDate:
        index === arr.length - 1
          ? endDate
          : timeframeEndFn(timeframeStartDate, dateFnsWeekOptions),
    }),
  );
};
