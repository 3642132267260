import { TFunction } from 'i18next';
import { Frequency, RRule } from 'shared/lib/rrule';
import { TaskTemplateSchedule } from 'shared/types/goal-template';
import {
  FrequencyUnit,
  HabitSchedule,
  HabitScheduleTimesPer,
} from 'shared/types/habit-schedule';
import { WeekDays, weekendDays, workWeek } from 'shared/types/week-days';
import { formatRruleWeekDays } from 'shared/utils/format-rrule-week-days';

export const getScheduleLabel = (
  schedule: HabitSchedule | TaskTemplateSchedule,
  t: TFunction,
) => {
  const rrule = RRule.fromString(schedule.rrule.format);
  const frequency = (schedule as HabitScheduleTimesPer).frequency;
  const weekDays = formatRruleWeekDays(schedule.rrule.format);
  const isInterval =
    !!rrule.origOptions.interval && rrule.origOptions.interval > 1;

  // yearly
  if (
    // frequency?.unit === FrequencyUnit.Yearly ||
    rrule.origOptions.freq === Frequency.YEARLY
  ) {
    let dayLabel = 'day';
    if (weekDays.length === 1) {
      dayLabel = weekDays[0];
    }

    if (
      weekDays.length === workWeek.length &&
      workWeek.every((weekDay) => weekDays.includes(weekDay))
    ) {
      dayLabel = 'work-day';
    }

    if (
      weekDays.length === weekendDays.length &&
      weekendDays.every((weekendDay) => weekDays.includes(weekendDay))
    ) {
      dayLabel = 'weekend';
    }

    return t('general.labels.habit.repeat.value.per-year.label', {
      count: rrule.origOptions.interval ?? 1,
      ordinalNumber: t(
        `general.labels.habit.repeat.value.ordinal.specific-day.${rrule.origOptions.bysetpos}.label.short`,
      ),
      day: t(
        `general.labels.habit.repeat.value.specific-days.days.${dayLabel}.label`,
      ),
      month: t(
        `general.labels.habit.repeat.year.months.${rrule.origOptions.bymonth}.label.short`,
      ),
    });
  }

  // monthly
  if (
    frequency?.unit === FrequencyUnit.Month ||
    rrule.origOptions.freq === Frequency.MONTHLY
  ) {
    if (frequency) {
      return t('general.labels.habit.repeat.value.target.per-month.label', {
        count: frequency.count,
        label:
          frequency.label ??
          t('general.labels.habit.repeat.value.target.default.label', {
            count: frequency.count,
          }),
      });
    }

    if (rrule.origOptions.bysetpos) {
      let dayLabel = 'day';
      if (weekDays.length === 1) {
        dayLabel = weekDays[0];
      }

      if (
        weekDays.length === workWeek.length &&
        workWeek.every((weekDay) => weekDays.includes(weekDay))
      ) {
        dayLabel = 'work-day';
      }

      if (
        weekDays.length === weekendDays.length &&
        weekendDays.every((weekendDay) => weekDays.includes(weekendDay))
      ) {
        dayLabel = 'weekend';
      }

      return t(
        'general.labels.habit.repeat.value.specific-day.per-month.label',
        {
          count: rrule.origOptions.interval ?? 1,
          ordinalNumber: t(
            `general.labels.habit.repeat.value.ordinal.specific-day.${rrule.origOptions.bysetpos}.label.short`,
          ),
          day: t(
            `general.labels.habit.repeat.specific-days.days.${dayLabel}.label`,
          ),
        },
      );
    }

    if (rrule.origOptions.bymonthday === -1) {
      return t(
        'general.labels.habit.repeat.value.specific-day.per-month.label',
        {
          count: rrule.origOptions.interval ?? 1,
          ordinalNumber: t(
            `general.labels.habit.repeat.value.ordinal.specific-day.${rrule.origOptions.bymonthday}.label.short`,
          ),
          day: t('general.labels.habit.repeat.specific-days.days.day.label'),
        },
      );
    }

    if (rrule.origOptions.bymonthday) {
      const days = [
        ...(Array.isArray(rrule.origOptions.bymonthday)
          ? rrule.origOptions.bymonthday
          : [rrule.origOptions.bymonthday]),
      ]
        .sort((monthDayA, monthDayB) => monthDayA - monthDayB)
        .map((monthDay) => t(`general.labels.${monthDay}.ordinal`))
        .join(', ');

      return isInterval
        ? t('general.labels.habit.repeat.value.on-interval.label', {
            interval: rrule.origOptions.interval,
            unit: t(
              'general.labels.habit.repeat.value.interval.unit.month.short.label',
              {
                count: rrule.origOptions.interval,
              },
            ),
            specification: days,
          })
        : t(
            'general.labels.habit.repeat.value.specific-days.days.every.label',
            { day: days },
          );
    }
  }

  // weekly
  if (frequency) {
    return t('general.labels.habit.repeat.value.target.per-week.label', {
      count: frequency.count,
      label:
        frequency.label ??
        t('general.labels.habit.repeat.value.target.default.label', {
          count: frequency.count,
        }),
    });
  }

  // check if it is set on every day
  if (weekDays.length === Object.keys(WeekDays).length) {
    return isInterval
      ? t('general.labels.habit.repeat.value.on-interval.label', {
          interval: rrule.origOptions.interval,
          unit: t(
            'general.labels.habit.repeat.value.interval.unit.week.short.label',
            {
              count: rrule.origOptions.interval,
            },
          ),
          specification: t(
            'general.labels.habit.repeat.value.specific-days.days.every-day.label',
          ),
        })
      : t(
          'general.labels.habit.repeat.value.specific-days.days.every-day.label',
        );
  }

  // check if it is a workweek
  if (
    workWeek.length === weekDays.length &&
    weekDays
      .toSorted()
      .every((val, index) => val === workWeek.toSorted()[index])
  ) {
    return isInterval
      ? t('general.labels.habit.repeat.value.on-interval.label', {
          interval: rrule.origOptions.interval,
          unit: t(
            'general.labels.habit.repeat.value.interval.unit.week.short.label',
            {
              count: rrule.origOptions.interval,
            },
          ),
          specification: t(
            'general.labels.habit.repeat.value.specific-days.days.week-day.label',
            {
              count: 2,
            },
          ),
        })
      : t('general.labels.habit.repeat.value.specific-days.days.every.label', {
          day: t(
            'general.labels.habit.repeat.value.specific-days.days.week-day.label',
            {
              count: 1,
            },
          ),
        });
  }

  const days = weekDays.map((weekday) =>
    t(`general.labels.habit.repeat.value.specific-days.days.${weekday}.label`),
  );

  // check if it is set on a single day
  if (weekDays.length === 1) {
    return isInterval
      ? t('general.labels.habit.repeat.value.on-interval.label', {
          interval: rrule.origOptions.interval,
          unit: t(
            'general.labels.habit.repeat.value.interval.unit.week.short.label',
            {
              count: rrule.origOptions.interval,
            },
          ),
          specification: days[0],
        })
      : t('general.labels.habit.repeat.value.specific-days.days.every.label', {
          day: days[0],
        });
  }

  // map all days to specific format
  return isInterval
    ? t('general.labels.habit.repeat.value.on-interval.label', {
        interval: rrule.origOptions.interval,
        unit: t(
          'general.labels.habit.repeat.value.interval.unit.week.short.label',
          {
            count: rrule.origOptions.interval,
          },
        ),
        specification: days.join(', '),
      })
    : t('general.labels.habit.repeat.value.specific-days.days.every.label', {
        day: days.join(', '),
      });
};
