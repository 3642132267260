import { useArchiveTask } from 'features/tasks';
import { useToggleHiddenInsightsItem } from 'features/user';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Archive, Edit3, EyeHidden, Trash2 } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useOpenDeleteHabitDialog } from 'shared/contexts/delete-habit';
import { useOpenEditHabitDialog } from 'shared/contexts/habit-form';
import { useUser } from 'shared/hooks/use-user';
import { Habit } from 'shared/types/habit';
import { InsightSections } from 'shared/types/insights';

export type ContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children' | 'ref'
> & {
  task: Habit;
  onClose: () => void;
};

export const ContextMenu = forwardRef<HTMLDivElement, ContextMenuProps>(
  ({ task, onClose, referenceElement, location }, ref) => {
    const { t } = useTranslation();
    const user = useUser();
    const openHabitDialog = useOpenEditHabitDialog();
    const archiveTask = useArchiveTask();
    const deleteHabit = useOpenDeleteHabitDialog();
    const toggleInsightsHabit = useToggleHiddenInsightsItem(
      InsightSections.Habits,
    );
    const isHidden = !!user?.settings?.insights?.sections?.[
      InsightSections.Habits
    ]?.hiddenItems?.includes(task.id);

    const onHide = () => {
      toggleInsightsHabit(task.id);
      onClose();
    };

    const onEdit = () => {
      openHabitDialog(task, task.id);
      onClose();
    };

    const onArchive = () => {
      archiveTask(task);
      onClose();
    };

    const onDelete = () => {
      deleteHabit(task);
      onClose();
    };

    return (
      <PopupMenu
        ref={ref}
        location={location}
        referenceElement={referenceElement}
        position="bottom-start"
      >
        <PopupMenuList hasBottomBorder>
          <PopupMenuListItem>
            <PopupMenuButton onClick={onHide} start={<Icon icon={EyeHidden} />}>
              {t(
                isHidden
                  ? 'context-menus.insights.show.label'
                  : 'context-menus.insights.hide.label',
              )}
            </PopupMenuButton>
          </PopupMenuListItem>
        </PopupMenuList>
        <PopupMenuList hasBottomBorder>
          <PopupMenuListItem>
            <PopupMenuButton onClick={onEdit} start={<Icon icon={Edit3} />}>
              {t('context-menus.task.labels.edit')}
            </PopupMenuButton>
          </PopupMenuListItem>
        </PopupMenuList>
        <PopupMenuList>
          <PopupMenuListItem>
            <PopupMenuButton
              onClick={onArchive}
              start={<Icon icon={Archive} />}
            >
              {t(
                task.archivedAt
                  ? 'context-menus.task.labels.de-archive'
                  : 'context-menus.task.labels.archive',
              )}
            </PopupMenuButton>
          </PopupMenuListItem>
          <PopupMenuListItem>
            <PopupMenuButton onClick={onDelete} start={<Icon icon={Trash2} />}>
              {t('context-menus.task.labels.delete')}
            </PopupMenuButton>
          </PopupMenuListItem>
        </PopupMenuList>
      </PopupMenu>
    );
  },
);
