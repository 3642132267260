import { ID } from './id';

export enum InsightsTimeframes {
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year',
  FourWeeks = 'four-weeks',
  TwelveWeeks = 'twelve-weeks',
  TwelveMonths = 'twelve-months',
}

export enum InsightSections {
  Goals = 'goals',
  Habits = 'habits',
  Lifescan = 'lifescan',
}

export const insightsTimeframes = Object.values(InsightsTimeframes);

export const fallbackTimeframe = InsightsTimeframes.Month;

export type SectionInsights = {
  hiddenItems: ID[];
  extraItems: ID[];
  order: ID[];
};

export type Insights = {
  timeframe?: InsightsTimeframes;
  showHiddenItems?: boolean;
  showActiveSubGoals?: boolean;
  sections?: Partial<Record<InsightSections, SectionInsights | null>> | null;
};
