import { useGoalById, useGoals, useSubGoals } from 'features/goals';
import { useTasks, useTasksByGoalId } from 'features/tasks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';
import { processGoalsAndTasks } from 'shared/utils/process-goals-and-tasks';

export const useActionPlanItems = () => {
  const { t } = useTranslation();
  const today = useToday();
  const { id } = useParams();

  const user = useUser();
  const goal = useGoalById(id);
  const goals = useGoals();
  const tasks = useTasksByGoalId(id);
  const subGoals = useSubGoals(id);
  const subTasks = useTasks(
    { goals: [...subGoals.map(({ id }) => id)], uncompletedOnly: true },
    !!id && !!subGoals.length,
  );

  const items = useMemo(
    () =>
      processGoalsAndTasks([
        ...(goal ? [goal] : []),
        ...subGoals.filter(({ archivedAt }) => !archivedAt),
        ...subTasks.map((task) =>
          mapTaskToTaskCard(task, {
            goals,
            t,
            today,
            showDue: true,
            hideGoal: true,
            weekStartsOn: getWeekStartsOn(user),
          }),
        ),
        ...tasks
          .filter(({ completedAt }) => !completedAt)
          .map((task) =>
            mapTaskToTaskCard(task, {
              goals,
              t,
              today,
              showDue: true,
              hideGoal: true,
              weekStartsOn: getWeekStartsOn(user),
            }),
          ),
      ]),
    [goal, goals, subGoals, subTasks, t, tasks, today, user],
  );

  const archivedSubGoals = useMemo(
    () => subGoals.filter(({ archivedAt }) => !!archivedAt),
    [subGoals],
  );

  const completedTasks = useMemo(
    () =>
      tasks
        .filter((task) => task.completedAt)
        .toSorted(
          (taskA, taskB) =>
            (taskA.completedAt?.getTime() ?? 0) -
            (taskB.completedAt?.getTime() ?? 0),
        )
        .map((task) =>
          mapTaskToTaskCard(task, {
            goals,
            t,
            today,
            showDue: true,
            hideGoal: true,
            weekStartsOn: getWeekStartsOn(user),
          }),
        ),
    [goals, t, tasks, today, user],
  );

  return { items, completedTasks, tasks, subTasks, archivedSubGoals };
};
