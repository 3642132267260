import { Frequency, RRule } from 'shared/lib/rrule';
import { WeekDays, weekendDays, workWeek } from 'shared/types/week-days';
import {
  getRruleWeekDays,
  parseRruleWeekDays,
  parseRruleWeekdayWeekDays,
} from 'shared/utils/get-rrule-week-days';
import { isNumber } from 'shared/utils/is-number';

import { DayType, FormUnit } from './types';

export const parseFormUnitFromRrule = (rrule: string = '') => {
  const rule = RRule.fromString(rrule);

  switch (rule.origOptions.freq) {
    case RRule.YEARLY:
      return FormUnit.Years;
    case RRule.MONTHLY:
      return FormUnit.Months;
    case RRule.WEEKLY:
    case RRule.DAILY:
    default:
      return FormUnit.Weeks;
  }
};

export const parseWeekDaysFromRrule = (rrule: string = ''): WeekDays[] => {
  const rule = RRule.fromString(rrule);
  if (rule.origOptions.freq === Frequency.DAILY) {
    return Object.values(WeekDays);
  }

  return Array.isArray(rule.origOptions.byweekday)
    ? parseRruleWeekDays(rule.origOptions.byweekday)
    : [];
};

export const setWeekDaysInRrule = (rrule: string, weekDays: WeekDays[]) => {
  const rule = RRule.fromString(rrule);
  rule.origOptions.freq = Frequency.WEEKLY;
  rule.origOptions.bymonthday = undefined;
  rule.origOptions.byweekday = getRruleWeekDays(weekDays);

  if (
    weekDays.length === Object.values(WeekDays).length &&
    rule.origOptions.interval === 1
  ) {
    rule.origOptions.freq = Frequency.DAILY;
    rule.origOptions.byweekday = undefined;
  }

  return rule.toString();
};

export const parseMonthDaysFromRrule = (rrule: string = ''): number[] => {
  const rule = RRule.fromString(rrule);

  if (isNumber(rule.origOptions.bymonthday)) {
    return (rule.origOptions.bymonthday as number) > 0
      ? [rule.origOptions.bymonthday as number]
      : [];
  }

  return (rule.origOptions.bymonthday as number[]) ?? [];
};

export const setMonthDaysInRrule = (rrule: string, monthDays: number[]) => {
  const rule = RRule.fromString(rrule);

  rule.origOptions.freq = Frequency.MONTHLY;
  rule.origOptions.bymonthday = monthDays;
  rule.origOptions.bysetpos = undefined;
  rule.origOptions.byweekday = undefined;

  return rule.toString();
};

export const parseMonthWeekDayFromRrule = (rrule: string = '') => {
  const rule = RRule.fromString(rrule);

  if (rule.origOptions.bymonthday === -1) {
    return {
      weekDay: DayType.Day,
      ordinal: rule.origOptions.bysetpos as number,
    };
  }

  if (
    rule.origOptions.freq !== Frequency.MONTHLY ||
    !rule.origOptions.byweekday
  ) {
    return undefined;
  }

  const weekDays = Array.isArray(rule.origOptions.byweekday)
    ? rule.origOptions.byweekday
    : [];

  if (
    weekDays.length === workWeek.length &&
    parseRruleWeekDays(weekDays).every((weekDay) => workWeek.includes(weekDay))
  ) {
    return {
      weekDay: DayType.WorkDay,
      ordinal: rule.origOptions.bysetpos as number,
    };
  }

  if (
    weekDays.length === weekendDays.length &&
    parseRruleWeekDays(weekDays).every((weekDay) =>
      weekendDays.includes(weekDay),
    )
  ) {
    return {
      weekDay: DayType.WeekendDay,
      ordinal: rule.origOptions.bysetpos as number,
    };
  }

  const weekDay = weekDays[0] ?? rule.origOptions.byweekday;

  if (typeof weekDay !== 'object' || !('weekday' in weekDay)) {
    return undefined;
  }

  return {
    weekDay: parseRruleWeekdayWeekDays([weekDay.weekday])[0],
    ordinal: rule.origOptions.bysetpos as number,
  };
};

export const setMonthSpecificDayInRrule = (
  rrule: string,
  {
    weekDay,
    ordinal,
  }: {
    weekDay: DayType | WeekDays;
    ordinal: number;
  },
) => {
  const rule = RRule.fromString(rrule);
  rule.origOptions.freq = Frequency.MONTHLY;
  rule.origOptions.bymonthday = undefined;
  rule.origOptions.byweekday = undefined;
  rule.origOptions.bysetpos = ordinal;

  if (weekDay === DayType.Day) {
    rule.origOptions.bymonthday = [ordinal];
    return rule.toString();
  }

  rule.origOptions.byweekday = getRruleWeekDays([weekDay as WeekDays])?.[0];

  if (weekDay === DayType.WorkDay) {
    rule.origOptions.byweekday = getRruleWeekDays(workWeek);
  }

  if (weekDay === DayType.WeekendDay) {
    rule.origOptions.byweekday = getRruleWeekDays(weekendDays);
  }

  return rule.toString();
};

export const parseYearWeekDayFromRrule = (rrule: string = '') => {
  const rule = RRule.fromString(rrule);

  if (rule.origOptions.bymonthday) {
    return {
      weekDay: DayType.Day,
      ordinal: rule.origOptions.bysetpos as number,
    };
  }

  if (
    Frequency.YEARLY !== rule.origOptions.freq ||
    !rule.origOptions.byweekday
  ) {
    return undefined;
  }

  const weekDays = Array.isArray(rule.origOptions.byweekday)
    ? rule.origOptions.byweekday
    : [];

  if (
    weekDays.length === workWeek.length &&
    parseRruleWeekDays(weekDays).every((weekDay) => workWeek.includes(weekDay))
  ) {
    return {
      weekDay: DayType.WorkDay,
      ordinal: rule.origOptions.bysetpos as number,
    };
  }

  if (
    weekDays.length === weekendDays.length &&
    parseRruleWeekDays(weekDays).every((weekDay) =>
      weekendDays.includes(weekDay),
    )
  ) {
    return {
      weekDay: DayType.WeekendDay,
      ordinal: rule.origOptions.bysetpos as number,
    };
  }

  const weekDay = weekDays[0] ?? rule.origOptions.byweekday;

  if (typeof weekDay !== 'object' || !('weekday' in weekDay)) {
    return undefined;
  }

  return {
    weekDay: parseRruleWeekdayWeekDays([weekDay.weekday])[0],
    ordinal: rule.origOptions.bysetpos as number,
  };
};

export const setYearSpecificMonthDayInRrule = (
  rrule: string,
  {
    weekDay,
    ordinal,
    month,
  }: {
    weekDay: DayType | WeekDays;
    ordinal: number;
    month: number;
  },
) => {
  const rule = RRule.fromString(rrule);
  rule.origOptions.freq = Frequency.YEARLY;
  rule.origOptions.bymonthday = undefined;
  rule.origOptions.byweekday = undefined;
  rule.origOptions.bysetpos = ordinal;
  rule.origOptions.bymonth = month;

  if (weekDay === DayType.Day) {
    rule.origOptions.bymonthday = [ordinal];
    return rule.toString();
  }

  rule.origOptions.byweekday = getRruleWeekDays([weekDay as WeekDays])?.[0];

  if (weekDay === DayType.WorkDay) {
    rule.origOptions.byweekday = getRruleWeekDays(workWeek);
  }

  if (weekDay === DayType.WeekendDay) {
    rule.origOptions.byweekday = getRruleWeekDays(weekendDays);
  }

  return rule.toString();
};

export const parseMonthFromRrule = (rrule: string) => {
  const rule = RRule.fromString(rrule);
  return rule.options.bymonth[0];
};

export const setMonthInRrule = (rrule: string, month: number) => {
  const rule = RRule.fromString(rrule);
  rule.origOptions.freq = Frequency.YEARLY;
  rule.origOptions.bymonthday = undefined;
  rule.origOptions.byweekday = undefined;
  rule.origOptions.bymonth = month;

  return rule.toString();
};

export const isValidRrule = (
  rrule: string = '',
  { hasCount }: { hasCount?: boolean } = {},
) => {
  if (!rrule) {
    return false;
  }

  const rule = RRule.fromString(rrule);
  if (
    ![
      Frequency.DAILY,
      Frequency.WEEKLY,
      Frequency.MONTHLY,
      Frequency.YEARLY,
    ].includes(rule.origOptions.freq!)
  ) {
    return false;
  }

  const hasNoOptions = [
    rule.origOptions.byweekday,
    rule.origOptions.bymonthday,
    rule.origOptions.bysetpos,
  ].every((option) => option === undefined);

  if (hasCount && hasNoOptions) {
    return true;
  }

  switch (rule.origOptions.freq) {
    case Frequency.DAILY:
      return hasNoOptions;
    case Frequency.WEEKLY:
      return !!rule.origOptions.byweekday;
    case Frequency.MONTHLY:
      return (
        !!rule.origOptions.bymonthday ||
        (!!rule.origOptions.byweekday && !!rule.origOptions.bysetpos)
      );
    case Frequency.YEARLY:
      return (
        !!rule.origOptions.bymonth &&
        !!rule.origOptions.bysetpos &&
        !!(rule.origOptions.byweekday || rule.origOptions.bymonthday)
      );
    default:
      return false;
  }
};
