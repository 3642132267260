import {
  useCompleteHabitDate,
  useHabitBarChartEntries,
  useHabitProgress,
  useSkipHabitDate,
} from 'features/tasks';
import { useTimeframeTime } from 'features/user';
import React from 'react';
import { HabitInsightsCard as HabitInsightsCardComponent } from 'shared/components/connected/habit-insights-card';
import { useActiveSchedule } from 'shared/hooks/use-active-schedule';
import { useUser } from 'shared/hooks/use-user';
import { Habit } from 'shared/types/habit';
import { EntryState, FrequencyUnit } from 'shared/types/habit-schedule';
import { ID } from 'shared/types/id';
import { InsightsTimeframes } from 'shared/types/insights';
import { Timestamp } from 'shared/types/timestamp';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

export type HabitInsightsCardProps = {
  habit: Habit;
};

export const HabitInsightsMetricCard: React.FC<HabitInsightsCardProps> = ({
  habit,
}) => {
  const user = useUser();
  const activeSchedule = useActiveSchedule(habit.schedules);
  const { referenceDate, startDate, endDate, timeframe } = useTimeframeTime({
    allMonthWeeks: activeSchedule?.frequency?.unit === FrequencyUnit.Week,
    quarterRoundedToWeeks:
      activeSchedule?.frequency?.unit === FrequencyUnit.Week,
    yearRoundedToWeeks: activeSchedule?.frequency?.unit === FrequencyUnit.Week,
  });
  const completeDate = useCompleteHabitDate();
  const skipDate = useSkipHabitDate();
  const weekStartsOn = getWeekStartsOn(user);
  const { timeframeOccurrences, timeframeCompletions, skips } =
    useHabitProgress(habit, {
      startDate,
      endDate,
    });

  const { entries, perfectSlots, entriesTarget, target, completionPercentage } =
    useHabitBarChartEntries(habit, {
      timeframe,
      startDate,
      endDate,
      weekStartsOn,
    });

  const _onDate = (_: ID, date: Timestamp, type: EntryState) => {
    if (type === EntryState.Skip) {
      skipDate(habit, date);
      return;
    }

    completeDate(habit, date, true);
  };

  return (
    <HabitInsightsCardComponent
      habit={habit}
      calendarOpensToDate={referenceDate}
      entries={entries}
      entriesTarget={entriesTarget}
      calendarOccurrences={timeframeOccurrences}
      completions={timeframeCompletions}
      skips={skips}
      perfectSlots={perfectSlots}
      currentValue={timeframeCompletions.length}
      completionPercentage={completionPercentage}
      weekStartsOn={getWeekStartsOn(user)}
      target={target}
      onDate={_onDate}
      displayMonth={timeframe === InsightsTimeframes.Month}
    />
  );
};
