import { ByWeekday, RRule } from 'shared/lib/rrule';
import { WeekDays } from 'shared/types/week-days';

const weekDayToByWeekdayMap: Record<WeekDays, ByWeekday> = {
  [WeekDays.Monday]: RRule.MO.weekday,
  [WeekDays.Tuesday]: RRule.TU.weekday,
  [WeekDays.Wednesday]: RRule.WE.weekday,
  [WeekDays.Thursday]: RRule.TH.weekday,
  [WeekDays.Friday]: RRule.FR.weekday,
  [WeekDays.Saturday]: RRule.SA.weekday,
  [WeekDays.Sunday]: RRule.SU.weekday,
};

export const getRruleWeekDays = (
  weekDays: WeekDays[],
): ByWeekday[] | undefined =>
  weekDays.length
    ? weekDays.map((weekDay) => weekDayToByWeekdayMap[weekDay])
    : undefined;

const byWeekDayToWeekDayMap: Record<string, WeekDays> = {
  [RRule.MO.toString()]: WeekDays.Monday,
  [RRule.TU.toString()]: WeekDays.Tuesday,
  [RRule.WE.toString()]: WeekDays.Wednesday,
  [RRule.TH.toString()]: WeekDays.Thursday,
  [RRule.FR.toString()]: WeekDays.Friday,
  [RRule.SA.toString()]: WeekDays.Saturday,
  [RRule.SU.toString()]: WeekDays.Sunday,
};

export const parseRruleWeekDays = (byWeekdays: ByWeekday[]): WeekDays[] =>
  byWeekdays.map((byWeekday) => byWeekDayToWeekDayMap[byWeekday.toString()]);

const byWeekDayWeekdayToWeekDayMap: Record<number, WeekDays> = {
  [RRule.MO.weekday]: WeekDays.Monday,
  [RRule.TU.weekday]: WeekDays.Tuesday,
  [RRule.WE.weekday]: WeekDays.Wednesday,
  [RRule.TH.weekday]: WeekDays.Thursday,
  [RRule.FR.weekday]: WeekDays.Friday,
  [RRule.SA.weekday]: WeekDays.Saturday,
  [RRule.SU.weekday]: WeekDays.Sunday,
};

export const parseRruleWeekdayWeekDays = (byWeekdays: number[]): WeekDays[] =>
  byWeekdays.map((byWeekday) => byWeekDayWeekdayToWeekDayMap[byWeekday]);
