import { useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import {
  useArchiveTask,
  useCompleteHabitDate,
  useCompleteTask,
  useCreateHabitAllowed,
  useHabits,
  useUpdateTask,
} from 'features/tasks';
import { useUpdateHabitOrder, useUserHadTrial } from 'features/user';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGridCreateRow } from 'shared/components/ui/data-grid-create-row';
import { PremiumBanner } from 'shared/components/ui/premium-banner';
import { useOpenDeleteHabitDialog } from 'shared/contexts/delete-habit';
import {
  useOpenCreateHabitDialog,
  useOpenEditHabitDialog,
} from 'shared/contexts/habit-form';
import { useOpenPremiumDialog } from 'shared/contexts/premium-dialog';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';
import { TaskType } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';
import { sortItems } from 'shared/utils/sort-items';
import { useTheme } from 'styled-components';

import {
  dateCheckboxWidth,
  initialVisibleDates,
  weekLength,
} from '../../constants';
import { useLastWeeks } from '../../hooks/use-last-weeks';
import { HabitsHeader } from '../habits-header';
import { SortableHabitsList } from '../sortable-habits-list';
import * as Styled from './active-habits-list.style';

export const ActiveHabitsList: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const user = useUser();
  const habits = useHabits({ uncompletedOnly: true, unArchivedOnly: true });
  const goals = useGoals();
  const lifeAreas = useLocalizedLifeAreas();
  const weeks = useLastWeeks(getWeekStartsOn(user));
  const userHadTrial = useUserHadTrial();

  const { submit: updateTask } = useUpdateTask();
  const updateOrder = useUpdateHabitOrder();
  const completeHabit = useCompleteTask();
  const completeHabitDate = useCompleteHabitDate();
  const deleteHabit = useOpenDeleteHabitDialog();
  const archiveHabit = useArchiveTask();
  const editHabit = useOpenEditHabitDialog();
  const createHabit = useOpenCreateHabitDialog();

  const {
    allowed: createHabitsAllowed,
    enforced: createHabitLimitsEnforced,
    limit: createHabitLimits,
    total: totalHabits,
  } = useCreateHabitAllowed();
  const openPremiumDialog = useOpenPremiumDialog();

  const [activeDate, setActiveDate] = useState(weeks.length * weekLength - 1);
  const [visibleDates, setVisibleDates] = useState(initialVisibleDates);

  const onPrevious = () =>
    setActiveDate((oldActiveDate) => Math.max(0, oldActiveDate - 1));

  const onNext = () =>
    setActiveDate((oldActiveDate) =>
      Math.min(weeks.length * weekLength - 1, oldActiveDate + 1),
    );

  const onContainerWidthChange = (width: number) => {
    const amountOfFittingDates = Math.floor((width - 1) / dateCheckboxWidth);
    if (amountOfFittingDates !== visibleDates) {
      setVisibleDates(amountOfFittingDates);
    }
  };

  const onComplete = (id: ID) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit) {
      completeHabit(habit);
    }
  };

  const onCompleteDate = (id: ID, date: Timestamp) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit) {
      completeHabitDate(habit, date);
    }
  };

  const onDelete = (id: ID) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit) {
      deleteHabit(habit);
    }
  };

  const onArchive = (id: ID) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit) {
      archiveHabit(habit);
    }
  };

  const onEdit = (id: ID) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit && !habit.frozenAt) {
      editHabit(habit, id);
    }
  };

  const onEditType = (id: ID, type: TaskType.Habit | TaskType.Repeating) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit && !habit.frozenAt) {
      updateTask({ id: habit.id, type });
    }
  };

  const onEditHideOnTodo = (id: ID) => {
    const habit = habits.find((habit) => habit.id === id);
    if (habit && !habit.frozenAt) {
      updateTask({
        id: habit.id,
        type: habit.type,
        hideOnTodo: !habit.hideOnTodo,
      });
    }
  };

  const activeHabits = useMemo(
    () =>
      sortItems(
        habits.filter(({ frozenAt }) => !frozenAt),
        user?.settings?.habitSorting ?? [],
        'id',
      ),
    [habits, user?.settings?.habitSorting],
  );

  const frozenHabits = useMemo(
    () =>
      sortItems(
        habits.filter(({ frozenAt }) => !!frozenAt),
        user?.settings?.habitSorting ?? [],
        'id',
      ),
    [habits, user?.settings?.habitSorting],
  );

  const showPremiumBanner =
    !createHabitsAllowed ||
    (!createHabitLimitsEnforced && createHabitLimits < totalHabits);

  const onCreate = () =>
    !createHabitsAllowed ? openPremiumDialog() : createHabit();

  return (
    <Styled.Container>
      <HabitsHeader
        weeks={weeks}
        onPrevious={onPrevious}
        onNext={onNext}
        scrollToDate={activeDate}
        dateWidth={dateCheckboxWidth}
        visibleDates={visibleDates}
        onContainerWidthChange={onContainerWidthChange}
      />
      <SortableHabitsList
        onReorder={updateOrder}
        habits={activeHabits}
        goals={goals}
        lifeAreas={lifeAreas}
        weeks={weeks}
        weekStartsOn={getWeekStartsOn(user)}
        scheduleScrollToDate={activeDate}
        scheduleDateWidth={dateCheckboxWidth}
        scheduleVisibleDates={visibleDates}
        onCompleteHabit={onComplete}
        onCompleteDate={onCompleteDate}
        onArchiveHabit={onArchive}
        onDeleteHabit={onDelete}
        onEditHabit={onEdit}
        onChangeHabitType={onEditType}
        onChangeHideOnTodo={onEditHideOnTodo}
      />
      {showPremiumBanner && (
        <Styled.PremiumBannerContainer>
          <PremiumBanner
            title={t(
              createHabitLimitsEnforced
                ? 'pages.habits.premium-banner.title'
                : 'pages.habits.premium-banner.not-enforced.title',
              { total: totalHabits, limit: createHabitLimits },
            )}
            description={t(
              createHabitLimitsEnforced
                ? userHadTrial
                  ? 'pages.habits.premium-banner.description'
                  : 'pages.habits.premium-banner.try-for-free.description'
                : 'pages.habits.premium-banner.not-enforced.description',
              { limit: createHabitLimits },
            )}
            onClick={openPremiumDialog}
            isTrial={!userHadTrial}
          />
        </Styled.PremiumBannerContainer>
      )}
      {!!frozenHabits.length && (
        <SortableHabitsList
          habits={frozenHabits}
          goals={goals}
          lifeAreas={lifeAreas}
          weeks={[]}
          weekStartsOn={getWeekStartsOn(user)}
        />
      )}
      {!theme.isMobile && (
        <DataGridCreateRow
          onClick={onCreate}
          label={t('pages.habits.footer.button.add.label')}
          showPremiumLabel={!createHabitsAllowed}
        />
      )}
    </Styled.Container>
  );
};
