import Typography from '@mui/material/Typography';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Frequency, RRule } from 'rrule';
import { FormSelect, Option } from 'shared/components/ui/form-select';
import { ResizableInput } from 'shared/components/ui/resizable-input';
import { TaskTypeToggle } from 'shared/components/ui/task-type-toggle';
import {
  FrequencyUnit,
  HabitSchedule,
  RruleReferenceDate,
  ScheduleFrequency,
} from 'shared/types/habit-schedule';
import { TaskType } from 'shared/types/task-base';

import { Footer } from './footer';
import * as Styled from './form.style';
import { defaultFrequency } from './types';

const frequencyUnits = Object.values(FrequencyUnit);

export type TargetFormProps = {
  type: TaskType;
  initialFrequency?: ScheduleFrequency | null;
  onChange: (schedule: HabitSchedule | null) => void;
  onChangeType: (type: TaskType.Repeating | TaskType.Habit) => void;
  createHabitPremium: boolean;
  showTypeToggle?: boolean;
};

const timesPerWeekOptions: Option[] = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
];

const timesPerMonthOptions: Option[] = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
  { value: 13, label: 13 },
  { value: 14, label: 14 },
  { value: 15, label: 15 },
  { value: 16, label: 16 },
  { value: 17, label: 17 },
  { value: 18, label: 18 },
  { value: 19, label: 19 },
  { value: 20, label: 20 },
  { value: 21, label: 21 },
  { value: 22, label: 22 },
  { value: 23, label: 23 },
  { value: 24, label: 24 },
  { value: 25, label: 25 },
  { value: 26, label: 26 },
  { value: 27, label: 27 },
  { value: 28, label: 28 },
];

export const TargetForm: React.FC<TargetFormProps> = ({
  type,
  initialFrequency,
  onChange,
  onChangeType,
  createHabitPremium,
  showTypeToggle,
}) => {
  const { t } = useTranslation();
  const [frequency, setFrequency] = useState<ScheduleFrequency>(
    initialFrequency ?? defaultFrequency,
  );

  const typeValue =
    type && type !== TaskType.Task
      ? type
      : createHabitPremium
        ? TaskType.Repeating
        : TaskType.Habit;

  const onSubmit = () => {
    if (type !== typeValue) {
      onChangeType(typeValue);
    }

    onChange({
      rrule: {
        format: new RRule({
          freq:
            frequency.unit === FrequencyUnit.Month
              ? Frequency.MONTHLY
              : Frequency.DAILY,
        }).toString(),
        referenceDate: RruleReferenceDate.StartDate,
      },
      frequency,
      completions: [],
      skips: [],
      startDate: new Date(),
      endDate: null,
    });
  };

  const onChangeCount = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCount = parseInt(e.target.value);
    setFrequency((odlFrequency) => ({ ...odlFrequency, count: newCount }));
  };

  const onChangeLabel = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    setFrequency((odlFrequency) => ({
      ...odlFrequency,
      label: target.value || null,
    }));

  const onChangeUnit = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    const unit = target.value as FrequencyUnit;
    if (!frequencyUnits.includes(unit)) {
      return;
    }

    setFrequency((odlFrequency) => ({ ...odlFrequency, unit, count: 1 }));
  };

  const onClear = () => onChange(null);

  const isValidValue = useMemo(() => frequency.count > 0, [frequency.count]);

  const frequencyOptions = useMemo(
    () => [
      {
        label: t('general.labels.habit.repeat.target.options.week.label'),
        value: FrequencyUnit.Week,
      },
      {
        label: t('general.labels.habit.repeat.target.options.month.label'),
        value: FrequencyUnit.Month,
      },
    ],
    [t],
  );

  useEffect(() => {
    if (initialFrequency) {
      setFrequency(initialFrequency);
    }
  }, [initialFrequency]);

  return (
    <Styled.Container>
      <Styled.HeaderContainer>
        <Typography variant="body2">
          {t('general.labels.habit.repeat.target.label')}
        </Typography>
      </Styled.HeaderContainer>

      <Styled.TargetBody>
        <FormSelect
          options={
            frequency.unit === FrequencyUnit.Month
              ? timesPerMonthOptions
              : timesPerWeekOptions
          }
          value={frequency.count ?? ''}
          onChange={onChangeCount}
          isSmall
        />
        {/*<IntervalInput value={frequency.count} onChange={onChangeCount} />*/}
        <ResizableInput
          value={frequency.label ?? ''}
          placeholder={t(
            'general.labels.habit.repeat.value.target.default.label',
            {
              count: frequency.count,
            },
          )}
          onChange={onChangeLabel}
          showAsSmallFormInput
        />
        <FormSelect
          options={frequencyOptions}
          value={frequency.unit}
          onChange={onChangeUnit}
          isSmall
        />
      </Styled.TargetBody>

      {!!showTypeToggle && (
        <Styled.Body>
          <TaskTypeToggle
            type={typeValue}
            onChange={onChangeType}
            isPremium={createHabitPremium}
          />
        </Styled.Body>
      )}

      <Footer onClear={onClear} onSubmit={onSubmit} isValid={isValidValue} />
    </Styled.Container>
  );
};
