import { useCallback } from 'react';
import { useUpdateInsightsSectionMutation } from 'shared/hooks/queries/use-update-insights-section-mutation';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';
import { InsightSections, SectionInsights } from 'shared/types/insights';

export const useToggleHiddenInsightsItem = (section: InsightSections) => {
  const { mutate } = useUpdateInsightsSectionMutation();
  const user = useUser();

  return useCallback(
    (id: ID) => {
      const newInsightsSection: SectionInsights = {
        hiddenItems:
          user?.settings?.insights?.sections?.[section]?.hiddenItems ?? [],
        extraItems:
          user?.settings?.insights?.sections?.[section]?.extraItems ?? [],
        order: user?.settings?.insights?.sections?.[section]?.order ?? [],
      };

      if (
        newInsightsSection.hiddenItems.includes(id) ||
        newInsightsSection.extraItems.includes(id)
      ) {
        newInsightsSection.hiddenItems = newInsightsSection.hiddenItems.filter(
          (hiddenItem) => hiddenItem !== id,
        );
        newInsightsSection.extraItems = newInsightsSection.extraItems.filter(
          (extraItem) => extraItem !== id,
        );
        mutate({ id: section, section: newInsightsSection });
        return;
      }

      newInsightsSection.hiddenItems = [...newInsightsSection.hiddenItems, id];
      mutate({ id: section, section: newInsightsSection });
    },
    [mutate, section, user?.settings?.insights?.sections],
  );
};
