import { Frequency, RRule } from 'shared/lib/rrule';
import { FrequencyUnit, ScheduleFrequency } from 'shared/types/habit-schedule';
import { fullWeek } from 'shared/types/week-days';
import { getRruleWeekDays } from 'shared/utils/get-rrule-week-days';

export enum FormOptions {
  RRule = 'rrule',
  Target = 'target',
}

export enum FormUnit {
  Weeks = 'weeks',
  Months = 'months',
  Years = 'years',
}

export enum DayType {
  Day = 'day',
  WorkDay = 'work-day',
  WeekendDay = 'weekend',
}

export const formUnits = Object.values(FormUnit);

export const formUnitToRruleFreqMap: Record<FormUnit, Frequency> = {
  [FormUnit.Weeks]: Frequency.WEEKLY,
  [FormUnit.Months]: Frequency.MONTHLY,
  [FormUnit.Years]: Frequency.YEARLY,
};

export const defaultRruleOptions = {
  freq: Frequency.WEEKLY,
  byweekday: getRruleWeekDays(fullWeek),
};

export const defaultRrule = new RRule(defaultRruleOptions).toString();

export const defaultFrequency: ScheduleFrequency = {
  count: 1,
  unit: FrequencyUnit.Week,
  label: null,
};
