import { typographyToCss } from 'shared/lib/mui';
import styled, { css } from 'styled-components';

export const Container = styled.span<{
  $minWidth: string;
  $maxWidth: string;
  $fullWidth: boolean;
}>`
  position: relative;
  display: inline-block;
  min-width: ${({ $minWidth }) => $minWidth};
  max-width: ${({ $maxWidth }) => $maxWidth};

  line-height: ${({ theme }) => theme.typography.buttonSmall.lineHeight};

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      min-width: initial;
      max-width: initial;
      width: 100%;
    `};
`;

export const IconContainer = styled.span<{ $hasValue: boolean }>`
  position: absolute;
  top: 50%;
  left: ${({ theme }) => theme.spacing(2)};

  transform: translateY(-50%);
  transition: color 200ms;

  font-size: 1.2rem;
  line-height: 62.5%;
  color: ${({ $hasValue, theme }) =>
    $hasValue ? 'inherit' : theme.palette.text.secondary};
`;

const ContentBase = css<{
  $hasIconLeft: boolean;
  $hasIconRight: boolean;
  $showAsFormInput: boolean;
  $showAsSmallFormInput: boolean;
  $hasNumberButtons: boolean;
  $inheritFont: boolean;
}>`
  display: block;
  box-sizing: border-box;
  ${({ theme }) => typographyToCss(theme.typography.buttonSmall)};
  padding: 0;

  border: none;
  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme, $showAsSmallFormInput, $showAsFormInput }) =>
    ($showAsSmallFormInput || $showAsFormInput) &&
    css`
      ${typographyToCss(
        $showAsSmallFormInput
          ? theme.typography.buttonSmall
          : theme.typography.body2,
      )};
      border-radius: ${({ theme }) => theme.spacing(2)};
      border: 1px solid ${({ theme }) => theme.palette.divider};

      padding: ${$showAsSmallFormInput
        ? `${theme.spacing(0.5)} ${theme.spacing(2)}`
        : theme.isMobile
          ? `${theme.spacing(2.5)} ${theme.spacing(3)}`
          : `${theme.spacing(1.5)} ${theme.spacing(2)}`};
    `};

  ${({ theme, $hasIconRight, $hasNumberButtons }) =>
    ($hasIconRight || $hasNumberButtons) &&
    css`
      padding-right: ${$hasIconRight && $hasNumberButtons
        ? theme.spacing(9)
        : theme.spacing(6)};
    `};

  ${({ $inheritFont }) =>
    $inheritFont &&
    css`
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
    `};
`;

export const Input = styled.input<{
  $hasIconLeft: boolean;
  $hasIconRight: boolean;
  $showAsFormInput: boolean;
  $showAsSmallFormInput: boolean;
  $hasNumberButtons: boolean;
  $inheritFont: boolean;
}>`
  ${ContentBase};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;

  background: transparent;
  transition:
    border-color 200ms,
    color 200ms;

  color: inherit;

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &::-moz-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &:-ms-input-placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  &[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }
`;

export const SizingSpan = styled.span<{
  $hasIconLeft: boolean;
  $hasIconRight: boolean;
  $showAsFormInput: boolean;
  $showAsSmallFormInput: boolean;
  $hasNumberButtons: boolean;
  $inheritFont: boolean;
}>`
  ${ContentBase};
  visibility: hidden;
  white-space: pre;
`;

export const NumberButtonsContainer = styled.span<{
  $clearButtonSpacing: boolean;
}>`
  position: absolute;
  top: 50%;
  right: ${({ theme, $clearButtonSpacing }) =>
    `calc(${theme.spacing(1)} + ${$clearButtonSpacing ? '1.6rem' : '0px'})`};

  display: flex;
  flex-direction: column;

  padding: ${({ theme }) => theme.spacing(0.5)};

  transform: translateY(-50%);
`;

const ButtonBase = css`
  margin: 0;
  padding: 0;

  background: transparent;
  border: none;
  cursor: pointer;

  font-size: 1.2rem;
  line-height: 62.5%;
`;

export const NumberButton = styled.button<{ $isSecond?: boolean }>`
  ${ButtonBase};

  display: flex;
  align-items: center;
  justify-content: center;

  transform: rotate(${({ $isSecond }) => ($isSecond ? 270 : 90)}deg);

  color: inherit;
`;

export const ClearButton = styled.button`
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.spacing(2)};
  margin: 0;
  padding: 0;

  transform: translateY(-50%);
  border: none;
  background: transparent;
  cursor: pointer;

  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 1.2rem;
  line-height: 62.5%;
`;
