import {
  addDays,
  addMonths,
  addWeeks,
  differenceInDays,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { useMemo } from 'react';
import { weekdaysNumberMap } from 'shared/constants';
import { useToday } from 'shared/contexts/today';
import { DateEntry } from 'shared/lib/recharts';
import { WeekDays } from 'shared/types/week-days';

import { TickRange } from './types';
import { useTickRange } from './use-tick-range';

const twoDays = 2 * 24 * 60 * 60 * 1000;

export type Options = {
  startDate?: Date;
  endDate?: Date;
  completed?: boolean;
  strictDates?: boolean;
  weekStartsOn?: WeekDays;
};

export const useXAxisData = (
  data: DateEntry[],
  {
    startDate,
    endDate,
    completed,
    strictDates,
    weekStartsOn = WeekDays.Monday,
  }: Options,
) => {
  const today = useToday();

  const domain = useMemo<[number, number]>(() => {
    const entryDates = data.map(({ date }) => date.getTime());
    const minDate = Math.min(
      ...entryDates,
      ...(startDate ? [startDate?.getTime()] : []),
      ...(!completed ? [today.getTime()] : []),
    );
    const maxDate = Math.max(
      ...entryDates,
      ...(endDate ? [endDate?.getTime()] : []),
      ...(!completed ? [today.getTime()] : []),
    );

    if (!strictDates && (differenceInDays(maxDate, minDate) > 2 || completed)) {
      return [minDate, maxDate];
    }

    return [
      (startDate ?? addDays(today, -1))?.getTime(),
      (endDate ?? addDays(today, 1)).getTime(),
    ];
  }, [completed, data, endDate, startDate, strictDates, today]);

  const tickRange = useTickRange(domain);

  const ticks = useMemo(() => {
    const range = domain[1] - domain[0];
    let rangeDates = [];
    let currentDate = startOfDay(domain[0]);
    rangeDates.push(currentDate.getTime());

    if (tickRange === TickRange.Month || range < twoDays) {
      currentDate = new Date(domain[1]);
      rangeDates.push(domain[1]);
    }

    while (currentDate.getTime() < domain[1]) {
      currentDate =
        tickRange === TickRange.Day
          ? addDays(currentDate, 1)
          : tickRange === TickRange.Week
            ? addWeeks(
                startOfWeek(currentDate, {
                  weekStartsOn: weekdaysNumberMap[weekStartsOn],
                }),
                1,
              )
            : addMonths(startOfMonth(currentDate), 1);

      if (currentDate.getTime() < domain[1]) {
        rangeDates.push(currentDate.getTime());
      }
    }

    // post-processing for weeks, as those have to placed at the end of the week
    if (tickRange === TickRange.Week) {
      rangeDates = rangeDates.map((date) =>
        endOfWeek(date, {
          weekStartsOn: weekdaysNumberMap[weekStartsOn],
        }).getTime(),
      );
    }

    return rangeDates.sort((entryA, entryB) => entryA - entryB);
  }, [domain, tickRange, weekStartsOn]);

  return {
    domain,
    ticks,
  };
};
