import { typographyToCss } from 'shared/lib/mui';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.background.default};
  box-shadow: ${({ theme }) => theme.shadowBox.menu};

  color: ${({ theme }) => theme.palette.text.primary};
`;

export const OptionsList = styled.ul`
  padding: ${({ theme }) => theme.spacing(1)};
  margin: 0;
  list-style: none;
`;

export const OptionsItem = styled.li``;

export const OptionDescription = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const OptionPremiumLabel = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};
  display: block;

  margin-top: ${({ theme }) => theme.spacing(0.5)};
  padding: ${({ theme }) => `${theme.spacing(0.5)} ${theme.spacing(2)}`};

  background: ${({ theme }) => theme.palette.info.light};
  border-radius: ${({ theme }) => theme.spacing(6)};

  color: ${({ theme }) => theme.palette.info.main};
`;

export const OptionContainer = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  margin: 0;
  padding: ${({ theme }) =>
    `${theme.spacing(1.5)} ${theme.spacing(0.5)} ${theme.spacing(1.5)} ${theme.spacing(3)}`};
  width: 100%;

  border: none;
  background: transparent;
  cursor: pointer;

  text-align: left;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const OptionContent = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(0.5)};
`;
