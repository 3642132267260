import { isAfter, isSameDay } from 'date-fns';
import { useCompleteTask, useUpdateTask } from 'features/tasks';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircle, ForwardChevrons } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { useToday } from 'shared/contexts/today';
import { Task } from 'shared/types/task';
import { TaskType } from 'shared/types/task-base';
import { getActiveSchedule } from 'shared/utils/get-active-schedule';

export type CompleteListProps = {
  task: Task;
  onClose: () => void;
};

export const CompleteList: React.FC<CompleteListProps> = ({
  task,
  onClose,
}) => {
  const { t } = useTranslation();
  const today = useToday();
  const completeTask = useCompleteTask();
  const { submit: updateTask } = useUpdateTask();

  const onComplete = () => {
    completeTask(task);
    onClose();
  };

  const onSkip = () => {
    const schedulesCopy = structuredClone(task.schedules);
    const activeSchedule = getActiveSchedule(schedulesCopy);

    if (!activeSchedule) {
      onClose();
      return;
    }

    activeSchedule.skips = [...(activeSchedule.skips ?? []), new Date()];

    updateTask({
      id: task.id,
      schedules: [
        ...(schedulesCopy?.filter(({ endDate }) => !!endDate) ?? []),
        activeSchedule,
      ],
    });
    onClose();
  };

  const isHabit = [TaskType.Habit, TaskType.Repeating].includes(task.type);
  const skippedTodayOrAfter = useMemo(() => {
    const activeSchedule = getActiveSchedule(task.schedules);
    return !!activeSchedule?.skips?.find(
      (skipDate) => isSameDay(skipDate, today) || isAfter(skipDate, today),
    );
  }, [task.schedules, today]);

  return (
    <PopupMenuList hasBottomBorder>
      <PopupMenuListItem>
        <PopupMenuButton
          onClick={onComplete}
          start={<Icon icon={CheckCircle} />}
        >
          {t(
            isHabit
              ? task.completedAt
                ? 'context-menus.task.habit.labels.un-complete'
                : 'context-menus.task.habit.labels.complete'
              : task.completedAt
                ? 'context-menus.task.labels.un-complete'
                : 'context-menus.task.labels.complete',
          )}
        </PopupMenuButton>
      </PopupMenuListItem>
      {isHabit && !skippedTodayOrAfter && (
        <PopupMenuListItem>
          <PopupMenuButton
            onClick={onSkip}
            start={<Icon icon={ForwardChevrons} />}
          >
            {t('context-menus.task.labels.skip')}
          </PopupMenuButton>
        </PopupMenuListItem>
      )}
    </PopupMenuList>
  );
};
