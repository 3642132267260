import { useLocalizedLifeAreas } from 'features/life-areas';
import { useMemo } from 'react';
import { useUser } from 'shared/hooks/use-user';
import { InsightSections } from 'shared/types/insights';
import { sortItems } from 'shared/utils/sort-items';

export const useInsightsLifeAreas = () => {
  const user = useUser();
  const lifeAreas = useLocalizedLifeAreas();

  return useMemo(() => {
    const filteredLifeAreas = lifeAreas.filter(
      ({ id, archivedAt }) =>
        !archivedAt &&
        (user?.settings?.insights?.showHiddenItems ||
          !user?.settings?.insights?.sections?.[
            InsightSections.Lifescan
          ]?.hiddenItems?.includes(id)),
    );

    const sortedLifeAreas = sortItems(
      filteredLifeAreas,
      user?.settings?.lifeAreaSorting ?? [],
      'id',
    );

    return user?.settings?.insights?.sections?.[InsightSections.Lifescan]?.order
      ?.length
      ? sortItems(
          sortedLifeAreas,
          user.settings.insights.sections[InsightSections.Lifescan].order,
          'id',
        )
      : sortedLifeAreas;
  }, [
    lifeAreas,
    user?.settings?.insights?.sections,
    user?.settings?.insights?.showHiddenItems,
    user?.settings?.lifeAreaSorting,
  ]);
};
