import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircle2, ForwardChevrons } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import {
  PopupMenu,
  PopupMenuButton,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { EntryState } from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';

export type DayContextMenuProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children' | 'ref'
> & {
  date: Timestamp;
  onDate: (date: Timestamp, type: EntryState) => void;
  onClose: () => void;
};

export const DayContextMenu: React.FC<DayContextMenuProps> = ({
  date,
  onDate,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation();
  const onComplete = () => {
    onDate(date, EntryState.Complete);
    onClose();
  };

  const onSkip = () => {
    onDate(date, EntryState.Skip);
    onClose();
  };

  return (
    <PopupMenu position="bottom-start" {...rest}>
      <PopupMenuList>
        <PopupMenuListItem>
          <PopupMenuButton
            onClick={onComplete}
            start={<Icon icon={CheckCircle2} />}
          >
            {t('context-menus.calendar.day.complete.label')}
          </PopupMenuButton>
        </PopupMenuListItem>
        <PopupMenuListItem>
          <PopupMenuButton
            onClick={onSkip}
            start={<Icon icon={ForwardChevrons} />}
          >
            {t('context-menus.calendar.day.skip.label')}
          </PopupMenuButton>
        </PopupMenuListItem>
      </PopupMenuList>
    </PopupMenu>
  );
};
