import { endOfQuarter } from 'date-fns';
import { GoalDateSection } from 'shared/types/orderable-section';
import { Timestamp } from 'shared/types/timestamp';
import { formatQuarterlyDateKey } from 'shared/utils/format-date-key';

export const createRoadmapSection = (
  startDate: Timestamp,
  endDate?: Timestamp,
): GoalDateSection => {
  const quarterEndDate = endDate ?? endOfQuarter(startDate);

  return {
    id: formatQuarterlyDateKey(startDate),
    startDate,
    endDate: quarterEndDate,
    items: [],
  };
};
