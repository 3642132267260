import { typographyToCss } from 'shared/lib/mui';
import styled, { DefaultTheme } from 'styled-components';

import { Colors } from './types';

const getColor = (color: Colors, theme: DefaultTheme): string | undefined => {
  const colorsMap: Record<Colors, string> = {
    [Colors.Primary]: theme.palette.primary.main,
    [Colors.Info]: theme.palette.info.main,
    [Colors.Warning]: theme.palette.warning.main,
    [Colors.Error]: theme.palette.error.main,
    [Colors.Success]: theme.palette.success.dark,
  };

  return colorsMap[color];
};

export const Container = styled.article`
  padding: ${({ theme }) => `${theme.spacing(2.5)} ${theme.spacing(4)}`};

  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  border-radius: ${({ theme }) => theme.spacing(3)};
  box-shadow: ${({ theme }) => theme.shadowBox.card};
  background: ${({ theme }) => theme.palette.backgroundExtended.card};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;

  color: ${({ theme }) => theme.palette.text.primary};
`;

export const Data = styled.div``;

export const Value = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.h3)};
`;

export const Target = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.subtitle2)};
  padding-left: ${({ theme }) => theme.spacing(1)};
`;

export const IconContainer = styled.span<{ $color: Colors }>`
  font-size: 2rem;
  line-height: 62.5%;
  color: ${({ $color, theme }) => getColor($color, theme) ?? 'inherit'};
`;

export const Body = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.text.secondary};
  min-width: 0;
`;

export const Description = styled.span`
  ${({ theme }) => typographyToCss(theme.typography.overline)};

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
