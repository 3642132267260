import { useGoals } from 'features/goals';
import { useTasksByLifeAreaId } from 'features/tasks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { ID } from 'shared/types/id';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';

export const useCompletedLifeAreaTasks = (id?: ID) => {
  const { t } = useTranslation();
  const today = useToday();
  const user = useUser();

  const tasks = useTasksByLifeAreaId(id, { completedOnly: true });
  const goals = useGoals();

  const taskCardTasks = useMemo(
    () =>
      tasks.map((task) =>
        mapTaskToTaskCard(task, {
          today,
          goals,
          t,
          showDue: true,
          hideTodayDue: true,
          weekStartsOn: getWeekStartsOn(user),
        }),
      ),
    [goals, t, tasks, today, user],
  );

  return {
    taskCardTasks,
  };
};
