import { useUserInsights } from 'features/user';
import { useTimeByInsightsTimeframe } from 'shared/hooks/use-time-by-insights-timeframe';
import { useUser } from 'shared/hooks/use-user';
import { fallbackTimeframe } from 'shared/types/insights';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

export type Options = {
  allMonthWeeks?: boolean;
  quarterRoundedToWeeks?: boolean;
  yearRoundedToWeeks?: boolean;
  weekEndsWithStartOfDay?: boolean;
};

export const useTimeframeTime = ({
  allMonthWeeks,
  quarterRoundedToWeeks,
  yearRoundedToWeeks,
  weekEndsWithStartOfDay,
}: Options = {}) => {
  const user = useUser();
  const insights = useUserInsights();
  const timeframe = insights?.timeframe ?? fallbackTimeframe;
  const weekStartsOn = getWeekStartsOn(user);

  const { referenceDate, startDate, endDate } = useTimeByInsightsTimeframe(
    timeframe,
    {
      weekStartsOn,
      allMonthWeeks,
      quarterRoundedToWeeks,
      yearRoundedToWeeks,
      weekEndsWithStartOfDay,
    },
  );

  return { referenceDate, startDate, endDate, timeframe };
};
