import { format } from 'date-fns';
import { Timestamp } from 'shared/types/timestamp';
import { GoalRoadmapSortingKeys } from 'shared/types/user-settings';

export const formatDateKey = (date: Timestamp) => format(date, 'yyyy-MM-dd');

export const formatQuarterlyDateKey = (date: Timestamp) =>
  // unplanned
  date.getTime() === new Date(0).getTime()
    ? GoalRoadmapSortingKeys.Unplanned
    : // in progress
      date.getTime() === new Date(1).getTime()
      ? GoalRoadmapSortingKeys.InProgress
      : format(date, 'QQQ-yyyy');
