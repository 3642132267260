import { addDays, endOfDay, parse, startOfWeek } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Calendar,
  CalendarDeleteDate,
  CalendarTomorrow,
  Flag,
} from 'shared/assets/icons';
import { Datepicker } from 'shared/components/ui/datepicker';
import { Icon } from 'shared/components/ui/icon';
import {
  Colors,
  PopupMenu,
  PopupMenuButton,
  PopupMenuInput,
  PopupMenuList,
  PopupMenuListItem,
} from 'shared/components/ui/popup-menu';
import { weekdaysNumberMap } from 'shared/constants';
import { useToday } from 'shared/contexts/today';
import { useKeysDown } from 'shared/hooks/use-keys-down';
import {
  DateFormatOptions,
  dateFormatOptionToLabelMap,
} from 'shared/types/date-format-options';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { useDateInput } from 'shared/utils/use-date-input';

export enum IconOptions {
  Calendar = 'calendar',
  Flag = 'flag',
}

export type DatepickerWithOptionsProps = Omit<
  React.ComponentProps<typeof PopupMenu>,
  'children'
> & {
  value: Timestamp | undefined;
  onChange: (date?: Timestamp) => void;
  dateFormat: DateFormatOptions;
  weekStartsOn: WeekDays;
  icon?: IconOptions;
  canRemoveValue?: boolean;
};

export const DatepickerWithOptions: React.FC<DatepickerWithOptionsProps> = ({
  value,
  onChange,
  dateFormat,
  weekStartsOn,
  icon = IconOptions.Calendar,
  canRemoveValue = true,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const today = useToday();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useDateInput(value, dateFormat);

  const [inputError, setInputError] = useState(false);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e);
    setInputError(false);
  };

  const changeToToday = () => onChange(endOfDay(new Date()));

  const changeToTomorrow = () => onChange(endOfDay(addDays(new Date(), 1)));

  const changeToNextWeek = () =>
    onChange(
      endOfDay(
        startOfWeek(addDays(new Date(), 7), {
          weekStartsOn: weekdaysNumberMap[weekStartsOn],
        }),
      ),
    );

  const clear = () => onChange(undefined);

  const onChangeDate = (date: Date | null) =>
    onChange(date ? endOfDay(date) : undefined);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useKeysDown(['Enter'], () => {
    if (inputRef.current && document.activeElement === inputRef.current) {
      const inputVal = inputRef.current.value;
      const date = inputVal
        ? parse(inputVal, dateFormat, new Date())
        : undefined;
      const isValidDate =
        !inputRef.current.value ||
        (inputVal?.length === dateFormat.length &&
          !!date &&
          !isNaN(date.getTime()));

      if (isValidDate) {
        onChange(date);
        return;
      }

      setInputError(true);
    }
  });

  return (
    <PopupMenu {...rest}>
      <PopupMenuList hasBottomBorder>
        <PopupMenuListItem>
          <PopupMenuInput
            ref={inputRef}
            iconColor={inputError ? Colors.Error : undefined}
            startIcon={icon === IconOptions.Calendar ? Calendar : Flag}
            textColor={inputError ? Colors.Error : undefined}
            value={inputValue}
            onChange={onChangeInput}
            placeholder={dateFormatOptionToLabelMap[dateFormat]}
          />
        </PopupMenuListItem>
      </PopupMenuList>

      <PopupMenuList hasBottomBorder>
        <PopupMenuListItem>
          <PopupMenuButton
            onClick={changeToToday}
            start={
              <Icon icon={icon === IconOptions.Calendar ? Calendar : Flag} />
            }
            tabIndex={0}
            type="button"
          >
            {t('forms.deadline.fields.today.label')}
          </PopupMenuButton>
        </PopupMenuListItem>
        <PopupMenuListItem>
          <PopupMenuButton
            onClick={changeToTomorrow}
            start={
              <Icon
                icon={icon === IconOptions.Calendar ? CalendarTomorrow : Flag}
              />
            }
            tabIndex={0}
            type="button"
          >
            {t('forms.deadline.fields.tomorrow.label')}
          </PopupMenuButton>
        </PopupMenuListItem>
        <PopupMenuListItem>
          <PopupMenuButton
            onClick={changeToNextWeek}
            start={
              <Icon
                icon={icon === IconOptions.Calendar ? CalendarTomorrow : Flag}
              />
            }
            tabIndex={0}
            type="button"
          >
            {t('forms.deadline.fields.next-week.label')}
          </PopupMenuButton>
        </PopupMenuListItem>
      </PopupMenuList>

      <PopupMenuList hasBottomBorder={!!value}>
        <Datepicker
          today={today}
          selected={value}
          onChange={onChangeDate}
          dateFormat={dateFormat}
          weekStartsOn={weekStartsOn}
          inline
        />
      </PopupMenuList>

      {!!value && canRemoveValue && (
        <PopupMenuList>
          <PopupMenuListItem>
            <PopupMenuButton
              onClick={clear}
              start={<Icon icon={CalendarDeleteDate} />}
              textColor={Colors.Error}
              startColor={Colors.Error}
              tabIndex={0}
              type="button"
            >
              {t('forms.deadline.fields.clear.label')}
            </PopupMenuButton>
          </PopupMenuListItem>
        </PopupMenuList>
      )}
    </PopupMenu>
  );
};
