import { scaleBand } from '@visx/scale';
import { useMemo } from 'react';
import { BarChartEntry } from 'shared/lib/recharts';

export type Options = {
  data: BarChartEntry[];
  width: number;
  yAxisOffset: number;
};

export const useXScale = ({ data, width, yAxisOffset }: Options) =>
  useMemo(() => {
    const padding = width * 0.001;

    return scaleBand({
      domain: data.map((_, index) => index),
      range: [padding, width - yAxisOffset - padding],
    });
  }, [data, width, yAxisOffset]);
