import { endOfDay, startOfDay } from 'date-fns';
import { RRule } from 'shared/lib/rrule';

export type Options = {
  format: string;
  startDate: Date;
  referenceStartDate?: Date;
  endDate: Date;
};

export const getRruleDates = ({
  format,
  startDate,
  referenceStartDate,
  endDate,
}: Options) => {
  const rule = RRule.fromString(format);
  // weirdly we need to set a start and end date on the options for this to work..
  rule.options.dtstart = startOfDay(startDate);
  rule.options.until = endOfDay(endDate);

  return rule.between(referenceStartDate ?? startDate, endDate, true);
};

export const getRruleFrequency = (options: Options): number =>
  getRruleDates(options).length;
