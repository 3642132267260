import { Frequency } from 'shared/lib/rrule';

export const frequencyToWeekAdjustmentsMap: Record<Frequency, number> = {
  [Frequency.SECONDLY]: -1,
  [Frequency.MINUTELY]: -1,
  [Frequency.HOURLY]: -1,
  [Frequency.DAILY]: -1,
  [Frequency.WEEKLY]: -1,
  [Frequency.MONTHLY]: -4,
  [Frequency.YEARLY]: -52,
};
