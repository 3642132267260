import React from 'react';
import { TaskMetaInteractionDueDate } from 'shared/components/ui/task-meta-interaction-due-date';
import { TaskMetaInteractionPriority } from 'shared/components/ui/task-meta-interaction-priority';
import { TaskMetaInteractionReminderTime } from 'shared/components/ui/task-meta-interaction-reminder-time';
import { TaskMetaInteractionRepeat } from 'shared/components/ui/task-meta-interaction-repeat';
import { useUser } from 'shared/hooks/use-user';
import { initialDateFormat } from 'shared/types/date-format-options';
import { CreateTask } from 'shared/types/task';
import { TaskPriorityOptions } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

export type MetaInteractionsProps = {
  newTask: CreateTask;
  onChange: (value: Partial<CreateTask>) => void;
  onRepeat: () => void;
};

export const MetaInteractions: React.FC<MetaInteractionsProps> = ({
  newTask,
  onChange,
  onRepeat,
}) => {
  const user = useUser();

  const onChangeDeadline = (date?: Timestamp) =>
    onChange({
      endStrategy: {
        ...(newTask.endStrategy ?? {}),
        deadline: date ?? null,
      },
    });

  const onChangeReminder = (reminderTime?: number) =>
    onChange({ reminderTime });

  const onChangePriority = (priority: TaskPriorityOptions | null) =>
    onChange({ priority });

  return (
    <>
      <TaskMetaInteractionDueDate
        dateFormat={user?.settings?.dateFormat ?? initialDateFormat}
        weekStartsOn={getWeekStartsOn(user)}
        onChange={onChangeDeadline}
        value={newTask.endStrategy?.deadline ?? undefined}
        highlight
        isMobileOutlined
      />
      <TaskMetaInteractionRepeat
        value={newTask.schedules}
        onClick={onRepeat}
        highlight
        isMobileOutlined
      />
      <TaskMetaInteractionReminderTime
        onChange={onChangeReminder}
        value={newTask.reminderTime}
        highlight
        isMobileOutlined
      />
      <TaskMetaInteractionPriority
        value={newTask.priority}
        onChange={onChangePriority}
        highlight
        isMobileOutlined
      />
    </>
  );
};
