import { doc, DocumentReference, serverTimestamp } from 'firebase/firestore';
import { Goal } from 'shared/types/goal';
import { ID } from 'shared/types/id';
import { Task } from 'shared/types/task';
import { Timestamp } from 'shared/types/timestamp';

import { dateToFirestoreTimestamp } from './converters/date-to-firestore-timestamp';
import { getGoalDocs } from './get-goals';
import { getTasksWithSubTasks } from './get-tasks-with-sub-tasks';
import {
  BatchDocuments,
  CollectionOptions,
  getCollection,
  getDatabase,
} from './helpers';

export const duplicateGoal = async (goal: Goal) => {
  const goalCollection = getCollection(CollectionOptions.Goals);
  const taskCollection = getCollection(CollectionOptions.Tasks);

  const dateBase = new Date();
  // get all related documents to the given goal
  const goals = await getGoalDocs();
  const subGoals = goals
    .filter(({ parentIds }) => parentIds?.includes(goal.id))
    .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
  const tasks = await getTasksWithSubTasks({
    goals: [goal.id, ...subGoals.map(({ id }) => id)],
  });

  // create an old-new map of ids for the goal and subgoals
  const goalMap = new Map<string, DocumentReference>();
  goalMap.set(goal.id, doc(goalCollection));
  subGoals.forEach(({ id }) => goalMap.set(id, doc(goalCollection)));

  // create an old-new map for ids for the tasks
  const taskMap = new Map<string, DocumentReference>();
  tasks.forEach(({ id }) => taskMap.set(id, doc(taskCollection)));

  const batchDocuments = new BatchDocuments(getDatabase());

  // loop over the goals and create new documents
  [goal, ...subGoals].forEach((goalToCopy, index) => {
    const docRef = goalMap.get(goalToCopy.id);
    if (!docRef) {
      return;
    }

    const newGoal: Goal = {
      ...goalToCopy,
      id: docRef.id,
      name:
        goal.id === goalToCopy.id
          ? `${goalToCopy.name} (copy)`
          : goalToCopy.name,
      image: null,
      parentIds:
        (goalToCopy.parentIds
          // if the parent is copied as well, take that id. Otherwise, keep the same parentIds as the goal this is copied from
          ?.map((id) => goalMap.get(id)?.id ?? id)
          .filter(Boolean) as ID[]) ?? null,
      goalSorting:
        (goalToCopy.goalSorting
          ?.map((id) => goalMap.get(id)?.id ?? null)
          .filter(Boolean) as ID[]) ?? null,
      taskSorting:
        (goalToCopy.taskSorting
          ?.map((id) => taskMap.get(id)?.id ?? null)
          .filter(Boolean) as ID[]) ?? null,
      sections:
        goalToCopy.sections?.map((goalSection) => ({
          ...goalSection,
          id: window.crypto.randomUUID(),
          tasks: goalSection.tasks
            .map((taskId) => taskMap.get(taskId)?.id ?? null)
            .filter(Boolean) as ID[],
        })) ?? [],
      completedTaskCount: 0,
      excludedFromLimits: null,
      // add set the milliseconds to make sure the createdAt dates are still in the correct order
      createdAt: dateToFirestoreTimestamp(
        new Date(dateBase.setMilliseconds(index)),
      ),
      updatedAt: serverTimestamp() as unknown as Timestamp,
      completedAt: null,
      roles: { owner: goalToCopy.roles.owner, all: [goalToCopy.roles.owner] },
    };

    batchDocuments.addDocument({
      data: newGoal,
      ref: docRef,
    });
  });

  // loop over the tasks and create new tasks
  tasks.forEach((task, index) => {
    const docRef = taskMap.get(task.id);
    if (!docRef) {
      return;
    }

    const newTask: Task = {
      ...task,
      id: docRef.id,
      lifeAreaId: null,
      goalId: task.goalId ? goalMap.get(task.goalId)?.id ?? null : null,
      childIds:
        (task.childIds
          ?.map((id) => taskMap.get(id)?.id ?? null)
          .filter(Boolean) as ID[]) ?? null,
      parentIds:
        (task.parentIds
          ?.map((id) => taskMap.get(id)?.id ?? null)
          .filter(Boolean) as ID[]) ?? null,
      subtaskOrder:
        (task.subtaskOrder
          ?.map((id) => taskMap.get(id)?.id ?? null)
          .filter(Boolean) as ID[]) ?? null,
      // add set the milliseconds to make sure the createdAt dates are still in the correct order
      createdAt: dateToFirestoreTimestamp(
        new Date(dateBase.setMilliseconds(index)),
      ),
      updatedAt: serverTimestamp() as unknown as Timestamp,
      completedAt: null,
      roles: { owner: goal.roles.owner, all: [goal.roles.owner] },
    };

    batchDocuments.addDocument({
      data: newTask,
      ref: docRef,
    });
  });

  // commit all documents to be created at once
  batchDocuments.commit();

  return goalMap.get(goal.id)?.id;
};
