import React from 'react';
import { ReactDatePicker } from 'shared/lib/react-datepicker';
import { Timestamp } from 'shared/types/timestamp';

import * as Styled from './datepicker.style';
import { Header } from './header';

export type DatepickerProps = Omit<
  React.ComponentProps<typeof ReactDatePicker>,
  | 'renderCustomHeader'
  | 'showPopperArrow'
  | 'shouldCloseOnSelect'
  | 'fixedHeight'
> & {
  today: Timestamp;
  bordered?: boolean;
};

export const Datepicker: React.FC<DatepickerProps> = ({
  today,
  selected,
  popperPlacement = 'bottom-start',
  weekStartsOn,
  inline,
  bordered,
  ...props
}) => (
  <Styled.Container $inline={!!inline} $bordered={!!bordered}>
    <Styled.Datepicker
      {...props}
      selected={selected}
      renderCustomHeader={(params) => (
        <Header currentDate={today} {...params} />
      )}
      popperPlacement={popperPlacement}
      weekStartsOn={weekStartsOn}
      inline={inline}
      showPopperArrow={false}
      shouldCloseOnSelect
      fixedHeight
    />
  </Styled.Container>
);
