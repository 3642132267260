import { addDays, addWeeks, endOfDay, isBefore, startOfDay } from 'date-fns';
import { useMemo } from 'react';
import { useTimeframeSchedules } from 'shared/hooks/use-timeframe-schedules';
import { isBetween } from 'shared/lib/date-fns';
import { RRule } from 'shared/lib/rrule';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';

export type Options = {
  startDate: Timestamp;
  endDate: Timestamp;
};

export const useTimeframeOccurrences = (
  schedules: HabitSchedule[],
  { startDate, endDate }: Options,
) => {
  // get a bigger range for extra completions to display in the month-view
  const rangeStartDate = useMemo(() => addWeeks(startDate, -3), [startDate]);
  const rangeEndDate = useMemo(() => addWeeks(endDate, 3), [endDate]);
  const timeframeSchedules = useTimeframeSchedules(schedules, {
    startDate: rangeStartDate,
    endDate: rangeEndDate,
  });

  const occurrences = useMemo(() => {
    const occurrences = timeframeSchedules.reduce<Timestamp[]>(
      (acc, schedule) => {
        if (schedule.frequency?.unit) {
          return acc;
        }

        // if there are completions before the startDate, we take the first completion and start from there to calculate the occurrences
        const completionsBeforeStartDate = isBefore(
          rangeStartDate,
          schedule.startDate,
        )
          ? schedule.completions
              .filter((completion) =>
                isBetween(completion, rangeStartDate, schedule.startDate),
              )
              .sort(
                (completionA, completionB) =>
                  completionA.getTime() - completionB.getTime(),
              )
          : [];
        if (completionsBeforeStartDate.length) {
          const earliestCompletion = completionsBeforeStartDate[0];

          const rrule = RRule.fromString(schedule.rrule.format);
          rrule.options.dtstart = startOfDay(earliestCompletion);
          rrule.options.until = endOfDay(addDays(schedule.startDate, -1));
          acc.push(
            ...rrule.between(
              startOfDay(earliestCompletion),
              schedule.startDate,
              true,
            ),
          );
        }

        const rrule = RRule.fromString(schedule.rrule.format);
        rrule.options.dtstart = startOfDay(schedule.startDate);
        rrule.options.until = endOfDay(
          schedule.endDate && isBefore(schedule.endDate, rangeEndDate)
            ? schedule.endDate
            : rangeEndDate,
        );

        acc.push(...rrule.between(rangeStartDate, rangeEndDate, true));

        return acc;
      },
      [],
    );

    const uniqueOccurrences = new Set(
      occurrences.map((occurrence) => startOfDay(occurrence).getTime()),
    );

    return Array.from(uniqueOccurrences).map(
      (occurrence) => new Date(occurrence),
    );
  }, [rangeEndDate, rangeStartDate, timeframeSchedules]);

  const timeframeOccurrences = useMemo(
    () =>
      occurrences.filter((occurrence) =>
        isBetween(occurrence, startDate, endDate),
      ),
    [endDate, occurrences, startDate],
  );

  return {
    occurrences,
    timeframeOccurrences,
  };
};
