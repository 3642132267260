import { format, isSameDay } from 'date-fns';
import { Timeframe } from 'shared/types/timeframe';

export type Options = {
  value: number;
  target?: number;
  timeframe: Timeframe;
};

export const getDateBarTooltip = ({ value, target, timeframe }: Options) => {
  const valString = target ? `${value}/${target}` : value;

  if (isSameDay(timeframe.startDate, timeframe.endDate)) {
    return `${valString} (${format(timeframe.startDate, 'MMM d')})`;
  }

  return `${valString} (${format(timeframe.startDate, 'MMM d')} - ${format(timeframe.endDate, 'MMM d')})`;
};
