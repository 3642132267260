import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import React from 'react';
import {
  ArrowRight,
  Award,
  BarChart2,
  CheckCircle,
  Question,
} from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { Timestamp } from 'shared/types/timestamp';
import { isNumber } from 'shared/utils/is-number';

import * as Styled from './header.style';
import { Colors } from './types';

type StatusProps = {
  value: number;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  color: Colors;
  isPercentage?: boolean;
};

const StatusItem: React.FC<StatusProps> = ({
  value,
  icon,
  color,
  isPercentage,
}) => (
  <Styled.StatusContainer>
    <Styled.StatusIcon $color={color}>
      <Icon icon={icon} />
    </Styled.StatusIcon>

    <Typography variant="overline">
      {isPercentage ? (value ? `${value}%` : '-') : value || 0}
    </Typography>
  </Styled.StatusContainer>
);

export type HeaderProps = {
  date: Timestamp;
  completionCount?: number;
  completionsPercentage?: number;
  skipCount?: number;
  failureCount?: number;
  perfectSlots?: number;
  hideStatus?: boolean;
  onInfo?: () => void;
};

export const Header: React.FC<HeaderProps> = ({
  date,
  completionCount,
  completionsPercentage,
  skipCount,
  failureCount,
  perfectSlots,
  hideStatus,
  onInfo,
}) => (
  <Styled.Container>
    <Typography variant="h5">{format(date, "LLL ''yy")}</Typography>

    <Styled.Meta>
      {!hideStatus && (
        <Styled.StatusList>
          <Styled.StatusItem>
            <StatusItem
              value={completionCount ?? 0}
              icon={CheckCircle}
              color={Colors.Primary}
            />
          </Styled.StatusItem>
          {isNumber(completionsPercentage) && (
            <Styled.StatusItem>
              <StatusItem
                value={completionsPercentage!}
                icon={BarChart2}
                color={Colors.Info}
                isPercentage
              />
            </Styled.StatusItem>
          )}
          <Styled.StatusItem>
            <StatusItem
              value={perfectSlots ?? 0}
              icon={Award}
              color={Colors.Success}
            />
          </Styled.StatusItem>
          <Styled.StatusItem>
            <StatusItem
              value={skipCount ?? 0}
              icon={ArrowRight}
              color={Colors.Warning}
            />
          </Styled.StatusItem>
          {/*<Styled.StatusItem>*/}
          {/*  <StatusItem value={failureCount ?? 0} icon={X} color={Colors.Error} />*/}
          {/*</Styled.StatusItem>*/}
        </Styled.StatusList>
      )}
      {!!onInfo && (
        <Styled.InfoButtonContainer>
          <IconButton
            icon={Question}
            size={Sizes.ExtraSmall}
            onClick={onInfo}
          />
        </Styled.InfoButtonContainer>
      )}
    </Styled.Meta>
  </Styled.Container>
);
