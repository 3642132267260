import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormSelect } from 'shared/components/ui/form-select';
import { WeekDays } from 'shared/types/week-days';

import * as Styled from './form-body.style';
import { getMonthDayOrdinals } from './get-month-day-ordinals';
import {
  parseMonthFromRrule,
  parseYearWeekDayFromRrule,
  setYearSpecificMonthDayInRrule,
} from './parse-rrule';
import { DayType } from './types';

const months: number[] = Array.from({ length: 12 }).map(
  (_, index) => index + 1,
);

const ordinals = [1, 2, 3, 4, 5, -1];

export type MonthsFormProps = {
  rrule: string;
  onChange: (rrule: string) => void;
  weekStartsOn: WeekDays;
};

export const RruleYearsBody: React.FC<MonthsFormProps> = ({
  rrule,
  weekStartsOn,
  onChange,
}) => {
  const { t } = useTranslation();
  const selectedWeekDay = useMemo(
    () => parseYearWeekDayFromRrule(rrule),
    [rrule],
  );
  const selectedMonth = useMemo(() => parseMonthFromRrule(rrule), [rrule]);

  const onChangeOrdinalDay = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!selectedWeekDay) {
      return;
    }

    onChange(
      setYearSpecificMonthDayInRrule(rrule, {
        weekDay: e.target.value as DayType | WeekDays,
        ordinal: selectedWeekDay.ordinal,
        month: selectedMonth,
      }),
    );
  };

  const onChangeOrdinalNumber = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!selectedWeekDay) {
      return;
    }

    onChange(
      setYearSpecificMonthDayInRrule(rrule, {
        weekDay: selectedWeekDay.weekDay,
        ordinal: parseInt(e.target.value),
        month: selectedMonth,
      }),
    );
  };

  const onChangeMonth = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!selectedWeekDay) {
      return;
    }

    onChange(
      setYearSpecificMonthDayInRrule(rrule, {
        weekDay: selectedWeekDay.weekDay,
        ordinal: selectedWeekDay.ordinal,
        month: parseInt(e.target.value),
      }),
    );
  };

  const ordinalOptions = useMemo(
    () =>
      ordinals.map((ordinal) => ({
        label: t(
          `general.labels.habit.repeat.value.ordinal.specific-day.${ordinal}.label`,
        ),
        value: ordinal,
      })),
    [t],
  );

  const ordinalDayOptions = useMemo(
    () =>
      getMonthDayOrdinals(weekStartsOn).map((weekDay) => ({
        label: t(
          `general.labels.habit.repeat.specific-days.days.${weekDay}.label`,
        ),
        value: weekDay,
      })),
    [t, weekStartsOn],
  );

  const monthOptions = useMemo(
    () =>
      months.map((month) => ({
        label: t(`general.labels.habit.repeat.year.months.${month}.label`),
        value: month,
      })),
    [t],
  );

  return (
    <Styled.Container>
      <Typography variant="body2">
        {t('forms.habit.fields.repeat.months.specific-day.label')}
      </Typography>

      <Styled.TimesPerContainer>
        <FormSelect
          value={selectedWeekDay?.ordinal}
          options={ordinalOptions}
          onChange={onChangeOrdinalNumber}
          isSmall
        />

        <FormSelect
          value={selectedWeekDay?.weekDay}
          options={ordinalDayOptions}
          onChange={onChangeOrdinalDay}
          isSmall
        />

        <FormSelect
          value={selectedMonth}
          options={monthOptions}
          onChange={onChangeMonth}
          isSmall
        />
      </Styled.TimesPerContainer>
    </Styled.Container>
  );
};
