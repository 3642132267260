import {
  differenceInWeeks,
  endOfWeek,
  format,
  isSameWeek,
  startOfMonth,
} from 'date-fns';
import { weekdaysNumberMap } from 'shared/constants';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

export type Options = {
  startDate: Timestamp;
  endDate: Timestamp;
  weekStartsOn: WeekDays;
};

export const getDateXAxisLabel = (
  date: Timestamp,
  { startDate, endDate, weekStartsOn }: Options,
) => {
  const difInWeeks = differenceInWeeks(endDate, startDate);

  return difInWeeks <= 1
    ? dateTickFormatterWeekDay(date)
    : difInWeeks < 11
      ? dateTickFormatterDay(date)
      : dateTickFormatterMonth(date, weekStartsOn);
};

export const dateTickFormatterWeekDay = (date: Timestamp) => {
  return format(date, 'iiiii');
};

export const dateTickFormatterDay = (date: Timestamp) => {
  return format(date, 'd');
};

export const dateTickFormatterMonth = (
  date: Timestamp,
  weekStartsOn: WeekDays,
) => {
  const dateFnsWeekOptions = { weekStartsOn: weekdaysNumberMap[weekStartsOn] };
  const endOfWeekDate = endOfWeek(date, dateFnsWeekOptions);

  if (isSameWeek(date, startOfMonth(endOfWeekDate), dateFnsWeekOptions)) {
    return format(endOfWeekDate, 'LLLLL');
  }

  return '';
};
