import {
  useCompleteHabitDate,
  useHabitBarChartEntries,
  useHabitProgress,
  useSkipHabitDate,
} from 'features/tasks';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InsightsTimeframeSelection } from 'shared/components/ui/insights-timeframe-selection';
import { useOpenInsightsCalculations } from 'shared/contexts/insights-calculations';
import { useToday } from 'shared/contexts/today';
import { useActiveSchedule } from 'shared/hooks/use-active-schedule';
import { useTimeByInsightsTimeframe } from 'shared/hooks/use-time-by-insights-timeframe';
import { useUser } from 'shared/hooks/use-user';
import { Habit } from 'shared/types/habit';
import { EntryState, FrequencyUnit } from 'shared/types/habit-schedule';
import { InsightsTimeframes } from 'shared/types/insights';
import { Timestamp } from 'shared/types/timestamp';
import { getScheduleFrequency } from 'shared/utils/get-schedule-frequency';
import { getScheduleLabel } from 'shared/utils/get-schedule-label';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';

import { Statistics } from './statistics';
import * as Styled from './task-statistics.style';
import { TimeframeSpecifics } from './timeframe-specifics';

export type TaskStatisticsProps = {
  task: Habit;
};

export const TaskStatistics: React.FC<TaskStatisticsProps> = ({ task }) => {
  const { t } = useTranslation();
  const user = useUser();
  const today = useToday();

  const [timeframe, setTimeframe] = useState(
    user?.settings?.insights?.timeframe ?? InsightsTimeframes.Year,
  );
  const openInsightsCalculations = useOpenInsightsCalculations();

  const weekStartsOn = getWeekStartsOn(user);
  const activeSchedule = useActiveSchedule(task.schedules);

  const weeklyTarget =
    activeSchedule?.frequency?.unit === FrequencyUnit.Week
      ? activeSchedule.frequency.count
      : undefined;
  const { startDate, endDate } = useTimeByInsightsTimeframe(timeframe, {
    weekStartsOn,
    allMonthWeeks: activeSchedule?.frequency?.unit === FrequencyUnit.Week,
    quarterRoundedToWeeks:
      activeSchedule?.frequency?.unit === FrequencyUnit.Week,
    yearRoundedToWeeks: activeSchedule?.frequency?.unit === FrequencyUnit.Week,
  });

  const {
    occurrences,
    completions,
    timeframeCompletions,
    skips,
    timeframeSkips,
    scheduleChanges,
  } = useHabitProgress(task, {
    startDate,
    endDate,
  });

  const { entries, entriesTarget, target, completionPercentage, perfectSlots } =
    useHabitBarChartEntries(task, {
      timeframe,
      startDate,
      endDate,
      weekStartsOn,
    });

  const completeHabitDate = useCompleteHabitDate();
  const skipHabitDate = useSkipHabitDate();

  const activeFrequency = useMemo(
    () => (activeSchedule ? getScheduleFrequency(activeSchedule) : undefined),
    [activeSchedule],
  );

  const onDate = (date: Timestamp, type: EntryState) => {
    if (type === EntryState.Skip) {
      skipHabitDate(task, date);
      return;
    }
    completeHabitDate(task, date, true);
  };

  const onInfo = () =>
    openInsightsCalculations({ habit: task, timeframe, referenceDate: today });

  return (
    <>
      <Styled.Header>
        <Styled.Title>{task.name}</Styled.Title>
        {!!activeSchedule && (
          <Styled.Description>
            {getScheduleLabel(activeSchedule, t)}
          </Styled.Description>
        )}
        <InsightsTimeframeSelection value={timeframe} onChange={setTimeframe} />
      </Styled.Header>

      <Styled.Body>
        <Statistics
          target={target}
          completionPercentage={completionPercentage}
          completions={timeframeCompletions}
          perfectSlots={perfectSlots.length}
          slotsType={activeFrequency}
          skips={timeframeSkips}
        />

        <TimeframeSpecifics
          timeframe={timeframe}
          startDate={startDate}
          endDate={endDate}
          weekStartsOn={weekStartsOn}
          entries={entries}
          entriesTarget={entriesTarget}
          occurrences={occurrences}
          completions={completions}
          timeframeCompletions={timeframeCompletions}
          skips={skips}
          target={target}
          onDate={onDate}
          weeklyTarget={weeklyTarget}
          monthlyTarget={
            activeSchedule?.frequency?.unit === FrequencyUnit.Month
              ? activeSchedule.frequency.count
              : undefined
          }
          showCompletionPercentage={
            ![FrequencyUnit.Week].includes(activeSchedule?.frequency?.unit!)
          }
          showSurroundingWeeks={[FrequencyUnit.Week].includes(
            activeSchedule?.frequency?.unit!,
          )}
          onInfo={
            [FrequencyUnit.Week].includes(activeSchedule?.frequency?.unit!) &&
            [InsightsTimeframes.Quarter].includes(timeframe)
              ? onInfo
              : undefined
          }
          perfectSlots={perfectSlots}
          scheduleChanges={scheduleChanges}
        />
      </Styled.Body>
    </>
  );
};
