import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Colors,
  Sizes as ButtonSizes,
  Variants as ButtonVariants,
} from 'shared/components/ui/button';

import * as Styled from './feedback-dialog.style';

export type FeedbackFormProps = {
  userName?: string;
  onSubmit: (fields: { message: string }) => void;
};

export const FeedbackForm: React.FC<FeedbackFormProps> = ({
  userName,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const onChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setMessage(e.target.value);

  const _onSubmit = () => {
    onSubmit({ message });
  };

  return (
    <>
      <Styled.Header>
        <Typography variant="h5" component="h2">
          {t('dialogs.feedback.title')}
        </Typography>
      </Styled.Header>

      <Styled.Body>
        <Styled.TextArea
          value={message}
          placeholder={t(
            userName
              ? 'dialogs.feedback.text-area.placeholder.user'
              : 'dialogs.feedback.text-area.placeholder.no-user',
            { user: userName },
          )}
          onChange={onChangeText}
        />
      </Styled.Body>

      <Styled.Footer>
        <Button
          size={ButtonSizes.Medium}
          color={Colors.Primary}
          variant={ButtonVariants.Contained}
          disabled={!message}
          onClick={_onSubmit}
        >
          {t('dialogs.feedback.submit.label')}
        </Button>
      </Styled.Footer>
    </>
  );
};
