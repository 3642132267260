import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormSelect } from 'shared/components/ui/form-select';
import { Frequency, RRule } from 'shared/lib/rrule';
import { WeekDays } from 'shared/types/week-days';
import { getWeekDays } from 'shared/utils/get-week-days';

import * as Styled from './form.style';
import { IntervalInput } from './interval-input';
import { setYearSpecificMonthDayInRrule } from './parse-rrule';
import {
  defaultRrule,
  FormUnit,
  formUnits,
  formUnitToRruleFreqMap,
} from './types';

export type HeaderProps = {
  unit: FormUnit;
  rrule: string;
  onChangeUnit: (rrule: string) => void;
  onChangeInterval: (updatedRrule: string) => void;
  weekStartsOn: WeekDays;
};

export const Header: React.FC<HeaderProps> = ({
  unit,
  rrule,
  onChangeUnit,
  onChangeInterval,
  weekStartsOn,
}) => {
  const { t } = useTranslation();

  const interval = useMemo(
    () => RRule.fromString(rrule).options.interval ?? 1,
    [rrule],
  );
  const initialOrdinalWeekDay = useMemo(
    () => getWeekDays(weekStartsOn)[0],
    [weekStartsOn],
  );

  const _onChangeInterval = (value: number) => {
    const newInterval = value ?? 1;
    const rule = RRule.fromString(rrule);
    rule.origOptions.interval = newInterval > 1 ? newInterval : undefined;
    onChangeInterval(rule.toString());
  };

  const _onChangeUnit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const freq = formUnitToRruleFreqMap[e.target.value as FormUnit];
    switch (freq) {
      case Frequency.YEARLY:
        return onChangeUnit(
          setYearSpecificMonthDayInRrule(rrule, {
            weekDay: initialOrdinalWeekDay,
            ordinal: 1,
            month: 1,
          }),
        );
      case Frequency.MONTHLY:
        return onChangeUnit(new RRule({ freq }).toString());
      default:
        onChangeUnit(defaultRrule);
    }
  };

  const formUnitOptions = useMemo(
    () =>
      formUnits.map((formUnit) => ({
        value: formUnit,
        label: t(`forms.habit.fields.repeat.header.units.${formUnit}`, {
          count: interval,
        }),
      })),
    [interval, t],
  );

  return (
    <Styled.HeaderContainer>
      <Typography variant="body2" component="span">
        {t('forms.habit.fields.repeat.header.repeat-every')}
      </Typography>

      <IntervalInput value={interval} onChange={_onChangeInterval} />

      <FormSelect
        value={unit}
        onChange={_onChangeUnit}
        options={formUnitOptions}
        isSmall
      />
    </Styled.HeaderContainer>
  );
};
