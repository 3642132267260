import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Repeat } from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { TaskMetaInteraction } from 'shared/components/ui/task-meta-interaction';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { getActiveSchedule } from 'shared/utils/get-active-schedule';
import { getScheduleLabel } from 'shared/utils/get-schedule-label';

import * as Styled from './task-meta-interaction-repeat.style';

export type TaskMetaInteractionRepeatProps = Omit<
  React.ComponentProps<typeof TaskMetaInteraction>,
  'start' | 'onClick' | 'label' | 'selected' | 'tooltipLabel'
> & {
  value: HabitSchedule[] | null;
  onClick: () => void;
};

export const TaskMetaInteractionRepeat: React.FC<
  TaskMetaInteractionRepeatProps
> = ({
  value,
  onClick,

  ...rest
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  const val = getActiveSchedule(value);

  return (
    <Styled.Container ref={containerRef}>
      <TaskMetaInteraction
        start={<Icon icon={Repeat} />}
        onClick={onClick}
        label={val ? getScheduleLabel(val, t) : undefined}
        tooltipLabel={t(
          val
            ? 'task.repeat.tooltip.edit.label'
            : 'task.repeat.tooltip.add.label',
        )}
        {...rest}
      />
    </Styled.Container>
  );
};
