import { useSortable } from '@dnd-kit/sortable';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Check,
  ChevronLeft,
  IconFormIcons,
  IconFormNames,
  Lock,
  ReorderDotsVertical,
} from 'shared/assets/icons';
import { Icon } from 'shared/components/ui/icon';
import { IconButton } from 'shared/components/ui/icon-button';
import { ContextMenu } from 'shared/components/ui/task-card/context-menu';
import { useActiveSchedule } from 'shared/hooks/use-active-schedule';
import { useContextMenuState } from 'shared/hooks/use-context-menu-state';
import { Goal } from 'shared/types/goal';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { ID } from 'shared/types/id';
import { LifeArea } from 'shared/types/life-area';
import { TaskType } from 'shared/types/task-base';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getGoalColor } from 'shared/utils/get-goal-color';
import { getScheduleLabel } from 'shared/utils/get-schedule-label';

import { Week } from '../../types/week';
import * as Styled from './habit-detail-desktop-card.style';
import { Schedule } from './schedule';

type SortableContainerProps = React.ComponentProps<typeof Styled.Container> & {
  id: ID;
};

const SortableContainer: React.FC<SortableContainerProps> = ({
  id,
  ...rest
}) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    isDragging,
    transform,
    transition,
  } = useSortable({ id });

  return (
    <Styled.Container
      {...rest}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      role="presentation"
      $isDragging={isDragging}
      $transform={transform}
      $transition={transition}
    />
  );
};

export type HabitDetailCardProps = {
  id: ID;
  icon?: IconFormNames | null;
  name: string;
  schedules: HabitSchedule[];
  hideOnTodo?: boolean;
  goalId?: ID | null;
  goals: Goal[];
  lifeAreaId?: ID | null;
  lifeAreas: LifeArea[];
  weeks: Week[];
  weekStartsOn: WeekDays;
  onCompleteDate?: (id: ID, date: Timestamp) => void;
  onCompleteHabit?: (id: ID) => void;
  onEditHabit?: (id: ID) => void;
  onArchiveHabit?: (id: ID) => void;
  onChangeHabitType?: (
    id: ID,
    type: TaskType.Habit | TaskType.Repeating,
  ) => void;
  onChangeHideOnTodo?: (id: ID) => void;
  onDeleteHabit?: (id: ID) => void;
  scheduleDateWidth?: number;
  scheduleVisibleDates?: number;
  scheduleScrollToDate?: number;
  isFrozen?: boolean;
  isCompleted?: boolean;
  isArchived?: boolean;
  isSortable?: boolean;
};

export const HabitDetailDesktopCard: React.FC<HabitDetailCardProps> = ({
  id,
  icon,
  name,
  schedules,
  hideOnTodo,
  goalId,
  goals,
  lifeAreaId,
  lifeAreas,
  weeks,
  weekStartsOn,
  onCompleteDate,
  onCompleteHabit,
  onEditHabit,
  onChangeHabitType,
  onChangeHideOnTodo,
  onDeleteHabit,
  onArchiveHabit,
  scheduleDateWidth,
  scheduleVisibleDates,
  scheduleScrollToDate,
  isFrozen,
  isCompleted,
  isArchived,
  isSortable,
}) => {
  const { t } = useTranslation();
  const {
    position: contextMenuPosition,
    open: openContextMenu,
    close: closeContextMenu,
  } = useContextMenuState({ disabled: isFrozen, basedOnWindow: true });
  const Container = isSortable ? SortableContainer : Styled.Container;

  const activeSchedule = useActiveSchedule(schedules);
  const activeLinkedItem = useMemo(
    () =>
      goals.find(({ id }) => goalId === id) ??
      lifeAreas.find(({ id }) => id === lifeAreaId),
    [goals, lifeAreas, goalId, lifeAreaId],
  );

  const linkedItemColor = useMemo(
    () =>
      activeLinkedItem
        ? getGoalColor(activeLinkedItem.id, { goals, lifeAreas })
        : undefined,
    [activeLinkedItem, goals, lifeAreas],
  );

  const onDelete = () => {
    closeContextMenu();
    onDeleteHabit?.(id);
  };

  const _onCompleteDate = (date: Timestamp) => {
    closeContextMenu();
    onCompleteDate?.(id, date);
  };

  const _onEdit = () => {
    closeContextMenu();
    onEditHabit?.(id);
  };

  return (
    <>
      <Container
        id={id}
        $isDisabled={!!isFrozen || !!isArchived}
        $isSelected={!!contextMenuPosition}
      >
        {!!isSortable && (
          <Styled.DragHandle>
            <IconButton icon={ReorderDotsVertical} />
          </Styled.DragHandle>
        )}
        <Styled.Details onClick={_onEdit} onContextMenu={openContextMenu}>
          <Styled.IconContainer>
            <Icon icon={IconFormIcons[icon ?? IconFormNames.Repeat]} />
            {isCompleted && (
              <Styled.CompletedContainer>
                <Icon icon={Check} />
              </Styled.CompletedContainer>
            )}
            {!!linkedItemColor && <Styled.ColorDot $color={linkedItemColor} />}
          </Styled.IconContainer>

          <Styled.DetailContent>
            <Styled.TitleContainer>
              <Styled.Title>{name}</Styled.Title>

              <Styled.TitleIconContainer $rotated={!isFrozen}>
                <Icon icon={isFrozen ? Lock : ChevronLeft} />
              </Styled.TitleIconContainer>
            </Styled.TitleContainer>

            {(activeSchedule || activeLinkedItem) && (
              <Styled.MetaContainer>
                {!!activeSchedule && (
                  <Styled.MetaSpan>
                    {getScheduleLabel(activeSchedule, t)}
                  </Styled.MetaSpan>
                )}
                {!!activeSchedule && !!activeLinkedItem && <Styled.Dot />}
                {!!activeLinkedItem && (
                  <Styled.MetaSpan>{activeLinkedItem.name}</Styled.MetaSpan>
                )}
              </Styled.MetaContainer>
            )}
          </Styled.DetailContent>
        </Styled.Details>

        {!isCompleted && activeSchedule && (
          <Styled.ScheduleContainer>
            <Schedule
              dateWidth={scheduleDateWidth}
              weekStartsOn={weekStartsOn}
              visibleDates={scheduleVisibleDates}
              scrollToDate={scheduleScrollToDate}
              value={activeSchedule}
              weeks={weeks}
              onCompleteDate={_onCompleteDate}
            />
          </Styled.ScheduleContainer>
        )}
      </Container>

      <ContextMenu
        id={id}
        type={TaskType.Habit}
        hideOnTodo={hideOnTodo}
        onChangeHideOnTodo={onChangeHideOnTodo}
        position={contextMenuPosition}
        onClose={closeContextMenu}
        isCompleted={isCompleted}
        onCompleteTask={onCompleteHabit}
        isArchived={isArchived}
        onArchiveTask={onArchiveHabit}
        onEditTask={onEditHabit}
        onChangeHabitType={onChangeHabitType}
        deleteTask={onDeleteHabit ? onDelete : undefined}
      />
    </>
  );
};
