import { Day, endOfMonth, endOfWeek } from 'date-fns';

export type Options = {
  weekStartsOn: Day;
};

export const endOfLastWeekOfMonth = (
  date: Date | string | number,
  { weekStartsOn }: Options,
) => endOfWeek(endOfMonth(date), { weekStartsOn });
