import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(4)}`};
`;

const BodyBase = css`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};

  padding: ${({ theme }) => `0 ${theme.spacing(4)} ${theme.spacing(3)}`};
`;

export const Body = styled.div`
  ${BodyBase};
`;

export const TargetBody = styled.div`
  ${BodyBase};
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  padding: ${({ theme }) => theme.spacing(2)};

  border-top: 1px solid ${({ theme }) => theme.palette.divider};
`;
