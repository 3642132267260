import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react';
import React, { forwardRef, useEffect, useMemo } from 'react';
import { useForwardedRef } from 'shared/hooks/use-forwarded-ref';

import * as Styled from './tooltip.style';
import { Positions } from './types';

const positions = Object.values(Positions);

const getPositionsList = (position: Positions) => {
  const index = positions.indexOf(position);
  return index === -1
    ? positions
    : [...positions.slice(index + 1), ...positions.slice(0, index)];
};

export type TooltipProps = {
  label: string;
  position?: Positions;
  referenceElement?: React.RefObject<HTMLElement>;
  location?: { top: number; left: number };
};

export const Tooltip: React.FC<TooltipProps> = forwardRef<
  HTMLDivElement,
  TooltipProps
>(({ label, position = Positions.Top, referenceElement, location }, ref) => {
  const containerRef = useForwardedRef(ref);
  const [visible, setVisible] = React.useState(false);

  const fallbackPositions = useMemo(
    () => getPositionsList(position),
    [position],
  );

  const { refs, floatingStyles, placement } = useFloating({
    elements: {
      reference: referenceElement?.current,
    },
    placement: position,
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
    middleware: [
      offset({ mainAxis: 8, alignmentAxis: 8 }),
      flip({
        fallbackPlacements: fallbackPositions,
      }),
      shift({ padding: Styled.positioningOffset }),
    ],
  });

  useEffect(() => {
    refs.setFloating(containerRef.current);
  }, [containerRef, refs]);

  useEffect(() => {
    if (referenceElement?.current) {
      referenceElement.current.onmouseenter = () => {
        // in case of referenceElement movement or delays, this makes sure it is set correctly
        refs.setPositionReference(referenceElement?.current);
        setVisible(true);
      };
      referenceElement.current.onmouseleave = () => setVisible(false);
    }
  }, [containerRef, referenceElement, refs]);

  useEffect(() => {
    if (location) {
      setVisible(true);
      refs.setPositionReference({
        getBoundingClientRect: () => ({
          width: 0,
          height: 0,
          x: location.left,
          y: location.top,
          top: location.top,
          right: location.left,
          bottom: location.top,
          left: location.left,
        }),
      });
    }
  }, [location, refs]);

  return (
    <Styled.Container
      ref={containerRef}
      style={floatingStyles}
      $placement={placement}
      $isVisible={visible}
    >
      <Styled.Label>{label}</Styled.Label>
    </Styled.Container>
  );
});
