import React, { useMemo, useState } from 'react';
import { TaskTypeToggle } from 'shared/components/ui/task-type-toggle';
import { useToday } from 'shared/contexts/today';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { TaskType } from 'shared/types/task-base';
import { WeekDays } from 'shared/types/week-days';

import { Footer } from './footer';
import * as Styled from './form.style';
import { Header } from './header';
import { isValidRrule, parseFormUnitFromRrule } from './parse-rrule';
import { parseValueAndCountToSchedule } from './parse-value-and-count-to-schedule';
import { RruleMonthsBody } from './rrule-months-body';
import { RruleWeeksBody } from './rrule-weeks-body';
import { RruleYearsBody } from './rrule-years-body';
import { defaultRrule, FormUnit } from './types';

export type RepeatRruleFormProps = {
  initialRrule?: string;
  type: TaskType;
  weekStartsOn: WeekDays;
  onChange: (schedule: HabitSchedule | null) => void;
  onChangeType: (value: TaskType.Repeating | TaskType.Habit) => void;
  createHabitPremium: boolean;
  showTypeToggle?: boolean;
};

export const RruleForm: React.FC<RepeatRruleFormProps> = ({
  initialRrule = defaultRrule,
  type,
  weekStartsOn,
  onChange,
  onChangeType,
  createHabitPremium,
  showTypeToggle,
}) => {
  const today = useToday();
  const [rrule, setRrule] = useState(initialRrule);
  const typeValue =
    type && type !== TaskType.Task
      ? type
      : createHabitPremium
        ? TaskType.Repeating
        : TaskType.Habit;

  const onClear = () => onChange(null);

  const onSubmit = () => {
    if (type !== typeValue) {
      onChangeType(typeValue);
    }

    onChange(parseValueAndCountToSchedule(rrule, null, today));
  };

  const selectedUnit = useMemo(() => parseFormUnitFromRrule(rrule), [rrule]);

  const isValidValue = useMemo(() => isValidRrule(rrule), [rrule]);

  return (
    <Styled.Container>
      <Header
        onChangeUnit={setRrule}
        onChangeInterval={setRrule}
        unit={selectedUnit}
        rrule={rrule}
        weekStartsOn={weekStartsOn}
      />

      <Styled.Body>
        {selectedUnit === FormUnit.Weeks && (
          <RruleWeeksBody
            rrule={rrule}
            onChange={setRrule}
            weekStartsOn={weekStartsOn}
          />
        )}
        {selectedUnit === FormUnit.Months && (
          <RruleMonthsBody
            rrule={rrule}
            onChange={setRrule}
            weekStartsOn={weekStartsOn}
          />
        )}
        {selectedUnit === FormUnit.Years && (
          <RruleYearsBody
            rrule={rrule}
            onChange={setRrule}
            weekStartsOn={weekStartsOn}
          />
        )}

        {!!showTypeToggle && (
          <TaskTypeToggle
            type={typeValue}
            onChange={onChangeType}
            isPremium={createHabitPremium}
          />
        )}
      </Styled.Body>

      <Footer onClear={onClear} onSubmit={onSubmit} isValid={isValidValue} />
    </Styled.Container>
  );
};
