import { createContext } from 'react';
import { Habit } from 'shared/types/habit';
import { HabitFormFields } from 'shared/types/habit-form';
import { ID } from 'shared/types/id';
import { RepeatingTask } from 'shared/types/repeating-task';

export type HabitFormContextProps = {
  onCreateHabit: (initialValues?: Partial<HabitFormFields>) => void;
  onEditHabit: (habit: Habit | RepeatingTask, id: ID) => void;
};

export const HabitFormContext = createContext<HabitFormContextProps>({
  onCreateHabit: () => {},
  onEditHabit: () => {},
});
