import { rrulestr, WeekdayStr } from 'shared/lib/rrule';
import { WeekDays } from 'shared/types/week-days';

const weekdayNumber: Record<number, WeekDays> = {
  0: WeekDays.Monday,
  1: WeekDays.Tuesday,
  2: WeekDays.Wednesday,
  3: WeekDays.Thursday,
  4: WeekDays.Friday,
  5: WeekDays.Saturday,
  6: WeekDays.Sunday,
};

const weekdayString: Record<WeekdayStr, WeekDays> = {
  MO: WeekDays.Monday,
  TU: WeekDays.Tuesday,
  WE: WeekDays.Wednesday,
  TH: WeekDays.Thursday,
  FR: WeekDays.Friday,
  SA: WeekDays.Saturday,
  SU: WeekDays.Sunday,
};

export const formatRruleWeekDays = (format: string): WeekDays[] => {
  const rrule = rrulestr(format);

  if (!rrule.origOptions.byweekday) {
    return Object.values(weekdayString);
  }

  if (!Array.isArray(rrule.origOptions.byweekday)) {
    const weekday =
      weekdayString[rrule.origOptions.byweekday.toString() as WeekdayStr] ??
      weekdayNumber[rrule.origOptions.byweekday as number];

    return [weekday];
  }

  return rrule.origOptions.byweekday.map(
    (weekday) =>
      weekdayString[weekday.toString() as WeekdayStr] ??
      weekdayNumber[weekday as number],
  );
};
