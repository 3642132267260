import { isBefore, isSameDay } from 'date-fns';
import { useMemo } from 'react';
import { useTimeframeOccurrences } from 'shared/hooks/use-timeframe-occurrences';
import { useTimeframePerfectSlots } from 'shared/hooks/use-timeframe-perfect-slots';
import { isBetween } from 'shared/lib/date-fns';
import { BarChartEntry } from 'shared/lib/recharts';
import { Habit } from 'shared/types/habit';
import { FrequencyUnit } from 'shared/types/habit-schedule';
import { InsightsTimeframes } from 'shared/types/insights';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';
import { getActiveSchedule } from 'shared/utils/get-active-schedule';
import { getDateBarTooltip } from 'shared/utils/get-date-bar-tooltip';
import { getDateXAxisLabel } from 'shared/utils/get-date-x-axis-label';
import { getScheduleFrequency } from 'shared/utils/get-schedule-frequency';
import { getScheduleTimeframeTarget } from 'shared/utils/get-schedule-timeframe-target';
import {
  getTimeframesForDateRange,
  Interval,
} from 'shared/utils/get-timeframes-for-date-range';

export type Options = {
  timeframe: InsightsTimeframes;
  startDate: Timestamp;
  endDate: Timestamp;
  weekStartsOn?: WeekDays;
};

export const useHabitBarChartEntries = (
  habit: Habit,
  { timeframe, startDate, endDate, weekStartsOn = WeekDays.Monday }: Options,
) => {
  const { occurrences } = useTimeframeOccurrences(habit.schedules, {
    startDate,
    endDate,
  });
  const perfectSlots = useTimeframePerfectSlots(habit.schedules, {
    timeframe,
    startDate,
    endDate,
    weekStartsOn,
  });

  const activeSchedule = useMemo(
    () => getActiveSchedule(habit.schedules),
    [habit.schedules],
  );
  const entries = useMemo((): BarChartEntry[] => {
    // get the active schedule to base the entry-range upon
    const activeSchedule = getActiveSchedule(habit.schedules);
    if (!activeSchedule) {
      return [];
    }
    const frequency = getScheduleFrequency(activeSchedule);
    const timeframeIntervalMap = {
      [InsightsTimeframes.Week]: Interval.Day,
      [InsightsTimeframes.Month]:
        activeSchedule.frequency?.unit === FrequencyUnit.Week
          ? Interval.Week
          : Interval.Month,
      [InsightsTimeframes.Quarter]:
        frequency === FrequencyUnit.Week ? Interval.Week : Interval.Month,
      [InsightsTimeframes.Year]: Interval.Month,
      [InsightsTimeframes.FourWeeks]: Interval.Week,
      [InsightsTimeframes.TwelveWeeks]: Interval.Week,
      [InsightsTimeframes.TwelveMonths]: Interval.Month,
    };
    // get all dates between start and end-date
    const timeframes = getTimeframesForDateRange(startDate, endDate, {
      weekStartsOn,
      interval: timeframeIntervalMap[timeframe],
    });

    // create empty entries for the timeframes
    return timeframes.map<BarChartEntry>((timeframeEntry) => {
      const { timeframeCompletions, timeframeTarget } = habit.schedules.reduce(
        (acc, schedule) => {
          // completions
          // get all completions within the timeframe
          const scheduleCompletionsForTimeframe =
            schedule.completions?.filter((completion) =>
              isBetween(
                completion,
                timeframeEntry.startDate,
                timeframeEntry.endDate,
              ),
            ) ?? [];

          // target
          // get the first completion of the schedule to get the range of the schedule
          const scheduleFirstCompletion = schedule.completions.toSorted(
            (dateA, dateB) => dateA!.getTime() - dateB!.getTime(),
          )[0] as Date | undefined;

          // get the start of the range of the schedule
          const scheduleStartDate =
            scheduleFirstCompletion &&
            isBefore(scheduleFirstCompletion, schedule.startDate)
              ? scheduleFirstCompletion
              : schedule.startDate;

          let target = 0;
          // make sure the timeframe startDate is in the range of the schedule before adding to the target
          if (
            !schedule.endDate ||
            isBetween(
              timeframeEntry.referenceDate,
              scheduleStartDate,
              schedule.endDate,
            )
          ) {
            // get the target for the schedule, for this timeframe
            target = schedule.frequency
              ? getScheduleTimeframeTarget(schedule, {
                  startDate: timeframeEntry.startDate,
                  endDate: timeframeEntry.endDate,
                  weekStartsOn,
                  calculateFullMonthWeeks:
                    schedule.frequency.unit === FrequencyUnit.Week,
                })
              : occurrences.filter((date) =>
                  isBetween(
                    date,
                    timeframeEntry.startDate,
                    timeframeEntry.endDate,
                  ),
                ).length;
          }

          acc.timeframeCompletions += scheduleCompletionsForTimeframe.length;
          acc.timeframeTarget += target;
          return acc;
        },
        { timeframeCompletions: 0, timeframeTarget: 0 },
      );
      return {
        value: timeframeCompletions,
        target: timeframeTarget,
        isPerfect: !!perfectSlots.find(
          (slot) =>
            (timeframe === InsightsTimeframes.Week &&
              isBetween(
                timeframeEntry.startDate,
                slot.startDate,
                slot.endDate,
              )) ||
            (isSameDay(timeframeEntry.startDate, slot.startDate) &&
              isSameDay(timeframeEntry.endDate, slot.endDate)),
        ),
        label: getDateXAxisLabel(timeframeEntry.referenceDate, {
          startDate,
          endDate,
          weekStartsOn,
        }),
        tooltip: getDateBarTooltip({
          value: timeframeCompletions,
          target: timeframeTarget,
          timeframe: timeframeEntry,
        }),
      };
    });
  }, [
    endDate,
    habit.schedules,
    occurrences,
    perfectSlots,
    startDate,
    timeframe,
    weekStartsOn,
  ]);

  const entriesTarget = useMemo(
    () => Math.max(...entries.map(({ target }) => target), 1),
    [entries],
  );

  // statistics
  const { totalEntriesTarget, completionCount } = useMemo(
    () =>
      entries.reduce(
        (acc, { value, target }) => {
          acc.completionCount += value;
          acc.totalEntriesTarget += target ?? 0;
          return acc;
        },
        { totalEntriesTarget: 0, completionCount: 0 },
      ),
    [entries],
  );

  const timeframeTargetMap = {
    [InsightsTimeframes.Week]:
      activeSchedule?.frequency?.unit === FrequencyUnit.Week
        ? activeSchedule.frequency.count
        : totalEntriesTarget,
    [InsightsTimeframes.Month]:
      activeSchedule?.frequency?.unit === FrequencyUnit.Month
        ? activeSchedule.frequency.count
        : totalEntriesTarget,
    [InsightsTimeframes.Quarter]: totalEntriesTarget,
    [InsightsTimeframes.Year]: totalEntriesTarget,
    [InsightsTimeframes.FourWeeks]: totalEntriesTarget,
    [InsightsTimeframes.TwelveWeeks]: totalEntriesTarget,
    [InsightsTimeframes.TwelveMonths]: totalEntriesTarget,
  };

  const target = timeframeTargetMap[timeframe];

  const completionPercentage = target
    ? Math.round((completionCount / target) * 1000) / 10
    : undefined;

  return {
    entries,
    perfectSlots,
    entriesTarget,
    target,
    completionPercentage:
      completionPercentage && completionPercentage >= 10
        ? Math.round(completionPercentage)
        : completionPercentage,
  };
};
