import { isAfter, isBefore, isSameDay } from 'date-fns';
import { useUpdateTask } from 'features/tasks';
import { useCallback } from 'react';
import { Task } from 'shared/types/task';
import { Timestamp } from 'shared/types/timestamp';

export const useSkipHabitDate = () => {
  const { submit: updateTask } = useUpdateTask();

  return useCallback(
    (task: Task, date?: Timestamp) => {
      if (!!task.frozenAt || !date) {
        return;
      }

      // sort the schedules so we are sure that they are in order
      const sortedSchedules =
        task.schedules?.toSorted(
          (scheduleA, scheduleB) =>
            scheduleA.startDate.getTime() - scheduleB.startDate.getTime(),
        ) ?? null;

      const newSchedules =
        sortedSchedules?.map((schedule, index) => {
          // todo: habit-failures - filter failures as well
          const completions =
            schedule.completions?.filter(
              (completion) => !isSameDay(date, completion),
            ) ?? [];
          const skips =
            schedule.skips?.filter((skip) => !isSameDay(date, skip)) ?? [];

          // get the previous schedule to see if the date falls between this and the previous schedule
          const prevSchedule = sortedSchedules[index - 1];

          const startPointSchedule = prevSchedule?.endDate ?? new Date(0);

          // check if the date is part of the current schedule, and if the date should be added
          if (
            schedule.skips &&
            skips.length === schedule.skips.length &&
            isAfter(date, startPointSchedule) &&
            (!schedule.endDate || isBefore(date, schedule.endDate))
          ) {
            skips.push(date);
          }

          return { ...schedule, completions, skips };
        }) ?? null;

      updateTask({ id: task.id, schedules: newSchedules });
    },
    [updateTask],
  );
};
