import { useGoals } from 'features/goals';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { SortingMode } from 'shared/types/sorting-mode';
import { noPriorityOption, TaskPriorityOptions } from 'shared/types/task-base';
import { TaskCardTask } from 'shared/types/task-card-task';
import { getSortedTasks } from 'shared/utils/get-sorted-tasks';
import { getWeekStartsOn } from 'shared/utils/get-week-starts-on';
import { mapTaskToTaskCard } from 'shared/utils/mappers/map-task-to-task-card';

import { useTodayTasks } from './use-today-tasks';

export const usePriorityTaskSections = () => {
  const { t } = useTranslation();
  const today = useToday();
  const user = useUser();

  const tasks = useTodayTasks();
  const goals = useGoals();

  const sections = useMemo(
    () => [
      {
        id: TaskPriorityOptions.High,
        items: getSortedTasks(
          tasks.filter(({ priority }) => priority === TaskPriorityOptions.High),
          {
            today,
            weekStartsOn: getWeekStartsOn(user),
            mode: Array.from(
              new Set([SortingMode.DueDate, user?.settings?.todoSortingMode]),
            ).filter(Boolean) as SortingMode[],
            order:
              user?.settings?.todayGroupSorting?.priority?.sorting?.[
                TaskPriorityOptions.High
              ] ?? [],
          },
        ).map((task) =>
          mapTaskToTaskCard(task, {
            today,
            goals,
            t,
            showDue: true,
            hideTodayDue: true,
            weekStartsOn: getWeekStartsOn(user),
          }),
        ),
      },
      {
        id: TaskPriorityOptions.Medium,
        items: getSortedTasks(
          tasks.filter(
            ({ priority }) => priority === TaskPriorityOptions.Medium,
          ),
          {
            today,
            weekStartsOn: getWeekStartsOn(user),
            mode: Array.from(
              new Set([SortingMode.DueDate, user?.settings?.todoSortingMode]),
            ).filter(Boolean) as SortingMode[],
            order:
              user?.settings?.todayGroupSorting?.priority?.sorting?.[
                TaskPriorityOptions.Medium
              ] ?? [],
          },
        ).map((task) =>
          mapTaskToTaskCard(task, {
            today,
            goals,
            t,
            showDue: true,
            hideTodayDue: true,
            weekStartsOn: getWeekStartsOn(user),
          }),
        ),
      },
      {
        id: TaskPriorityOptions.Low,
        items: getSortedTasks(
          tasks.filter(({ priority }) => priority === TaskPriorityOptions.Low),
          {
            today,
            weekStartsOn: getWeekStartsOn(user),
            mode: Array.from(
              new Set([SortingMode.DueDate, user?.settings?.todoSortingMode]),
            ).filter(Boolean) as SortingMode[],
            order:
              user?.settings?.todayGroupSorting?.priority?.sorting?.[
                TaskPriorityOptions.Low
              ] ?? [],
          },
        ).map((task) =>
          mapTaskToTaskCard(task, {
            today,
            goals,
            t,
            showDue: true,
            hideTodayDue: true,
            weekStartsOn: getWeekStartsOn(user),
          }),
        ),
      },
      {
        id: noPriorityOption,
        items: getSortedTasks(
          tasks.filter(
            ({ priority }) =>
              !priority || priority === TaskPriorityOptions.None,
          ),
          {
            today,
            weekStartsOn: getWeekStartsOn(user),
            mode: Array.from(
              new Set([SortingMode.DueDate, user?.settings?.todoSortingMode]),
            ).filter(Boolean) as SortingMode[],
            order:
              user?.settings?.todayGroupSorting?.priority?.sorting?.[
                TaskPriorityOptions.None
              ] ?? [],
          },
        ).map((task) =>
          mapTaskToTaskCard(task, {
            today,
            goals,
            t,
            showDue: true,
            hideTodayDue: true,
            weekStartsOn: getWeekStartsOn(user),
          }),
        ),
      },
    ],
    [goals, t, tasks, today, user],
  );

  const taskCardTasks = useMemo(
    () =>
      sections.reduce<TaskCardTask[]>((acc, { items }) => {
        acc.push(...items);
        return acc;
      }, []),
    [sections],
  );

  return {
    sections,
    taskCardTasks,
  };
};
