import Typography from '@mui/material/Typography';
import {
  addMonths,
  addWeeks,
  eachMonthOfInterval,
  endOfMonth,
  isSameWeek,
  startOfMonth,
} from 'date-fns';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EvenlyDistributedBarChart } from 'shared/components/ui/evenly-distributed-bar-chart';
import { Section } from 'shared/components/ui/section';
import { weekdaysNumberMap } from 'shared/constants';
import {
  endOfLastWeekOfMonth,
  startOfFirstWeekOfMonth,
} from 'shared/lib/date-fns';
import { BarChartEntry } from 'shared/lib/recharts';
import { EntryState, HabitSchedule } from 'shared/types/habit-schedule';
import { InsightsTimeframes } from 'shared/types/insights';
import { Timeframe } from 'shared/types/timeframe';
import { Timestamp } from 'shared/types/timestamp';
import { WeekDays } from 'shared/types/week-days';

import { TimeframeMonth } from './timeframe-month';
import * as Styled from './timeframe-specifics.style';

const timeframeBarChartSectionTitleMap = {
  [InsightsTimeframes.Week]:
    'general.labels.task-statistics.sections.this-week.label',
  [InsightsTimeframes.Quarter]:
    'general.labels.task-statistics.sections.this-quarter.label',
  [InsightsTimeframes.Year]:
    'general.labels.task-statistics.sections.this-year.label',
  [InsightsTimeframes.FourWeeks]:
    'general.labels.task-statistics.sections.last-four-weeks.label',
  [InsightsTimeframes.TwelveWeeks]:
    'general.labels.task-statistics.sections.last-twelve-weeks.label',
  [InsightsTimeframes.TwelveMonths]:
    'general.labels.task-statistics.sections.last-twelve-months.label',
};

export type TimeframeSpecificsProps = {
  timeframe: InsightsTimeframes;
  startDate: Timestamp;
  endDate: Timestamp;
  weekStartsOn: WeekDays;
  entries?: BarChartEntry[];
  entriesTarget?: number;
  target?: number;
  weeklyTarget?: number;
  monthlyTarget?: number;
  occurrences: Timestamp[];
  completions: Timestamp[];
  timeframeCompletions: Timestamp[];
  skips: Timestamp[];
  perfectSlots: Timeframe[];
  scheduleChanges?: {
    date: Timestamp;
    oldSchedule: HabitSchedule;
    newSchedule: HabitSchedule;
  }[];
  onDate?: (date: Timestamp, type: EntryState) => void;
  onInfo?: () => void;
  showCompletionPercentage?: boolean;
  showSurroundingWeeks?: boolean;
};

export const TimeframeSpecifics: React.FC<TimeframeSpecificsProps> = ({
  timeframe,
  startDate,
  endDate,
  weekStartsOn,
  entries,
  entriesTarget,
  target,
  weeklyTarget,
  monthlyTarget,
  timeframeCompletions,
  occurrences,
  completions,
  skips,
  perfectSlots,
  scheduleChanges,
  onDate,
  onInfo,
  showCompletionPercentage,
  showSurroundingWeeks,
}) => {
  const { t } = useTranslation();

  const months = useMemo(() => {
    const firstMonthStartDate = isSameWeek(startOfMonth(startDate), startDate, {
      weekStartsOn: weekdaysNumberMap[weekStartsOn],
    })
      ? startDate
      : startOfMonth(addWeeks(startDate, 1));
    const lastMonthEndDate = isSameWeek(endOfMonth(endDate), endDate, {
      weekStartsOn: weekdaysNumberMap[weekStartsOn],
    })
      ? endDate
      : endOfMonth(addWeeks(endDate, -1));

    return eachMonthOfInterval({
      start: firstMonthStartDate,
      end: lastMonthEndDate,
    }).map((_, index, array) => {
      const referenceDate = addMonths(firstMonthStartDate, index);
      const monthStartDate =
        !index && showSurroundingWeeks
          ? startOfFirstWeekOfMonth(firstMonthStartDate, {
              weekStartsOn: weekdaysNumberMap[weekStartsOn],
            })
          : startOfMonth(referenceDate);
      const monthEndDate =
        index === array.length - 1 && showSurroundingWeeks
          ? endOfLastWeekOfMonth(endDate, {
              weekStartsOn: weekdaysNumberMap[weekStartsOn],
            })
          : endOfMonth(referenceDate);

      return {
        referenceDate,
        dataStartDate: monthStartDate,
        dataEndDate: monthEndDate,
      };
    });
  }, [endDate, showSurroundingWeeks, startDate, weekStartsOn]);

  return (
    <>
      {timeframe !== InsightsTimeframes.Month && entries && (
        <Section title={t(timeframeBarChartSectionTitleMap[timeframe])}>
          <Styled.ChartContainer>
            <Styled.BarChartContainer>
              <Styled.BarChartHeader>
                <Typography variant="h3" component="span">
                  {timeframeCompletions.length}
                </Typography>
                {!!target && ` / ${target}`}
              </Styled.BarChartHeader>

              <EvenlyDistributedBarChart
                data={entries}
                target={entriesTarget}
                showYAxis
              />
            </Styled.BarChartContainer>
          </Styled.ChartContainer>
        </Section>
      )}

      {timeframe !== InsightsTimeframes.Week && (
        <Section
          title={t('general.labels.task-statistics.sections.calendar.label')}
        >
          {months.map(({ referenceDate, dataStartDate, dataEndDate }) => (
            <Styled.CalendarContainer key={referenceDate.getTime()}>
              <TimeframeMonth
                referenceDate={referenceDate}
                dataStartDate={dataStartDate}
                dataEndDate={dataEndDate}
                occurrences={occurrences}
                completions={completions}
                skips={skips}
                perfectSlots={perfectSlots}
                scheduleChanges={scheduleChanges}
                target={monthlyTarget}
                weekStartsOn={weekStartsOn}
                onDate={onDate}
                onInfo={onInfo}
                showCompletionPercentage={showCompletionPercentage}
                weekTarget={weeklyTarget}
              />
            </Styled.CalendarContainer>
          ))}
        </Section>
      )}
    </>
  );
};
