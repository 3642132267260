import { useMutation } from '@tanstack/react-query';
import { updateInsightsSection } from 'shared/services/backend-api';
import { InsightSections, SectionInsights } from 'shared/types/insights';

export const useUpdateInsightsSectionMutation = () =>
  useMutation({
    mutationFn: async ({
      id,
      section,
    }: {
      id: InsightSections;
      section: SectionInsights;
    }) => updateInsightsSection(id, section),
  });
