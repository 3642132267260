import { useMemo } from 'react';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { Timestamp } from 'shared/types/timestamp';

export type Options = {
  startDate: Timestamp;
  endDate: Timestamp;
};

export const useTimeframeSchedules = (
  schedules: HabitSchedule[],
  { startDate, endDate }: Options,
) =>
  useMemo(
    () =>
      schedules.filter(
        (schedule) =>
          schedule.startDate <= endDate &&
          (!schedule.endDate || schedule.endDate >= startDate),
      ),
    [endDate, schedules, startDate],
  );
