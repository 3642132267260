import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeft } from 'shared/assets/icons';
import { IconButton, Sizes } from 'shared/components/ui/icon-button';
import { HabitSchedule, ScheduleFrequency } from 'shared/types/habit-schedule';
import { TaskType } from 'shared/types/task-base';
import { WeekDays } from 'shared/types/week-days';

import * as Styled from './repeat-form.style';
import { RruleForm } from './rrule-form';
import { TargetForm } from './target-form';
import { FormOptions } from './types';

type OptionProps = {
  label: string;
  description: string;
  onClick: () => void;
  isPremium?: boolean;
};

const Option: React.FC<OptionProps> = ({
  label,
  description,
  onClick,
  isPremium,
}) => {
  const { t } = useTranslation();

  const _onClick = () => onClick();

  return (
    <Styled.OptionContainer onClick={_onClick}>
      <Styled.OptionContent>
        <Typography variant="body2">{label}</Typography>
        <Styled.OptionDescription>{description}</Styled.OptionDescription>
        {isPremium && (
          <Styled.OptionPremiumLabel>
            {t('general.labels.premium')}
          </Styled.OptionPremiumLabel>
        )}
      </Styled.OptionContent>

      <IconButton
        icon={ChevronLeft}
        rotation={180}
        as="span"
        size={Sizes.Small}
      />
    </Styled.OptionContainer>
  );
};

export type RepeatFormProps = {
  initialRrule?: string;
  initialFrequency?: ScheduleFrequency | null;
  type: TaskType;
  weekStartsOn: WeekDays;
  onChange: (schedule: HabitSchedule | null) => void;
  onChangeType: (type: TaskType.Habit | TaskType.Repeating) => void;
  onPremium: () => void;
  isTargetPremium: boolean;
  isCreateHabitPremium: boolean;
  showTypeToggle?: boolean;
};

export const RepeatForm: React.FC<RepeatFormProps> = ({
  initialRrule,
  initialFrequency,
  type,
  weekStartsOn,
  onChange,
  onChangeType,
  onPremium,
  isTargetPremium,
  isCreateHabitPremium,
  showTypeToggle,
}) => {
  const { t } = useTranslation();
  const [option, setOption] = useState<FormOptions>();

  const setOptionTarget = () =>
    isTargetPremium ? onPremium() : setOption(FormOptions.Target);

  const setOptionRRule = () => setOption(FormOptions.RRule);

  return (
    <Styled.Container>
      {!option && (
        <Styled.OptionsList>
          <Styled.OptionsItem onClick={setOptionRRule}>
            <Option
              label={t('general.labels.habit.repeat.options.rrule.label')}
              description={t(
                'general.labels.habit.repeat.options.rrule.description',
              )}
              onClick={setOptionRRule}
            />
          </Styled.OptionsItem>

          <Styled.OptionsItem onClick={setOptionTarget}>
            <Option
              label={t('general.labels.habit.repeat.options.target.label')}
              description={t(
                'general.labels.habit.repeat.options.target.description',
              )}
              onClick={setOptionTarget}
              isPremium={isTargetPremium}
            />
          </Styled.OptionsItem>
        </Styled.OptionsList>
      )}

      {option === FormOptions.RRule && (
        <RruleForm
          type={type}
          initialRrule={initialRrule}
          weekStartsOn={weekStartsOn}
          onChange={onChange}
          onChangeType={onChangeType}
          createHabitPremium={isCreateHabitPremium}
          showTypeToggle={showTypeToggle}
        />
      )}

      {option === FormOptions.Target && (
        <TargetForm
          type={type}
          initialFrequency={initialFrequency}
          onChange={onChange}
          onChangeType={onChangeType}
          createHabitPremium={isCreateHabitPremium}
          showTypeToggle={showTypeToggle}
        />
      )}
    </Styled.Container>
  );
};
