import { Frequency, RRule } from 'shared/lib/rrule';
import { FrequencyUnit, HabitSchedule } from 'shared/types/habit-schedule';

export const getScheduleFrequency = (schedule: HabitSchedule) => {
  if (schedule.frequency) {
    return schedule.frequency.unit;
  }

  const rrule = RRule.fromString(schedule.rrule.format);
  switch (rrule.options.freq) {
    case Frequency.YEARLY:
      return FrequencyUnit.Year;
    case Frequency.MONTHLY:
      return FrequencyUnit.Month;
    default:
      return FrequencyUnit.Week;
  }
};
