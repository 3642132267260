import { isBefore, isSameDay } from 'date-fns';
import { getSortedGoals, useGoals } from 'features/goals';
import { useLocalizedLifeAreas } from 'features/life-areas';
import { useMemo } from 'react';
import { useToday } from 'shared/contexts/today';
import { useUser } from 'shared/hooks/use-user';
import { Goal } from 'shared/types/goal';
import { InsightSections } from 'shared/types/insights';
import { GoalGroupBy, GoalSortingMode } from 'shared/types/sorting-mode';
import { GoalRoadmapSortingKeys } from 'shared/types/user-settings';
import { sortItems } from 'shared/utils/sort-items';

export const useInsightsGoals = () => {
  const today = useToday();
  const lifeAreas = useLocalizedLifeAreas();
  const goals = useGoals();
  const user = useUser();

  return useMemo(() => {
    const filteredGoals = goals.filter(
      ({ id, completedAt, archivedAt, startDate, parentIds }) =>
        (user?.settings?.insights?.showHiddenItems ||
          !user?.settings?.insights?.sections?.[
            InsightSections.Goals
          ]?.hiddenItems.includes(id)) &&
        !parentIds?.length &&
        !archivedAt &&
        !completedAt &&
        !!startDate &&
        (isBefore(startDate, today) || isSameDay(startDate, today)),
    );

    let order =
      user?.settings?.goalRoadmapSorting?.[GoalRoadmapSortingKeys.InProgress] ??
      [];

    if (user?.settings?.goalGroupBy === GoalGroupBy.LifeArea) {
      const sortedLifeAreas = sortItems(
        lifeAreas,
        user?.settings?.lifeAreaSorting ?? [],
        'id',
      );
      order = [
        ...sortedLifeAreas
          .map(({ id }) => {
            const lifeAreaGoals = filteredGoals.filter(
              ({ lifeAreaId }) => lifeAreaId === id,
            );
            return sortItems(
              lifeAreaGoals,
              user?.settings?.goalGroupSorting?.lifeArea?.sorting?.[id] ?? [],
              'id',
            ).map(({ id }) => id);
          })
          .flat(1),
        ...(user?.settings?.goalGroupSorting?.lifeArea?.sorting?.['null'] ??
          []),
      ];
    }

    let sortedGoals = getSortedGoals(filteredGoals, {
      mode: [user?.settings?.goalSortingMode].filter(
        Boolean,
      ) as GoalSortingMode[],
      order,
      allGoals: goals,
    });

    if (
      user?.settings?.insights?.sections?.[InsightSections.Goals]?.order?.length
    ) {
      sortedGoals = sortItems(
        sortedGoals,
        user.settings.insights.sections[InsightSections.Goals].order,
        'id',
      );
    }

    return user?.settings?.insights?.showActiveSubGoals
      ? sortedGoals.reduce<Goal[]>((acc, goal) => {
          acc.push(
            ...[
              goal,
              ...((goal.goalSorting
                ?.map((subGoalId) =>
                  goals.find(
                    ({ id, completedAt, archivedAt, startDate }) =>
                      id === subGoalId &&
                      (user?.settings?.insights?.showHiddenItems ||
                        !user?.settings?.insights?.sections?.[
                          InsightSections.Goals
                        ]?.hiddenItems.includes(id)) &&
                      !archivedAt &&
                      !completedAt &&
                      !!startDate &&
                      (isBefore(startDate, today) ||
                        isSameDay(startDate, today)),
                  ),
                )
                .filter(Boolean) as Goal[]) ?? []),
            ],
          );
          return acc;
        }, [])
      : sortedGoals;
  }, [
    goals,
    lifeAreas,
    today,
    user?.settings?.goalGroupBy,
    user?.settings?.goalGroupSorting?.lifeArea?.sorting,
    user?.settings?.goalRoadmapSorting,
    user?.settings?.goalSortingMode,
    user?.settings?.insights?.sections,
    user?.settings?.insights?.showActiveSubGoals,
    user?.settings?.insights?.showHiddenItems,
    user?.settings?.lifeAreaSorting,
  ]);
};
