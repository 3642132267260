import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { weekdaysNumberMap } from 'shared/constants';
import { Frequency, RRule } from 'shared/lib/rrule';
import { HabitSchedule } from 'shared/types/habit-schedule';
import { getScheduleTimeframeCompletion } from 'shared/utils/get-schedule-timeframe-completion';

import { isScheduleDue } from './is-schedule-due';
import { isScheduleOverdue } from './is-schedule-overdue';
import { Options } from './types';

export const getRruleDueDateBySchedule = (
  schedule: HabitSchedule,
  { today, weekStartsOn }: Options,
) => {
  if (isScheduleDue(schedule, { today, weekStartsOn })) {
    return endOfDay(today);
  }

  // check if schedule is overdue, and if so, return the date it is overdue from
  const overdueDate = isScheduleOverdue(schedule, { today, weekStartsOn });
  if (overdueDate) {
    return overdueDate;
  }
  // if not,
  // get the current timeframe completions
  const {
    completions: completionsCurrentTimeframe,
    total: totalAllowedCompletionsCurrentTimeframe,
  } = getScheduleTimeframeCompletion(schedule, {
    referenceDate: today,
    weekStartsOn,
  });

  // get the rrule from the schedule
  const rrule = RRule.fromString(schedule.rrule.format);

  const lastSkip = schedule.skips?.[schedule.skips.length - 1];
  const lastComplete = schedule.completions.toSorted(
    (dateA, dateB) => dateB!.getTime() - dateA!.getTime(),
  )[0] as Date | undefined;

  // get next timeframe startDate if current timeframe is completed
  const nextTimeframeStartDate =
    completionsCurrentTimeframe.length >=
    totalAllowedCompletionsCurrentTimeframe
      ? rrule.options.freq === Frequency.YEARLY
        ? endOfDay(addYears(startOfYear(today), 1))
        : rrule.options.freq === Frequency.MONTHLY
          ? endOfDay(addMonths(startOfMonth(today), 1))
          : endOfDay(
              startOfWeek(addWeeks(today, 1), {
                weekStartsOn: weekdaysNumberMap[weekStartsOn],
              }),
            )
      : undefined;

  // set the first next option from the latest skip, latest completion, next timeframe start date or today (depending on which is last)
  const startDateToCheckFrom = [
    nextTimeframeStartDate,
    startOfDay(addDays(today, 1)),
    lastComplete ? startOfDay(addDays(lastComplete, 1)) : undefined,
    lastSkip ? startOfDay(addDays(lastSkip, 1)) : undefined,
  ]
    .filter(Boolean)
    .sort((dateA, dateB) => dateB!.getTime() - dateA!.getTime())[0] as Date;

  rrule.options.dtstart = startOfDay(schedule.startDate);

  const afterDue = rrule.after(startDateToCheckFrom, true);
  return afterDue ? endOfDay(afterDue) : undefined;
};
